
import {
    GET_CATEGORIES
} from './types'
import axios from 'axios'

export const getCategories = () => dispatch => {
    axios.get(`/api/categories/getCategories`)
        .then(res => {
            dispatch({
                type: GET_CATEGORIES,
                payload: res.data
            })
        })
        .catch(err => {
            console.log(err)
        })

}