import React, { Component } from "react";
import { Form } from "reactstrap";
import FormTitle from "./FormTitle";
import InputField from "./InputField";
import RadioInput from "./RadioInput";

export default class SuperForm extends Component {
  render() {
    const {
      mainLabel,
      inputGroups,
      onChange,
      errors,
      radioGroups,
      isVisible,
      handleRadioButton,
      saleActive
    } = this.props;
    return (
      <div>
        <Form onSubmit={e => e.preventDefault()}>
          {mainLabel && <FormTitle title={mainLabel} />}
          {inputGroups?.map((inputGroup, index) => (
            <InputField
              key={index}
              inputGroup={inputGroup}
              onChange={onChange}
              errors={errors}
              saleActive={saleActive}
            />
          ))}

          {radioGroups && (
            <RadioInput
              title={radioGroups.title}
              radioGroups={radioGroups.buttons}
              isVisible={isVisible}
              handleRadioButton={handleRadioButton}
            />
          )}
        </Form>
      </div>
    );
  }
}
