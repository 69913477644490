import React from "react";
import moment from "moment";

export default function NewLabel({ time }) {
  const twoMonthsAgo = new Date();
  twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);

  return (
    moment(time) > moment(twoMonthsAgo) && (
      <div className="position-absolute p-3" style={{ top: 0 }}>
        <p
          className="font-weight-bold"
          style={{ color: "rgb(161,99,72)", fontSize: 14 }}
        >
          New Arrival
        </p>
      </div>
    )
  );
}
