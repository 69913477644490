import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  Row,
  Col,
  Badge,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Input
} from "reactstrap";
import {
  getSelectedOrder,
  updateDeliveryDate,
  resetSelectedOrder,
  deleteSelectedOrder
} from "../../actions/adminOrdersDash";

class SelectedOrder extends Component {
  state = {
    order: {},
    showUpdateModal: false,
    showUpdateMessage: false,
    updateClicked: false,
    trackingNumber: "",
    showDeleteModal: false
  };

  componentWillUnmount() {
    this.props.resetSelectedOrder();
  }

  componentDidMount() {
    if (this.props.location.state === undefined) {
      this.props.getSelectedOrder(this.props.match.params.orderid);
    }

    if (
      this.props.location.state !== undefined &&
      this.props.location.state.order
    ) {
      this.setState({
        order: this.props.location.state.order
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({
        order: {}
      });

      this.props.getSelectedOrder(this.props.match.params.orderid);
    }

    if (this.props.adminOrders.order !== prevProps.adminOrders.order) {
      this.setState({
        order: this.props.adminOrders.order
      });
    }
    if (
      this.props.adminOrders.orderUpdated !==
        prevProps.adminOrders.orderUpdated &&
      this.state.updateClicked
    ) {
      this.setState({
        updateClicked: false,
        showUpdateMessage: true
      });

      setTimeout(this.closeModal, 3000);
    }

    if (
      this.props.adminOrders.orderDeleted !==
        prevProps.adminOrders.orderDeleted &&
      this.props.adminOrders.orderDeleted
    ) {
      this.props.history.push("/adminpanel/orders");
    }
  }

  closeModal = () => {
    this.setState({
      showUpdateModal: false
    });
  };

  toggleUpdateModal = () => {
    if (!this.props.adminOrders.loading) {
      this.setState({
        showUpdateModal: !this.state.showUpdateModal
      });
    }
  };

  toggleDeleteModal = () => {
    if (!this.props.adminOrders.deleteButtonLoading) {
      this.setState({
        showDeleteModal: !this.state.showDeleteModal
      });
    }
  };

  deleteApproved = e => {
    this.props.deleteSelectedOrder(this.props.match.params.orderid);
  };

  updateApproved = e => {
    let data = {};
    data.trackingNumber = this.state.trackingNumber;
    data.orderStatus = "Confirmed";
    let orderid = this.props.match.params.orderid;
    this.setState({
      updateClicked: true
    });

    this.props.updateDeliveryDate(orderid, data);
  };

  ucFirstAllWords = str => {
    var pieces = str.split(" ");
    for (var i = 0; i < pieces.length; i++) {
      var j = pieces[i].charAt(0).toUpperCase();
      pieces[i] = j + pieces[i].substr(1);
    }
    return pieces.join(" ");
  };

  routeToOrder = order => {
    this.props.history.push({
      pathname: `/adminpanel/orders/${order._id}`,
      state: { order }
    });
  };

  handleTracking = e => {
    this.setState({
      [e.target.name]: e.target.value.toUpperCase()
    });
  };

  deleteOrder = e => {
    this.setState({
      showDeleteModal: !this.state.showDeleteModal
    });
  };

  render() {
    let SubTotal;
    if (this.state.order.products) {
      SubTotal =
        Math.round(
          this.state.order.products
            .map(eachItem => eachItem.productQuantity * eachItem.productPrice)
            .reduce((total, currentValue) => total + currentValue) * 100
        ) / 100;
    }

    let total = this.state.order.total / 100;

    let discount;

    if (this.state.order.coupon) {
      if (SubTotal >= this.state.order.coupon.minimumSpend) {
        if (this.state.order.coupon.isPercent) {
          discount = (SubTotal / 100) * this.state.order.coupon.amount;
        } else {
          discount = this.state.order.coupon.amount;
        }
      }
    }

    return (
      <div>
        <div>
          <Modal
            isOpen={this.state.showDeleteModal}
            toggle={this.toggleDeleteModal}
          >
            <ModalHeader>
              Are you sure you want to delete this order?{" "}
            </ModalHeader>

            <ModalFooter>
              <Button color="danger" onClick={this.deleteApproved}>
                {this.props.adminOrders.deleteButtonLoading
                  ? "Please Wait."
                  : "Delete"}{" "}
              </Button>
              <Button
                color="light"
                className="border"
                onClick={this.toggleDeleteModal}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>

        <div>
          <Modal
            isOpen={this.state.showUpdateModal}
            toggle={this.toggleUpdateModal}
          >
            <ModalHeader>
              Are you sure you want to update tracking number with{" "}
              {this.state.trackingNumber}?{" "}
            </ModalHeader>

            {this.state.showUpdateMessage && (
              <ModalBody>
                <p className="text-success">
                  Tracking number is entered and buyer informed. This window
                  will close in 3 seconds.
                </p>
              </ModalBody>
            )}

            <ModalFooter>
              <Button color="danger" onClick={this.updateApproved}>
                {this.props.adminOrders.updateButtonLoading
                  ? "Please Wait."
                  : "Update"}{" "}
              </Button>
              <Button
                color="light"
                className="border"
                onClick={this.toggleUpdateModal}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
        {this.props.adminOrders.loading && (
          <div className="text-center text-light my-5">
            <h5>Please wait. Order is being fetched.</h5>
          </div>
        )}

        {Object.keys(this.state.order).length !== 0 && (
          <div>
            <Row>
              <Col md="8">
                <div>
                  <div className="bg-white  border-0  shadow card">
                    <div className="bg-secondary card-header">
                      <div className="align-items-center row">
                        <div className="col-8">
                          <h3 className="mb-0 text-light">Order Details</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body bg-white">
                    {this.state.order.goGreen && (
                      <Badge
                        className="ml-2"
                        style={{ fontSize: "100%" }}
                        color="success"
                      >
                        ECO FRIENDLY ORDER
                      </Badge>
                    )}

                    <p className="my-2 px-2 text-muted">
                      Order Status :
                      <Badge
                        className="ml-2"
                        style={{ fontSize: "100%" }}
                        color={
                          this.state.order.orderStatus === "Processing"
                            ? "warning"
                            : "success"
                        }
                      >
                        {this.state.order.orderStatus}
                      </Badge>
                    </p>
                    <p className="my-2 px-2 text-muted">
                      Order Number : {this.state.order.orderid.toUpperCase()}
                    </p>
                    <p className="my-2 px-2 text-muted">
                      Ordered {moment(this.state.order.created_at).fromNow()}
                    </p>

                    <div className=" mt-2">
                      <div className="table-responsive">
                        <table className="table text-muted">
                          <thead className="table-borderless">
                            <tr>
                              <th scope="col">PRODUCT</th>
                              <th scope="col">NAME</th>
                              <th scope="col">QUANTITY</th>
                              <th scope="col">PRICE</th>
                              <th scope="col">TOTAL</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.order.products.map(product => (
                              <tr key={product._id}>
                                <td style={{ width: "100px" }}>
                                  <img
                                    src={product.productImage}
                                    alt={product.productName}
                                    className="img-fluid"
                                  />
                                </td>
                                <td
                                  style={{ verticalAlign: "middle" }}
                                  className="font-weight-bold"
                                >
                                  {product.productName}
                                </td>
                                <td
                                  style={{ verticalAlign: "middle" }}
                                  className="font-weight-bold"
                                >
                                  {product.productQuantity}
                                </td>
                                <td
                                  style={{ verticalAlign: "middle" }}
                                  className="font-weight-bold"
                                >
                                  AUD {product.productPrice.toFixed(2)}
                                </td>
                                <td
                                  style={{ verticalAlign: "middle" }}
                                  className="font-weight-bold"
                                >
                                  AUD{" "}
                                  {(
                                    product.productPrice *
                                    product.productQuantity
                                  ).toFixed(2)}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="text-muted ">
                  <div className="bg-white  border-0  shadow card">
                    <div className="bg-secondary card-header">
                      <div className="align-items-center row">
                        <div className="col-8">
                          <h3 className="mb-0 text-light">Shipping Summary</h3>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-body bg-white">
                    <h5 className="font-weight-bold">Shipping Address</h5>
                    {this.state.order.shippingType === "expressShipping" && (
                      <Badge style={{ fontSize: "100%" }} color="danger">
                        EXPRESS SHIPPING
                      </Badge>
                    )}
                    <p>
                      {this.state.order.firstName} {this.state.order.lastName}
                    </p>
                    <p>{this.state.order.address}</p>
                    <p>
                      {this.state.order.city.toUpperCase()}{" "}
                      {this.state.order.region && this.state.order.region}{" "}
                      {this.state.order.postcode} {this.state.order.country}
                    </p>
                    <p>Phone Number: {this.state.order.phoneNumber}</p>
                    <p>Email: {this.state.order.email}</p>
                    {this.state.order.shippingDate &&
                      this.state.order.shippingDate !== "" && (
                        <p>
                          Delivery Date:{" "}
                          {moment(this.state.order.shippingDate).format(
                            "Do MMM YYYY "
                          )}
                        </p>
                      )}
                    {this.state.order.trackingNumber && (
                      <a
                        rel="noopener noreferrer"
                        href={`https://auspost.com.au/mypost/track/#/details/${this.state.order.trackingNumber}`}
                        target="_blank"
                      >
                        {this.state.order.trackingNumber}{" "}
                      </a>
                    )}

                    {!this.state.order.trackingNumber && (
                      <div className="bg-light  text-danger py-3 ">
                        <p>Enter tracking number</p>
                        <Form>
                          <Input
                            onChange={this.handleTracking}
                            type="text"
                            name="trackingNumber"
                            placeholder="Enter tracking Number"
                            value={this.state.trackingNumber}
                          />
                        </Form>
                        <div className="my-2">
                          <Button
                            onClick={this.toggleUpdateModal}
                            outline
                            size="sm"
                            color="success"
                          >
                            Update
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className=" text-muted  mt-3">
                  <div className="bg-white  border-0  shadow card">
                    <div className="bg-secondary card-header">
                      <div className="align-items-center row">
                        <div className="col-8">
                          <h3 className="mb-0 text-light">Order Summary</h3>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <h5 className='font-weight-bold'>Order Summary</h5> */}
                  <div className="card-body bg-white border-bottom py-2">
                    <div>
                      <p>
                        SubTotal{" "}
                        <span className="float-right">
                          AU ${SubTotal.toFixed(2)}
                        </span>
                      </p>
                      <p>
                        Shipping{" "}
                        <span className="float-right">
                          {this.state.order.shippingFee !== 0
                            ? "AU $" + this.state.order.shippingFee.toFixed(2)
                            : "Free"}
                        </span>
                      </p>
                      {this.state.order.coupon && discount !== undefined && (
                        <React.Fragment>
                          <p>
                            Discount{" "}
                            <span className="float-right">
                              {this.state.order.coupon.isPercent ? "%" : "AU $"}
                              {this.state.order.coupon.amount}
                            </span>
                          </p>
                          <p className="text-danger">
                            Discount Applied{" "}
                            <span className="float-right text-danger">
                              - AU ${discount.toFixed(2)}
                            </span>
                          </p>
                        </React.Fragment>
                      )}
                    </div>
                    <h5 className="py-3">
                      Total{" "}
                      <span className="float-right">
                        AU ${total.toFixed(2)}
                      </span>
                    </h5>
                  </div>
                </div>

                <div className="my-2">
                  <Badge
                    className="ml-2"
                    style={{ fontSize: "100%", cursor: "pointer" }}
                    color="danger"
                    onClick={this.deleteOrder}
                  >
                    DELETE ORDER
                  </Badge>
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col>
                <div className="bg-white  border-0  shadow card">
                  <div className="bg-secondary card-header">
                    <div className="align-items-center row">
                      <div className="col-8">
                        <h3 className="mb-0 text-light">
                          Other orders by{" "}
                          {this.ucFirstAllWords(this.state.order.firstName)}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    {this.state.order.user &&
                    this.state.order.user.orders.length > 1 ? (
                      <div>
                        <div className="table-responsive">
                          <table className="table">
                            <thead className="table-borderless">
                              <tr>
                                <th scope="col">ORDER ID</th>
                                <th scope="col">TIME</th>
                                <th scope="col">TOTAL</th>
                                <th scope="col">SHIPPING</th>
                                <th scope="col">PAYMENT TYPE</th>
                                <th scope="col">ORDER STATUS</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.order.user.orders.map(
                                order =>
                                  order.orderid !==
                                    this.state.order.orderid && (
                                    <React.Fragment key={order._id}>
                                      <tr
                                        onClick={e => this.routeToOrder(order)}
                                        className="admin-each-product "
                                        style={{ cursor: "pointer" }}
                                      >
                                        <td
                                          style={{ verticalAlign: "middle" }}
                                          className="font-weight-bold"
                                        >
                                          {order.orderid.toUpperCase()}
                                        </td>
                                        <td
                                          style={{ verticalAlign: "middle" }}
                                          className="font-weight-bold"
                                        >
                                          {moment(order.created_at)
                                            .startOf("hour")
                                            .fromNow()}
                                        </td>
                                        <td
                                          style={{ verticalAlign: "middle" }}
                                          className="font-weight-bold"
                                        >
                                          AUD {(order.total / 100).toFixed(2)}
                                        </td>
                                        <td
                                          style={{ verticalAlign: "middle" }}
                                          className="font-weight-bold"
                                        >
                                          {this.ucFirstAllWords(
                                            order.shippingType
                                          )}
                                        </td>
                                        <td
                                          style={{ verticalAlign: "middle" }}
                                          className="font-weight-bold"
                                        >
                                          {" "}
                                          {this.ucFirstAllWords(
                                            order.paymentType
                                          )}
                                        </td>
                                        <td
                                          style={{ verticalAlign: "middle" }}
                                          className={
                                            order.orderStatus === "Processing"
                                              ? "text-warning font-weight-bold"
                                              : order.orderStatus ===
                                                "Confirmed"
                                              ? "text-success font-weight-bold"
                                              : "text-danger font-weight-bold"
                                          }
                                        >
                                          {" "}
                                          {order.orderStatus}
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <p>No other orders found.</p>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  adminOrders: state.adminOrders
});

export default connect(mapStateToProps, {
  getSelectedOrder,
  updateDeliveryDate,
  resetSelectedOrder,
  deleteSelectedOrder
})(SelectedOrder);
