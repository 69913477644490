import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Label,
  FormGroup,
  Alert,
  Button,
} from "reactstrap";
import classnames from "classnames";
import axios from "axios";

export default class WholesaleInquiry extends Component {
  state = {
    windowWidth: window.innerWidth,
    fullName: "",
    email: "",
    phoneNumber: "",
    businessName: "",
    abn: "",
    category: "",
    website: "",
    instagram: "",
    facebook: "",
    message: "",
    errors: {},
    showAlert: false,
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      errors: {},
    });
  };

  componentDidUpdate(prevState, prevProps) {
    if (this.state.showAlert !== prevState.showAlert && this.state.showAlert) {
      setTimeout(() => {
        this.setState({
          fullName: "",
          email: "",
          phoneNumber: "",
          businessName: "",
          abn: "",
          category: "",
          website: "",
          instagram: "",
          facebook: "",
          message: "",
          errors: {},
          showAlert: false,
        });
      }, 5000);
    }
  }

  componentDidMount() {
    this.updateWindowDimension();
    window.addEventListener("resize", this.updateWindowDimension);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimension);
  }

  updateWindowDimension = (e) => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };

  sendInquiry = (data) => {
    axios
      .post("/api/reviews/becomestockist", data)
      .then((res) => {
        this.setState({
          showAlert: true,
        });
      })
      .catch((err) => {
        this.setState({
          errors: err.response.data,
        });
      });
  };

  handleSubmit = (e) => {
    let data = {};

    data.fullName = this.state.fullName;
    data.email = this.state.email;
    data.phoneNumber = this.state.phoneNumber;
    data.businessName = this.state.businessName;
    data.abn = this.state.abn;
    data.category = this.state.category;
    data.website = this.state.website;
    data.instagram = this.state.instagram;
    data.facebook = this.state.facebook;
    data.message = this.state.message;

    this.sendInquiry(data);
  };

  render() {
    const { errors } = this.state;

    return (
      <div className="container">
        <Row className="my-3">
          <Col>
            <div className=" my-5 text-center ">
              <h1 className="font-weight-bold py-3" style={{ fontSize: 24 }}>
                Become a Stockist
              </h1>
              <div
                className="text-left"
                style={{
                  paddingLeft: this.state.windowWidth > 1000 && "20%",
                  paddingRight: this.state.windowWidth > 1000 && "20%",
                }}
              >
                <p style={{ fontSize: 14 }}>
                  Are you a retailer that would like to add the Remote Trails
                  brand to your product or apparel line while supporting
                  sustainable business and being a part of something that
                  matters? We would love to hear from you!
                </p>
                <p style={{ fontSize: 15 }}>
                  If interested, please fill out the form below and briefly
                  summarize your need(s) and interest. A representative from
                  Remote Trails will get back to you to further discuss your
                  objective and goals. We look forward to speaking with you.
                </p>
              </div>

              <div
                className="text-left"
                style={{
                  paddingLeft: this.state.windowWidth > 1000 && "20%",
                  paddingRight: this.state.windowWidth > 1000 && "20%",
                }}
              >
                <Form
                  className=" p-3"
                  style={{ backgroundColor: "whitesmoke", borderRadius: 10 }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <Label className="font-weight-bold" style={{ fontSize: 15 }}>
                    {" "}
                    Your contact details
                  </Label>
                  <FormGroup>
                    <Input
                      onChange={this.onChange}
                      style={{ fontSize: 13, border: 0 }}
                      type="text"
                      placeholder="Name*"
                      value={this.state.fullName}
                      name="fullName"
                      className={classnames("", {
                        "is-invalid": errors.fullName,
                      })}
                    />
                    <div
                      style={{ fontSize: 12 }}
                      className="invalid-feedback px-2"
                    >
                      {errors.fullName}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Input
                      onChange={this.onChange}
                      style={{ fontSize: 13, border: 0 }}
                      type="email"
                      placeholder="Email*"
                      value={this.state.email}
                      name="email"
                      className={classnames("", { "is-invalid": errors.email })}
                    />
                    <div
                      style={{ fontSize: 12 }}
                      className="px-2 invalid-feedback"
                    >
                      {errors.email}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Input
                      onChange={this.onChange}
                      style={{ fontSize: 13, border: 0 }}
                      type="number"
                      placeholder="Phone number"
                      value={this.state.phoneNumber}
                      name="phoneNumber"
                    />
                  </FormGroup>

                  <Label className="font-weight-bold" style={{ fontSize: 15 }}>
                    About your business & store
                  </Label>
                  <FormGroup>
                    <Input
                      onChange={this.onChange}
                      style={{ fontSize: 13, border: 0 }}
                      type="text"
                      placeholder="Business Name"
                      value={this.state.businessName}
                      name="businessName"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      onChange={this.onChange}
                      style={{ fontSize: 13, border: 0 }}
                      type="number"
                      placeholder="ABN"
                      value={this.state.abn}
                      name="abn"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      onChange={this.onChange}
                      style={{ fontSize: 13, border: 0 }}
                      type="text"
                      placeholder="Category"
                      value={this.state.category}
                      name="category"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      onChange={this.onChange}
                      style={{ fontSize: 13, border: 0 }}
                      type="text"
                      placeholder="Website"
                      value={this.state.website}
                      name="website"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      onChange={this.onChange}
                      style={{ fontSize: 13, border: 0 }}
                      type="text"
                      placeholder="Instagram"
                      value={this.state.instagram}
                      name="instagram"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      onChange={this.onChange}
                      style={{ fontSize: 13, border: 0 }}
                      type="text"
                      placeholder="Facebook"
                      value={this.state.facebook}
                      name="facebook"
                    />
                  </FormGroup>

                  <Label className="font-weight-bold" style={{ fontSize: 15 }}>
                    Your inquiry
                  </Label>

                  <FormGroup>
                    <Input
                      onChange={this.onChange}
                      className={classnames("", { "is-invalid": errors.email })}
                      style={{ fontSize: 13, border: 0 }}
                      type="textarea"
                      rows={10}
                      placeholder="Message*"
                      value={this.state.message}
                      name="message"
                    />
                    <div
                      style={{ fontSize: 12 }}
                      className="px-2 invalid-feedback"
                    >
                      {errors.message}
                    </div>
                  </FormGroup>
                </Form>
                {this.state.showAlert && (
                  <div>
                    <Alert
                      className="my-3 border-0"
                      color="success"
                      style={{ fontSize: 13 }}
                    >
                      We've recieved your inquiry. We'll be in touch within
                      24-48 hours.
                    </Alert>
                  </div>
                )}
                <Button
                  size="sm"
                  style={{ borderRadius: 30, backgroundColor: "#a16348" }}
                  onClick={() => this.handleSubmit()}
                  className="px-5 mt-2 border-0 w-100 p-2"
                >
                  SEND MESSAGE
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
