import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import classnames from "classnames";
import { resetPassword, resetShowMessage } from "../actions/formActions";
import { InputGroup, Button, Input } from "reactstrap";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";

class ResetPasswordForm extends Component {
  state = {
    email: "",
    errors: {},
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onSubmit = (e) => {
    e.preventDefault();
    const data = {
      email: this.state.email,
    };
    this.props.resetPassword(data);
  };

  componentDidUpdate(prevProps) {
    if (this.props.errors !== prevProps.errors) {
      this.setState({
        errors: this.props.errors,
      });
    }
  }
  componentWillUnmount() {
    this.props.resetShowMessage();
  }

  render() {
    const { errors } = this.state;
    if (localStorage.jwtToken) {
      return <Redirect to="/dashboard" />;
    }

    return (
      <div>
        <Helmet>
          <title>Reset Password - Remote Trails</title>
          <meta name="description" content="Reset Password - Remote Trails" />
        </Helmet>
        {this.props.history.location.pathname !== "/order-status" && (
          <div>
            <h1
              className=" text-left mt-5 font-weight-bold"
              style={{ color: "#3d4246", fontSize: 36 }}
            >
              Reset Password
            </h1>
            <p style={{ fontSize: 14 }} className="text-muted">
              Provide your account email address to receive an email to reset
              your password.
            </p>
          </div>
        )}

        <form onSubmit={this.onSubmit}>
          <InputGroup className="my-2">
            <Input
              placeholder="E-mail"
              onChange={this.onChange}
              className={classnames("", { "is-invalid": errors.email })}
              value={this.state.email}
              name="email"
            />
            <div className="invalid-feedback" style={{ fontSize: 12 }}>
              {errors.email}
            </div>
          </InputGroup>
          {this.props.auth.showResetPasswordSuccess && (
            <div>
              <p className="text-success" style={{ fontSize: 14 }}>
                We've sent you an email with a link to update your password.
              </p>
            </div>
          )}

          <div>
            <Button
              className="w-100"
              onClick={this.onSubmit}
              style={{
                borderRadius: "20px",
                fontWeight: 700,
                background: "black",
                color: "white",
                cursor: "pointer",
              }}
            >
              Send
            </Button>
          </div>

          <p className="text-center my-4">
            <span
              onClick={() => this.props.setFormType("login")}
              style={{
                fontSize: 14,
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Back
            </span>
          </p>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  errors: state.errors,
  auth: state.form,
  cart: state.cart,
});

export default connect(mapStateToProps, { resetPassword, resetShowMessage })(
  withRouter(ResetPasswordForm)
);
