import React, { Component } from 'react'
import { Row, Col, Button, Modal, ModalBody, ModalFooter } from 'reactstrap'
import { getCategories, deleteCategory, activationStatus } from '../../actions/adminCategoryActions'
import { connect } from 'react-redux'



class Categories extends Component {

    handleNewCategoryClick = e => {
        this.props.history.push('/adminpanel/categories/newCategory')
    }

    componentDidMount() {
        this.props.getCategories()
    }

    deleteCategory = item => {
        this.props.deleteCategory(item._id)
    }

    handleActivationStatus = item => {
        let data = {}
        if (item.isVisible) {
            data.isVisible = false
        } else {
            data.isVisible = true
        }
        this.props.activationStatus(item._id, data)

    }

    render() {
        return (
            <div>
                <Row>
                    <Col>
                        <div>
                            <Button color='success' size='sm' onClick={this.handleNewCategoryClick}>New Category</Button>
                        </div>
                    </Col>
                </Row>
                <div>
                    {this.props.categories.loading ? (
                        <div className='text-center text-light my-5'>
                            <h5>Please wait. Categories are being fetched.</h5>
                        </div>
                    ) : (
                            this.props.categories.categories.length === 0 ? (
                                <div className='text-center text-light my-5'>
                                    <h5>No results found.</h5>
                                </div>
                            ) : (
                                    <div className='bg-light' style={{ borderRadius: 10 }}>
                                        <Row>
                                            <Col className='px-3'>
                                                <div className='table-responsive'>
                                                    <table className="table">
                                                        <thead className='table-borderless'>
                                                            <tr>
                                                                <th scope="col">NAME</th>
                                                                <th scope="col">VISIBILITY</th>
                                                                <th scope="col"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody >
                                                            {this.props.categories.categories.map(item => (
                                                                <tr
                                                                    key={item._id} style={{ cursor: 'pointer' }}>
                                                                    <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>  {item.name}</td>
                                                                    <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>{item.isVisible ? 'Visible' : 'Hidden'}</td>
                                                                    <td style={{ verticalAlign: 'middle' }} className='font-weight-bold text-center'><Button onClick={() => this.handleActivationStatus(item)}
                                                                        color={item.isVisible ? 'warning' : 'success'}>{item.isVisible ? 'Hide' : 'Display'}</Button>
                                                                        <Button className='ml-2' onClick={() => this.deleteCategory(item)} color='danger'>Delete</Button></td>
                                                                </tr>
                                                            ))}

                                                        </tbody>
                                                    </table>
                                                </div>

                                            </Col>
                                        </Row>
                                    </div>
                                )
                        )}
                </div>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    categories: state.adminCategories
})


export default connect(mapStateToProps, { getCategories, deleteCategory, activationStatus })(Categories)