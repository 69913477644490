import React from "react";
import { NavbarBrand } from "reactstrap";
import useWindowDimensions from "./useWindowDimensions";
import logo from "../images/remote-trails-logo.png";

const Logo = () => {
  const { height, width } = useWindowDimensions();

  return (
    <div>
      <NavbarBrand href="/">
        <img
          src={logo}
          style={{ height: width < 768 ? 55 : 60 }}
          alt="Inspired by Australia's Natinal Wonders | Remote Trails Apparel + Gear"
        />
      </NavbarBrand>
    </div>
  );
};

export default Logo;
