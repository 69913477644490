import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import { Formik } from "formik";
import { Input, Button } from "reactstrap";
import * as Yup from "yup";
import Axios from "axios";
import { connect } from "react-redux";
import { lead } from "../utils/analytics";

function WelcomeModal(props) {
  const [open, setOpen] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);

  const updateModalStatus = () => {
    setOpen(true);
    localStorage.setItem("welcomeModal", Date.now());
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 500 && !localStorage.welcomeModal) {
      updateModalStatus();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleForm = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    const data = {
      email: values.email,
      cart: props.shoppingCart.cart,
    };

    Axios.post("/api/guestUsers/newsletter", data)
      .then((res) => {
        setShowSuccess(true);
        resetForm();
        lead(this.props.shoppingCart.cart, "Newsletter");
      })
      .catch((err) => {
        console.log(err.response.data);
        setFormErrors(err.response.data);
        setSubmitting(false);
      });
  };

  const validation = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
  });

  return (
    <div>
      <Modal isOpen={open} toggle={() => setOpen(!open)}>
        <div
          className="p-5"
          style={{ backgroundColor: "whitesmoke", color: "black" }}
        >
          <div
            onClick={() => setOpen(!open)}
            className="text-right"
            style={{ cursor: "pointer" }}
          >
            &times;
          </div>
          <div>
            <p style={{ fontSize: 25, fontWeight: 400 }}>Stay Connected</p>
            <p style={{ fontSize: 15 }}>GET 10% OFF YOUR FIRST ORDER</p>
          </div>
          <div className="">
            <p style={{ fontSize: 14 }}>
              Sign-up to stay up to date and score 10% off your first order
            </p>
          </div>
          <div>
            <Formik
              initialValues={{ email: "" }}
              validationSchema={validation}
              onSubmit={handleForm}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                isSubmitting,
                setErrors,
                /* and other goodies */
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Input
                      placeholder="Enter your email"
                      type="email"
                      value={values.email}
                      name="email"
                      onChange={(e) => {
                        handleChange(e);
                        setErrors({});
                        setFormErrors({});
                      }}
                    />
                    <div style={{ color: "red", fontSize: 12 }}>
                      {errors.email}
                      {formErrors?.userExists &&
                        "Email address has already subscribed."}
                    </div>

                    <div style={{ color: "#28a745", fontSize: 12 }}>
                      {showSuccess &&
                        "Thanks for signing up to our newsletter. Check your email to see your 10% off promo code!"}
                    </div>
                    <Button
                      disabled={isSubmitting}
                      onClick={handleSubmit}
                      className="mt-3 px-5"
                      style={{
                        borderRadius: 30,
                        backgroundColor: "#a16348",
                        border: "none",
                      }}
                    >
                      Subscribe
                    </Button>
                  </form>
                );
              }}
            </Formik>
          </div>

          <div className="mt-5">
            <Button
              onClick={() => setOpen(!open)}
              style={{
                backgroundColor: "transparent",
                border: "none",
                textDecoration: "underline",
                padding: 0,
                color: "#a36349",
                fontSize: 12,
              }}
            >
              No thanks
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  shoppingCart: state.cart,
});

export default connect(mapStateToProps, {})(WelcomeModal);
