import React, { Component } from "react";
import {
  Col,
  Row,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  InputGroup,
  Input,
} from "reactstrap";
import classnames from "classnames";
import StandardButton from "./StandardButton";
import Axios from "axios";

export default class HowAreWeDoing extends Component {
  state = {
    showModal: false,
    hoverStars: 0,
    stars: 0,
    email: "",
    feedback: "",
    showSuccess: false,
    errors: {},
  };

  handleStars = (stars) => {
    this.setState({
      stars,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      errors: {},
    });
  };

  handleSubmit = (e) => {
    this.setState({
      errors: {},
      showSuccess: false,
    });

    const data = {
      email: this.state.email,
      feedback: this.state.feedback,
      stars: this.state.stars,
    };

    Axios.post("/api/reviews/feedback", data)
      .then((res) => {
        this.setState({
          showSuccess: true,
          stars: "",
          email: "",
          feedback: "",
          errors: {},
        });
      })
      .catch((err) => {
        this.setState({ errors: err.response.data });
      });
  };

  render() {
    const { errors } = this.state;

    return (
      <Row className="p-0 m-0">
        <Col className="p-0 m-0">
          <div
            className="position-fixed p-0 m-0"
            style={{ top: "50%", left: -20 }}
            onClick={() => this.setState({ showModal: !this.state.showModal })}
          >
            {this.state.showModal && (
              <div>
                <Modal
                  isOpen={this.state.showModal}
                  toggle={() =>
                    this.setState({ showModal: !this.state.showModal })
                  }
                >
                  <ModalHeader className="position-relative border-0 p-0">
                    <Button
                      className="border-0 position-absolute"
                      style={{
                        backgroundColor: "#a06348",
                        right: 10,
                        top: -15,
                        borderRadius: 20,
                      }}
                      onClick={() =>
                        this.setState({ showModal: !this.state.showModal })
                      }
                    >
                      <span
                        className="font-weight-bold"
                        style={{
                          fontSize: 20,
                          lineHeight: "20px",
                          verticalAlign: "middle",
                        }}
                      >
                        &times;{" "}
                      </span>
                    </Button>
                  </ModalHeader>

                  <ModalBody className="text-center mt-2">
                    How would you rate your experience on this website?
                  </ModalBody>

                  <div className="text-center pb-3">
                    <i
                      style={{
                        cursor: "pointer",
                        color:
                          (this.state.hoverStars >= 1 ||
                            this.state.stars >= 1) &&
                          "gold",
                      }}
                      className="fas fa-star mx-1 feedback-star"
                      onMouseLeave={() => this.setState({ hoverStars: 0 })}
                      onMouseEnter={() => this.setState({ hoverStars: 1 })}
                      onClick={() => this.handleStars(1)}
                    ></i>
                    <i
                      style={{
                        cursor: "pointer",
                        color:
                          (this.state.hoverStars >= 2 ||
                            this.state.stars >= 2) &&
                          "gold",
                      }}
                      className="fas fa-star mx-1 feedback-star"
                      onMouseLeave={() => this.setState({ hoverStars: 0 })}
                      onMouseEnter={() => this.setState({ hoverStars: 2 })}
                      onClick={() => this.handleStars(2)}
                    ></i>
                    <i
                      style={{
                        cursor: "pointer",
                        color:
                          (this.state.hoverStars >= 3 ||
                            this.state.stars >= 3) &&
                          "gold",
                      }}
                      className="fas fa-star mx-1 feedback-star"
                      onMouseLeave={() => this.setState({ hoverStars: 0 })}
                      onMouseEnter={() => this.setState({ hoverStars: 3 })}
                      onClick={() => this.handleStars(3)}
                    ></i>
                    <i
                      style={{
                        cursor: "pointer",
                        color:
                          (this.state.hoverStars >= 4 ||
                            this.state.stars >= 4) &&
                          "gold",
                      }}
                      className="fas fa-star mx-1 feedback-star"
                      onClick={() => this.handleStars(4)}
                      onMouseLeave={() => this.setState({ hoverStars: 0 })}
                      onMouseEnter={() => this.setState({ hoverStars: 4 })}
                    ></i>
                    <i
                      style={{
                        cursor: "pointer",
                        color:
                          (this.state.hoverStars >= 5 ||
                            this.state.stars >= 5) &&
                          "gold",
                      }}
                      className="fas fa-star mx-1 feedback-star"
                      onClick={() => this.handleStars(5)}
                      onMouseLeave={() => this.setState({ hoverStars: 0 })}
                      onMouseEnter={() => this.setState({ hoverStars: 5 })}
                    ></i>
                  </div>

                  <div
                    className="text-danger text-center "
                    style={{ fontSize: 12.8 }}
                  >
                    {errors.stars}
                  </div>

                  <Form className="mx-5" onSubmit={(e) => e.preventDefault()}>
                    <InputGroup className="my-2">
                      <InputGroup className="">
                        <Input
                          placeholder="Email"
                          onChange={this.onChange}
                          className={classnames("", {
                            "is-invalid": errors.email,
                          })}
                          value={this.state.email}
                          name="email"
                        />
                        <div className="invalid-feedback">{errors.email}</div>
                      </InputGroup>
                    </InputGroup>
                    <InputGroup className="mt-2">
                      <InputGroup className="">
                        <Input
                          placeholder="Feedback"
                          onChange={this.onChange}
                          className={classnames("", {
                            "is-invalid": errors.feedback,
                          })}
                          value={this.state.feedback}
                          name="feedback"
                          type="textarea"
                          row={20}
                        />
                        <div className="invalid-feedback">
                          {errors.feedback}
                        </div>
                      </InputGroup>
                    </InputGroup>
                  </Form>

                  {this.state.showSuccess && (
                    <div className="text-success my-2 text-center">
                      Thanks for you feedback!
                    </div>
                  )}

                  <div className="mx-5 mb-4">
                    <StandardButton
                      handleClick={() => this.handleSubmit()}
                      text="Submit"
                      style={{ backgroundColor: "#a06348", borderRadius: 50 }}
                    />
                  </div>
                </Modal>
              </div>
            )}

            <div
              className="m-0 p-0"
              style={{
                transform: "rotate(-90deg)",
                backgroundColor: "#a06348",
                cursor: "pointer",
              }}
            >
              <p
                className="p-2 text-light"
                style={{ fontSize: 13, lineHeight: "13px" }}
              >
                Feedback
              </p>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}
