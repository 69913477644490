import React, { Component } from "react";
import {
  InputGroup,
  Input,
  Form,
  Label,
  Button,
  FormGroup,
  Row,
  Col,
  InputGroupAddon,
  InputGroupText,
  UncontrolledTooltip,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import {
  guestUser,
  updateInputs,
  updateRadioBox,
  verifyUser,
  setShippingMethod,
  setShippingFee,
} from "../actions/checkOutActions";
import { connect } from "react-redux";
import classnames from "classnames";
import ButtonSpinner from "./ButtonSpinner";
import { logoutUser } from "../actions/formActions";
import PaymentRequestForm from "./PaymentRequestForm";
import { Elements } from "react-stripe-elements";
import { lead } from "../utils/analytics";

const { compose, withProps, lifecycle } = require("recompose");
const { withScriptjs } = require("react-google-maps");
const {
  StandaloneSearchBox,
} = require("react-google-maps/lib/components/places/StandaloneSearchBox");

const mapStateToProps = (state) => ({
  checkout: state.checkout,
  shoppingCart: state.cart,
  guest: state.guest,
  auth: state.form,
});

const PlacesWithStandaloneSearchBox = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
  }),
  lifecycle({
    componentDidMount() {
      const refs = {};

      this.setState({
        selectedAddress: "",
        places: [],
        onSearchBoxMounted: (ref) => {
          refs.searchBox = ref;
        },
        onPlacesChanged: () => {
          const places = refs.searchBox.getPlaces();

          let address = "";
          let streetNumber = "";
          let streetName = "";
          let city = "";
          let region = "";
          let postcode = "";
          let country = "";
          let apartmentNumber = "";

          for (let i = 0; i < places[0].address_components.length; i++) {
            if (places[0].address_components[i].types[0] === "subpremise") {
              apartmentNumber = places[0].address_components[i].long_name;
            }

            if (places[0].address_components[i].types[0] === "street_number") {
              streetNumber = places[0].address_components[i].long_name;
            }
            if (places[0].address_components[i].types[0] === "route") {
              streetName = places[0].address_components[i].long_name;
            }
            if (places[0].address_components[i].types[0] === "locality") {
              city = places[0].address_components[i].long_name;
            }
            if (
              places[0].address_components[i].types[0] ===
              "administrative_area_level_1"
            ) {
              region = places[0].address_components[i].long_name;
            }
            if (places[0].address_components[i].types[0] === "country") {
              country = places[0].address_components[i].long_name;
            }
            if (places[0].address_components[i].types[0] === "postal_code") {
              postcode = places[0].address_components[i].long_name;
            }
          }

          address = streetNumber + " " + streetName;

          if (apartmentNumber !== "") {
            address = apartmentNumber + "/" + address;
          }

          this.props.updateInputs("address", address);
          this.props.updateInputs("city", city);
          this.props.updateInputs("postcode", postcode);
          this.props.updateInputs("region", region);
          this.props.updateInputs("country", country);
        },
      });
    },
  }),
  withScriptjs
)((props) => (
  <div data-standalone-searchbox="">
    <StandaloneSearchBox
      ref={props.onSearchBoxMounted}
      // bounds={props.bounds}
      onPlacesChanged={props.onPlacesChanged}
      bounds={
        new window.google.maps.LatLngBounds(
          new window.google.maps.LatLng(-37.8274, 144.7702),
          new window.google.maps.LatLng(-37.8274, 144.7702)
        )
      }
    >
      <InputGroup>
        <Input
          type="text"
          className="form-control"
          placeholder="Address*"
          onChange={(e) => {
            props.updateInputs("address", e.target.value);
          }}
          value={props.checkout.address}
          name="address"
        />
      </InputGroup>
    </StandaloneSearchBox>
  </div>
));

const ConnectedSearchBox = connect(mapStateToProps, {
  logoutUser,
  guestUser,
  updateInputs,
  updateRadioBox,
  verifyUser,
  setShippingFee,
  setShippingMethod,
})(PlacesWithStandaloneSearchBox);

class UserInfo extends Component {
  state = {
    newAddress: true,
    windowWidth: 0,
  };

  componentDidMount() {
    this.setState({
      windowWidth: window.innerWidth,
    });
    window.addEventListener("resize", this.updateWindowDimension);

    if (this.props.auth.isAuthenticated) {
      if (this.props.auth.user.firstName) {
        let firstName = "firstname";
        this.props.updateInputs(firstName, this.props.auth.user.firstName);
      }
      if (this.props.auth.user.lastName) {
        let lastName = "lastname";
        this.props.updateInputs(lastName, this.props.auth.user.lastName);
      }
      if (this.props.auth.user.email) {
        let email = "email";
        this.props.updateInputs(
          email,
          this.props.auth.user.email.toLowerCase()
        );
      }

      if (this.props.auth.user.phoneNumber) {
        let phoneNumber = "phoneNumber";
        this.props.updateInputs(phoneNumber, this.props.auth.user.phoneNumber);
      }

      if (this.props.auth.user.adress.length !== 0) {
        let defaultAddress = this.props.auth.user.adress.filter(
          (eachAdress) => eachAdress.addressType === "default"
        );

        let address = defaultAddress[0].streetAdress;
        let city = defaultAddress[0].city;
        let postcode = defaultAddress[0].zipCode;
        let region = defaultAddress[0].region;
        let country = defaultAddress[0].country;

        this.props.updateInputs("address", address);
        this.props.updateInputs("city", city);
        this.props.updateInputs("postcode", postcode);
        this.props.updateInputs("region", region);
        this.props.updateInputs("country", country);

        this.setState({
          newAddress: false,
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.checkout.buttonLoading !== prevProps.checkout.buttonLoading
    ) {
      if (
        !this.props.checkout.buttonLoading &&
        Object.keys(this.props.checkout.errors).length === 0
      ) {
        this.props.nextStep();
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimension);
  }
  updateWindowDimension = (e) => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };
  onChange = (e) => {
    var field = e.target.name;
    var inputValue = e.target.value;
    this.props.updateInputs(field, inputValue);
    if (!this.state.newAddress) {
      this.setState({
        newAddress: true,
      });
    }
  };

  nextStep = (e) => {
    e.preventDefault();

    if (!this.props.auth.isAuthenticated) {
      var guestUser = {
        email: this.props.checkout.email.toLowerCase(),
        firstname: this.props.checkout.firstname,
        lastname: this.props.checkout.lastname,
        sendOffers: this.props.checkout.sendOffers,
        zipCode: this.props.checkout.postcode,
        cart: this.props.shoppingCart.cart,
        guestUser: this.props.checkout.guestUser,
        streetAdress: this.props.checkout.address,
        city: this.props.checkout.city,
        region: this.props.checkout.region,
        country: this.props.checkout.country,
        phoneNumber: this.props.checkout.phoneNumber.replace(/\s/g, ""),
      };
      this.props.guestUser(guestUser);
    } else {
      var userData = {
        user: this.props.auth.user.id,
        zipCode: this.props.checkout.postcode,
        streetAdress: this.props.checkout.address,
        city: this.props.checkout.city,
        email: this.props.checkout.email.toLowerCase(),
        firstname: this.props.checkout.firstname,
        lastname: this.props.checkout.lastname,
        country: this.props.checkout.country,
        region: this.props.checkout.region,
        newAddress: this.state.newAddress,
        phoneNumber: this.props.checkout.phoneNumber.replace(/\s/g, ""),
      };

      if (this.props.checkout.region !== "") {
        userData.region = this.props.checkout.region;
      }
      lead(this.props.shoppingCart.cart);
      this.props.verifyUser(userData);
    }
  };

  handleRadioBox = (e) => {
    this.props.updateRadioBox(!this.props.checkout.sendOffers);
  };
  handleSavedAdress = (e) => {
    let val = e.target.value;

    if (val === "new address") {
      this.props.updateInputs("address", "");
      this.props.updateInputs("city", "");
      this.props.updateInputs("postcode", "");
      this.props.updateInputs("region", "");
      this.props.updateInputs("country", "");

      if (!this.state.newAddress) {
        this.setState({
          newAddress: true,
        });
      }
    } else {
      let selectedAddress = this.props.auth.user.adress.filter(
        (eachAdress) => eachAdress._id === val
      );
      let address = selectedAddress[0].streetAdress;
      let city = selectedAddress[0].city;
      let postcode = selectedAddress[0].zipCode;
      let region = selectedAddress[0].region;
      let country = selectedAddress[0].country;

      this.props.updateInputs("address", address);
      this.props.updateInputs("city", city);
      this.props.updateInputs("postcode", postcode);
      this.props.updateInputs("region", region);
      this.props.updateInputs("country", country);

      if (this.state.newAddress) {
        this.setState({
          newAddress: false,
        });
      }
    }
  };

  onLogoutClick = () => {
    this.props.logoutUser();
    this.props.history.push("/login");
  };

  goBackToPrevious = (e) => {
    this.props.history.push("/cart");
  };

  render() {
    return (
      <div className="container py-4" style={{ maxWidth: 650 }}>
        {/* <Row>
                    <Col>
                        <Elements>
                            <PaymentRequestForm />
                        </Elements>
                    </Col>
                </Row> */}

        <div className="">
          <h5 className="text-left " style={{ fontSize: "1.28571em" }}>
            Contact Information
            {!this.props.auth.isAuthenticated && (
              <span
                className="float-right "
                style={{ fontSize: "14px", paddingTop: 5 }}
              >
                <a href="/login" className="text-muted">
                  {" "}
                  Already have an account? Log in
                </a>
              </span>
            )}
          </h5>
          {!this.props.auth.isAuthenticated ? (
            <Form className="pt-2">
              <InputGroup>
                <Input
                  className={classnames("", {
                    "is-invalid": this.props.checkout.errors.email,
                  })}
                  placeholder="Email*"
                  type="email"
                  onChange={this.onChange}
                  value={this.props.checkout.email}
                  name="email"
                />
                <div className="invalid-feedback">
                  {this.props.checkout.errors.email}
                </div>
              </InputGroup>

              <InputGroup className="pl-4 pt-2">
                <Label check>
                  <Input
                    type="checkbox"
                    id="offers"
                    checked={this.props.checkout.sendOffers}
                    onChange={this.handleRadioBox}
                  />
                  Keep me up to date on news and exclusive offers
                </Label>
              </InputGroup>
            </Form>
          ) : (
            <div className="pt-2">
              <div style={{ display: "table-cell" }}>
                <i
                  className="far fa-user fa-2x  p-3 text-muted"
                  style={{ borderRadius: 15, border: "2px solid" }}
                ></i>
              </div>
              <p style={{ display: "table-cell", verticalAlign: "middle" }}>
                <span className="px-2">
                  {this.props.auth.user.firstName}{" "}
                  {this.props.auth.user.lastName}
                </span>
                <span>({this.props.auth.user.email})</span>
                <br />
                <a
                  href="#"
                  style={{ cursor: "pointer" }}
                  onClick={this.onLogoutClick}
                  className="px-2 checkout-logout"
                >
                  Log out
                </a>
              </p>
            </div>
          )}
        </div>

        <div className="mt-3">
          {this.props.checkout.shippingMethod === "delivery" && (
            <h5 className="text-left" style={{ fontSize: "1.28571em" }}>
              Shipping Address
            </h5>
          )}
          {this.props.checkout.shippingMethod === "pickup" && (
            <h5 className="text-left" style={{ fontSize: "1.28571em" }}>
              Pick-up Details
            </h5>
          )}
          <Form className="pt-3">
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Input
                    className={classnames("", {
                      "is-invalid": this.props.checkout.errors.firstname,
                    })}
                    placeholder="First Name*"
                    type="text"
                    onChange={this.onChange}
                    value={this.props.checkout.firstname}
                    name="firstname"
                  />
                  <div className="invalid-feedback">
                    {this.props.checkout.errors.firstname}
                  </div>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Input
                    className={classnames("", {
                      "is-invalid": this.props.checkout.errors.lastname,
                    })}
                    placeholder="Last Name*"
                    type="text"
                    onChange={this.onChange}
                    value={this.props.checkout.lastname}
                    name="lastname"
                  />
                  <div className="invalid-feedback">
                    {this.props.checkout.errors.lastname}
                  </div>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <InputGroup>
                    <Input
                      className={classnames("", {
                        "is-invalid": this.props.checkout.errors.phoneNumber,
                      })}
                      placeholder="Phone Number*"
                      type="text"
                      onChange={this.onChange}
                      value={this.props.checkout.phoneNumber}
                      name="phoneNumber"
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText id="phoneNumber-tooltip">
                        ?
                      </InputGroupText>
                      <UncontrolledTooltip
                        placement="right"
                        target="phoneNumber-tooltip"
                      >
                        In case we need to contact you about your order
                      </UncontrolledTooltip>
                    </InputGroupAddon>
                  </InputGroup>
                  <div className="invalid-feedback">
                    {this.props.checkout.errors.phoneNumber}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            {this.props.auth.isAuthenticated &&
              this.props.auth.user.adress.length !== 0 && (
                <FormGroup>
                  <Label for="saved-addresses">Saved Addresses</Label>
                  <Input
                    type="select"
                    name="saved-addresses"
                    id="saved-addresses"
                    onChange={this.handleSavedAdress}
                  >
                    {this.props.auth.user.adress.map((eachAdress) => (
                      <option value={eachAdress._id} key={eachAdress._id}>
                        {eachAdress.streetAdress} {eachAdress.city}{" "}
                        {eachAdress.zipCode} {eachAdress.country}
                      </option>
                    ))}
                    <option value="new address">Use a new address</option>
                  </Input>
                </FormGroup>
              )}
            <FormGroup>
              <ConnectedSearchBox />
            </FormGroup>

            {/* <FormGroup>
                            <Input
                                disabled={!this.state.newAddress}
                                placeholder='Address*' type='text' onChange={this.onChange} value={this.props.checkout.address} name='address' />
                        </FormGroup> */}

            <FormGroup>
              <Input
                disabled={!this.state.newAddress}
                placeholder="City*"
                type="text"
                onChange={this.onChange}
                value={this.props.checkout.city}
                name="city"
              />
            </FormGroup>

            <Row form>
              {this.props.checkout.country !== "United Kingdom" && (
                <Col>
                  <FormGroup>
                    <Input
                      disabled={!this.state.newAddress}
                      placeholder={
                        this.props.checkout.country === "New Zealand"
                          ? "Region*"
                          : "State*"
                      }
                      type="text"
                      onChange={this.onChange}
                      value={this.props.checkout.region}
                      name="region"
                    />
                  </FormGroup>
                </Col>
              )}

              <Col>
                <FormGroup>
                  <Input
                    disabled={!this.state.newAddress}
                    className={classnames("", {
                      "is-invalid": this.props.checkout.errors.postcode,
                    })}
                    placeholder="Postal Code*"
                    type="text"
                    onChange={this.onChange}
                    value={this.props.checkout.postcode}
                    name="postcode"
                  />
                  <div className="invalid-feedback">
                    {this.props.checkout.errors.postcode}
                  </div>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Input
                    disabled={!this.state.newAddress}
                    placeholder="Country"
                    type="select"
                    onChange={this.onChange}
                    value={this.props.checkout.country}
                    name="country"
                  >
                    <option>Australia</option>
                    <option>New Zealand</option>
                    <option>Canada</option>
                    <option>United Kingdom</option>
                    <option>United States</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Input
                type="textarea"
                rows="8"
                placeholder="Please add any notes regarding you order"
                onChange={this.onChange}
                value={this.props.checkout.description}
                name="description"
              />
            </FormGroup>
          </Form>

          <Row>
            <Col md="6" xs="12" className="order-2 order-md-1  my-2">
              <Button
                disabled={this.props.checkout.buttonLoading}
                size="sm"
                color="dark"
                outline
                className="font-weight-bold w-100"
                onClick={this.goBackToPrevious}
              >
                &lt; Cart
              </Button>
            </Col>
            <Col md="6" xs="12" className="order-1 text-right my-2 order-md-2 ">
              <Button
                size="sm"
                className="w-100"
                disabled={
                  this.props.checkout.buttonLoading ||
                  this.props.checkout.email === "" ||
                  this.props.checkout.firstname === "" ||
                  this.props.checkout.lastname === "" ||
                  this.props.checkout.address === "" ||
                  this.props.checkout.city === "" ||
                  this.props.checkout.postcode === "" ||
                  this.props.checkout.phoneNumber === "" ||
                  this.props.checkout.country === "" ||
                  (this.props.checkout.country !== "United Kingdom" &&
                    this.props.checkout.region === "")
                }
                color="success"
                onClick={this.nextStep}
                style={{ minWidth: 182 }}
              >
                {this.props.checkout.buttonLoading ? (
                  <ButtonSpinner />
                ) : (
                  <span> Continue to Shipping &gt;</span>
                )}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, {
  logoutUser,
  guestUser,
  updateInputs,
  updateRadioBox,
  verifyUser,
  setShippingFee,
  setShippingMethod,
})(withRouter(UserInfo));
