import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Row, Col, NavbarBrand, Collapse, Label } from "reactstrap";
import StepWizard from "react-step-wizard";
import UserInfo from "./UserInfo";
import Payment from "./Payment";
import Shipping from "./Shipping";
import CheckoutNavBar from "./CheckoutNavBar";
import { Helmet } from "react-helmet";
import LoadingOverlay from "react-loading-overlay";
import {
  couponInputUpdate,
  applyCoupon,
  resetCouponError,
} from "../actions/discountActions";
// import ButtonSpinner from './ButtonSpinner'
// import classnames from 'classnames'
import { validateCart, resetWarnings } from "../actions/shoppingCart";
import { setShippingFee, updateInputs } from "../actions/checkOutActions";
import logo from "../images/remote-trails-logo.png";
import { getCartTotal } from "../utils/getCartTotal";
import EachProductList from "./EachProductList";
import ProgressBar from "./ProgressBar";
import TotalLabel from "./TotalLabel";
import CheckoutShippingLabel from "./CheckoutShippingLabel";
import SubTotalLabel from "./SubTotalLabel";
import DiscountSection from "./DiscountSection";
import CheckoutRightColumn from "./CheckoutRightColumn";

class Checkout extends Component {
  state = {
    isOpen: false,
  };

  handleSwitch = (e) => {
    var field = e.target.name;
    var inputValue = e.target.checked;
    this.props.updateInputs(field, inputValue);
  };

  componentDidUpdate(prevProps) {
    if (this.props.orders.order !== prevProps.orders.order) {
      this.props.history.push({
        pathname: `/order-received/${this.props.orders.order._id}`,
        state: {
          order: this.props.orders.order,
        },
      });
    }
    if (
      this.props.discount.errors !== prevProps.discount.errors &&
      Object.keys(this.props.discount.errors).length > 0
    ) {
      setTimeout(this.resetCoupon, 4000);
    }
    if (
      this.props.shoppingCart.cart !== prevProps.shoppingCart.cart &&
      this.props.shoppingCart.cart.length === 0
    ) {
      this.props.setShippingFee(0);
    }
  }
  updateCoupon = (e) => {
    var field = e.target.name;
    var inputValue = e.target.value;
    this.props.couponInputUpdate(field, inputValue);
  };

  handleCoupon = (e) => {
    e.preventDefault();
    let couponData = {};
    couponData.coupon = this.props.discount.coupon;
    this.props.applyCoupon(couponData);
  };

  toggle = (e) => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  resetCoupon = () => {
    this.props.resetCouponError();
  };

  componentDidMount() {
    let userid;

    if (this.props.auth.isAuthenticated) {
      userid = this.props.auth.user.id;
    } else if (this.props.guest.userid !== "") {
      userid = this.props.guest.userid;
    }

    this.props.validateCart(this.props.shoppingCart.cart, userid);
  }
  componentWillUnmount() {
    this.props.resetWarnings();
  }

  render() {
    let subTotal = 0;

    if (this.props.shoppingCart.cart.length !== 0) {
      subTotal =
        Math.round(
          this.props.shoppingCart.cart
            .map((eachItem) => eachItem.quantity * eachItem.product.price)
            .reduce((total, currentValue) => total + currentValue) * 100
        ) / 100;
    }

    let total = subTotal;
    let discount;

    if (this.props.checkout.shippingFee !== null) {
      total = total + this.props.checkout.shippingFee;
    }

    if (Object.keys(this.props.discount.userCoupon).length > 0) {
      if (subTotal >= this.props.discount.userCoupon.minimumSpend) {
        if (this.props.discount.userCoupon.isPercent) {
          discount = (subTotal / 100) * this.props.discount.userCoupon.amount;
        } else {
          discount = this.props.discount.userCoupon.amount;
        }
      }
    }

    if (discount !== undefined) {
      total = total - discount;
    }
    if (total < 0) {
      total = 0;
    }

    if (this.props.shoppingCart.cart.length === 0) {
      return <Redirect to="/cart" />;
    }

    let cartTotal = getCartTotal(this.props.shoppingCart.cart) || 0;

    return (
      <LoadingOverlay active={this.props.checkout.fullPageLoading} spinner>
        <div
          className="border "
          style={{
            minHeight: "100vh",
            backgroundColor: "#fafafa",
          }}
        >
          <div>
            <Helmet>
              <title>Checkout - Remote Trails</title>
              <meta name="description" content="Checkout - Remote Trails" />
            </Helmet>
          </div>

          <Row className="mt-3 page-container mx-auto">
            <Col lg={7} className="border-right">
              <NavbarBrand href="/" className="w-100 text-center ">
                <img
                  style={{ maxHeight: 100 }}
                  src={logo}
                  alt="Remote Trails"
                />
              </NavbarBrand>
              <div
                className=" border-top border-bottom mt-3 d-lg-none"
                onClick={this.toggle}
                style={{ cursor: "pointer" }}
              >
                <p className="pt-3">
                  <i className="fas fa-shopping-cart pr-2"></i>
                  Show order summary
                  <i className="fas fa-chevron-down pl-2"></i>
                  <span className="float-right">AU ${total.toFixed(2)}</span>
                </p>
              </div>
              <Collapse isOpen={this.state.isOpen} className=" d-lg-none">
                {this.props.shoppingCart.cart.map((product) => (
                  <EachProductList product={product} />
                ))}

                {this.props.shoppingCart.warnings.map((eachWarning, index) => (
                  <div key={index} className="border-bottom">
                    <Row className="px-2 py-3">
                      <Col xs="2">
                        <img
                          src={eachWarning.product.imageList[0].imageUrl}
                          alt={eachWarning.product.name}
                          style={{ height: 60 }}
                        />
                      </Col>
                      <Col xs="10">
                        <div className="pt-2">
                          <span className="w-75 d-inline float-left  pl-3">
                            {" "}
                            {eachWarning.product.name}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row className="px-4 py-1 ">
                      <p className="font-weight-bold text-danger">
                        {eachWarning.definition}
                      </p>
                    </Row>
                  </div>
                ))}

                <div className="border-bottom pt-3">
                  <SubTotalLabel total={subTotal} />
                  <CheckoutShippingLabel
                    shippingFee={this.props.checkout.shippingFee}
                  />
                  <DiscountSection
                    coupon={this.props.discount.userCoupon}
                    discount={discount}
                    subTotal={subTotal}
                  />
                </div>
                <TotalLabel total={total} />
                <ProgressBar total={cartTotal} />
              </Collapse>

              {this.props.checkout.errors.noMatch && (
                <Row className="py-3">
                  <Col>
                    <Label className="text-danger">
                      {this.props.checkout.errors.noMatch}
                    </Label>
                  </Col>
                </Row>
              )}

              <StepWizard nav={<CheckoutNavBar />} isHashEnabled={true}>
                <UserInfo hashKey={"userDetails"} />
                <Shipping hashKey={"shipping"} />
                <Payment hashKey={"payment"} />
              </StepWizard>
            </Col>

            <CheckoutRightColumn
              discount={discount}
              subTotal={subTotal}
              total={total}
              cart={this.props.shoppingCart.cart}
              shippingFee={this.props.checkout.shippingFee}
              coupon={this.props.discount.userCoupon}
            />
          </Row>
        </div>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => ({
  shoppingCart: state.cart,
  checkout: state.checkout,
  guest: state.guest,
  auth: state.form,
  orders: state.orders,
  discount: state.discount,
});

export default connect(mapStateToProps, {
  updateInputs,
  setShippingFee,
  couponInputUpdate,
  applyCoupon,
  validateCart,
  resetWarnings,
  resetCouponError,
})(Checkout);
