import React, { Component } from 'react'
import image from '../images/aboutBase.jpg'
import {
    Row, Col, Container
} from 'reactstrap';
import Fade from 'react-reveal/Fade';
import SearchBar from './SearchBar'


export default class Base extends Component {
    render() {
        return (
            <div>
                <div className='position-relative'>
                    <img style={{ height: '30vh', minHeight: '350px', objectFit: 'cover', }} className="d-block w-100 overflow-hidden  position-absolute" src={this.props.image ? this.props.image : image} alt={this.props.title} />
                </div>

                <div className=" text-center w-100 position-relative"
                    style={{
                        top: 0,
                        height: '30vh', minHeight: '350px',
                        backgroundColor: this.props.overlay && 'rgba(14, 13, 13, 0.303)'
                    }}>
                    <Container className=' w-100 h-100  '>
                        <Row className=' my-auto h-100 '>
                            <Col className=' my-auto '>
                                {this.props.title === 'Contact Us' ? <SearchBar /> :

                                    <div>
                                        <h1 className='text-light font-weight-bold'>{this.props.title}</h1>
                                    </div>
                                }

                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}
