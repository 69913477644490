import React, { Component } from "react";
import { connect } from "react-redux";
import { getProducts } from "../actions/productActions";
import InstagramSpinner from "./InstagramSpinner";
import queryString from "query-string";
import { Helmet } from "react-helmet";
import { Row, Col } from "reactstrap";
import ProductTile from "./ProductTile";
import { pageView } from "../utils/analytics";

import {
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";

class Shop extends Component {
  state = {
    products: [],
    sortDropDown: false,
    orderby: "",
    searchQuery: "",
  };

  setSort = (value) => {
    const parsed = queryString.parse(this.props.location.search);

    if (value !== "") {
      parsed.orderby = value;
    } else {
      parsed.orderby = undefined;
    }

    let stringified = queryString.stringify(parsed);

    if (stringified.length > 0) {
      this.setState({
        orderby: value,
        searchQuery: "?" + stringified,
      });
    } else {
      this.setState({
        orderby: "",
        searchQuery: "",
      });
    }
  };

  componentDidMount() {
    pageView();
    const parsed = queryString.parse(this.props.location.search);

    let page = 1;
    let orderby = "default";
    let query;
    let category;

    if (parsed.orderby) {
      orderby = parsed.orderby;
      this.setState({ orderby });
    }

    if (parsed.category) {
      category = parsed.category;
    }
    if (parsed.page) {
      page = parsed.page;
    }
    if (parsed.productSearch) {
      query = parsed.productSearch;
    }

    if (this.props.products.products.length === 0) {
      this.props.getProducts(page, orderby, query, category);
    } else {
      this.setState({
        products: this.props.products.products,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.products.products !== prevProps.products.products) {
      this.setState({
        products: this.props.products.products,
      });
    }

    const parsed = queryString.parse(this.props.location.search);
    if (this.state.searchQuery !== prevState.searchQuery) {
      this.props.history.push(`/shop${this.state.searchQuery}`);
    }
    if (this.props.location.search !== prevProps.location.search) {
      let page;
      let orderby;
      let query;
      let category;

      if (parsed.orderby) {
        orderby = parsed.orderby;
        this.setState({ orderby });
      }
      if (parsed.category) {
        category = parsed.category;
      }
      if (parsed.page) {
        page = parsed.page;
      }
      if (parsed.productSearch) {
        query = parsed.productSearch;
      }

      let stringified = queryString.stringify(parsed);

      if (stringified.length > 0) {
        this.setState({
          searchQuery: "?" + stringified,
        });
      } else {
        this.setState({
          searchQuery: "",
        });
      }

      this.props.getProducts(page, orderby, query, category);
    }
  }

  routeToSpecificPage = (product) => {
    this.props.history.push({
      pathname: `/shop/${product.name.replace(/[^A-Z0-9]+/gi, "-")}/${
        product._id
      }`,
      state: { product },
    });
  };

  dropdownToggle = (e) => {
    this.setState({
      sortDropDown: !this.state.sortDropDown,
    });
  };

  handlePageChange = (i) => {
    const parsed = queryString.parse(this.props.location.search);
    parsed.page = i + 1;

    let stringified = queryString.stringify(parsed);

    this.setState({
      searchQuery: "?" + stringified,
    });
  };

  previousPage = (e) => {
    const parsed = queryString.parse(this.props.location.search);
    parsed.page = parseInt(parsed.page) - 1;

    let stringified = queryString.stringify(parsed);

    this.setState({
      searchQuery: "?" + stringified,
    });
  };
  nextPage = (e) => {
    const parsed = queryString.parse(this.props.location.search);
    if (parsed.page) {
      parsed.page = parseInt(parsed.page) + 1;
    } else {
      parsed.page = 2;
    }

    let stringified = queryString.stringify(parsed);

    this.setState({
      searchQuery: "?" + stringified,
    });
  };

  render() {
    const parsed = queryString.parse(this.props.location.search);

    let end;
    let totalItemNumbers = this.props.products.totalPageNumbers;
    let currentPage;
    if (parsed.page) {
      if (
        parsed.page >
        [...Array(Math.ceil(this.props.products.totalPageNumbers / 24))].length
      ) {
        currentPage = [
          ...Array(Math.ceil(this.props.products.totalPageNumbers / 24)),
        ].length;
      } else if (parsed.page <= 1) {
        currentPage = 1;
      } else {
        currentPage = parsed.page;
      }
    } else {
      currentPage = 1;
    }

    if (24 < totalItemNumbers) {
      end = 24 * currentPage;
      if (end > totalItemNumbers) {
        end = totalItemNumbers;
      }
    } else {
      end = totalItemNumbers;
    }

    return (
      <div>
        <div>
          <Helmet>
            <title>
              Shop | Remote Trails Outdoor Apparel + Gear | Proudly Australian
              owned and operated from Melbourne | We give back for every sale |
              Sustainable Outdoor Apparel + Gear
            </title>
            <meta
              name="description"
              content="Shop | Remote Trails | Proudly Australian owned and operated from Melbourne | We give back for every sale | Sustainable Outdoor Apparel + Gear | Free Shipping over $100 on all domestic orders"
            />
          </Helmet>
        </div>
        <p
          className="text-center my-5"
          style={{ fontSize: 33, fontWeight: "normal", color: "rgb(61,66,70)" }}
        >
          {" "}
          {!parsed.category
            ? "Products"
            : parsed.category === "solid"
            ? "Solid"
            : parsed.category === "overlay"
            ? "Overlay"
            : parsed.category === "christmas"
            ? "Christmas"
            : parsed.category === "crescentic"
            ? "Crescentic"
            : parsed.category === "nailart"
            ? "Nail Art"
            : parsed.category === "sale" && "Sale Products"}
        </p>
        <hr />
        <div className="row-padding">
          <div className="d-inline">
            <p className="text-muted d-inline" style={{ fontSize: 14 }}>
              SORT BY
            </p>
            <Dropdown
              size="sm"
              className="btn-sm  ml-auto d-inline"
              isOpen={this.state.sortDropDown}
              toggle={this.dropdownToggle}
            >
              <DropdownToggle caret className=" bg-light text-dark">
                {this.state.orderby === ""
                  ? "Best selling"
                  : this.state.orderby === "pricelow"
                  ? "Price(low to high)"
                  : this.state.orderby === "pricehigh"
                  ? "Price(high to low)"
                  : this.state.orderby === "created_ascending"
                  ? "Date(old to new)"
                  : this.state.orderby === "created_descending" &&
                    "Date(new to old)"}
              </DropdownToggle>
              <DropdownMenu right>
                {this.state.orderby !== "" && (
                  <DropdownItem
                    value="default"
                    onClick={() => this.setSort("")}
                  >
                    Best selling
                  </DropdownItem>
                )}
                <DropdownItem onClick={() => this.setSort("pricelow")}>
                  Price(low to high)
                </DropdownItem>
                <DropdownItem onClick={() => this.setSort("pricehigh")}>
                  Price(high to low)
                </DropdownItem>
                <DropdownItem onClick={() => this.setSort("created_ascending")}>
                  Date(old to new)
                </DropdownItem>
                <DropdownItem
                  onClick={() => this.setSort("created_descending")}
                >
                  Date(new to old)
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          <div className="float-right d-inline">
            <p className="text-muted d-inline" style={{ fontSize: 14 }}>
              {this.props.products.totalPageNumbers} products
            </p>
          </div>
        </div>
        <hr />

        <div className=" my-5">
          {this.props.products.productLoading ? (
            <div className="text-center">
              <InstagramSpinner />
            </div>
          ) : (
            <div className="container">
              <Row>
                {this.state.products.length !== 0 ? (
                  this.state.products.map(
                    (product) =>
                      product.imageList.length !== 0 && (
                        <ProductTile product={product} />
                      )
                  )
                ) : (
                  <Col className="text-center">
                    <h5>
                      There is no products in this category. Please check back
                      later.{" "}
                    </h5>
                  </Col>
                )}
              </Row>

              {[...Array(Math.ceil(this.props.products.totalPageNumbers / 24))]
                .length > 1 &&
                this.props.products.products.length !== 0 && (
                  <Row className="text-center mt-5">
                    <Col className="text-center ">
                      <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-center">
                          {[
                            ...Array(
                              Math.ceil(
                                this.props.products.totalPageNumbers / 24
                              )
                            ),
                          ].map((page, i) => (
                            <li
                              key={i}
                              className={
                                !queryString.parse(this.props.location.search)
                                  .page && i + 1 === 1
                                  ? "active page-item"
                                  : queryString.parse(
                                      this.props.location.search
                                    ).page <= 1 && i + 1 === 1
                                  ? "active page-item"
                                  : queryString.parse(
                                      this.props.location.search
                                    ).page >= 2 &&
                                    i + 1 ===
                                      parseInt(
                                        queryString.parse(
                                          this.props.location.search
                                        ).page
                                      )
                                  ? "active page-item"
                                  : queryString.parse(
                                      this.props.location.search
                                    ).page >
                                      [
                                        ...Array(
                                          Math.ceil(
                                            this.props.products
                                              .totalPageNumbers / 24
                                          )
                                        ),
                                      ].length &&
                                    i + 1 ===
                                      [
                                        ...Array(
                                          Math.ceil(
                                            this.props.products
                                              .totalPageNumbers / 24
                                          )
                                        ),
                                      ].length
                                  ? "active page-item"
                                  : "page-item"
                              }
                            >
                              <span
                                className="page-link border-0"
                                style={{ cursor: "pointer" }}
                                onClick={() => this.handlePageChange(i)}
                              >
                                {" "}
                                {i + 1}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </nav>
                    </Col>
                  </Row>
                )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  products: state.products,
});
export default connect(mapStateToProps, { getProducts })(Shop);
