import React, { Component } from 'react'
import { Row, Col, Collapse } from 'reactstrap'
import faqs from './Policies/Faqs.json'


export default class Faqs extends Component {
    state = {
        isOpen: ''
    }

    handleClick = (e, id) => {
        this.setState({
            isOpen: this.state.isOpen === id ? '' : id
        })
    }
    render() {
        return (
            <div className='container'>
                <Row className='my-3'>
                    <Col>
                        <div className=' my-5 text-center'>
                            <h1 className='font-weight-bold py-3' style={{ fontSize: 24 }}>Frequently Asked Questions</h1>
                        </div>

                        {Object.keys(faqs[0].faqs).map((category, index) => (
                            <div key={index} className=' px-3 my-4 mt-5'>
                                <h5 className='font-weight-bold' style={{ fontSize: 18, letterSpacing: '2px' }} >
                                    {category}
                                </h5>
                                {Object.entries(faqs[0].faqs).map((faq, index) => (
                                    faq[0] === category &&
                                    faq[1].map((eachFaq) => (
                                        <div key={eachFaq.id} className='py-3'>
                                            <div className='pt-3 border-bottom pb-3 position-relative' style={{ color: '#4b4b4b', cursor: 'pointer' }} onClick={(e) => this.handleClick(e, eachFaq.id)}>
                                                <h6 className='d-inline' style={{ fontSize: 12, letterSpacing: '1.8px', marginRight: 20 }} >{eachFaq.question.toUpperCase()}</h6>
                                                <span className='d-inline position-absolute' style={{ right: 10 }}>
                                                    {this.state.isOpen === eachFaq.id ?
                                                        <i style={{ fontSize: 12 }} className="fas fa-minus "></i>

                                                        :
                                                        <i style={{ fontSize: 12 }} className="fas fa-plus"></i>

                                                    }
                                                </span>
                                            </div>

                                            {eachFaq.answers.map((answer, answerIndex) => (
                                                <Collapse key={answerIndex} isOpen={this.state.isOpen === eachFaq.id}>
                                                    <p style={{ fontSize: 14, color: '#66666 !important' }} className='my-3 py-3 '>
                                                        {answer.paragraph}
                                                    </p>
                                                </Collapse>

                                            ))}
                                        </div>
                                    ))
                                ))}
                            </div>
                        ))}
                    </Col>
                </Row>
            </div>

        )
    }
}
