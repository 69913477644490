import React, { useState } from "react";

const TouchableOpacity = ({ children, onClick, className }) => {
  const [touched, setTouched] = useState(false);

  const setStyle = () => {
    if (touched) {
      return {
        ...styles.root,
        ...styles.touchStyles,
      };
    } else {
      return styles.root;
    }
  };

  return (
    <div
      className={className}
      role="button"
      style={setStyle()}
      onMouseDown={() => setTouched(true)}
      onMouseUp={() => {
        setTouched(false);
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

const styles = {
  root: {
    cursor: "pointer",
    userSelect: "none",
    WebkitUserSelect: "none",
    WebkitTouchCallout: "none",
  },
  touchStyles: {
    opacity: 0.5,
    transition: "opacity 100ms ease",
  },
};

export default TouchableOpacity;
