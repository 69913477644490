import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import BreadCrumbs from './BreadCrumbs';
import privacyPolicy from './Policies/PrivacyPolicy.json'




const crumbs = [{
    name: 'Help Centre',
    route: '/help-centre'
},
{
    name: 'Privacy Policy',
    route: '/privacypolicy'
}]

export default class PrivacyPolicy extends Component {
    render() {
        return (
            <div >
                <div className='container'>
                    <div >
                        <BreadCrumbs title='PRIVACY POLICY' crumbs={crumbs} />
                    </div>
                </div>
                <div className='container my-2'>
                    <Row>
                        <Col>
                            <div  >
                                {privacyPolicy[0].privacyPolicy.map((policy, index) => (
                                    <div key={index} >
                                        {policy.title !== '' && (
                                            <h1 className='my-4' style={{ fontSize: 25 }}>{policy.title}</h1>
                                        )}
                                        <li style={{ lineHeight: 2, fontSize: 15 }}>{policy.paragraph}</li>
                                    </div>
                                ))}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}
