import React, { Component } from "react";
import { Row, Col, Button, Form, Input, FormGroup } from "reactstrap";
import { connect } from "react-redux";
import {
  setShippingFee,
  setShippingMethod,
  setDate,
  updateInputs,
} from "../actions/checkOutActions";
import { withRouter } from "react-router-dom";
import ButtonSpinner from "./ButtonSpinner";
import classnames from "classnames";
import {
  couponInputUpdate,
  applyCoupon,
  resetCouponError,
} from "../actions/discountActions";
import { getCartTotal } from "../utils/getCartTotal";
import shippingOptions from "../utils/shippingOptions";

class Shipping extends Component {
  state = {
    total: 0,
    windowWidth: 0,
    shippingMethods: [],
  };

  handleSwitch = (e) => {
    var field = e.target.name;
    var inputValue = e.target.checked;
    this.props.updateInputs(field, inputValue);
  };

  componentDidMount() {
    this.shippingOptions();

    this.setState({
      windowWidth: window.innerWidth,
    });
    window.addEventListener("resize", this.updateWindowDimension);

    var total = getCartTotal(this.props.shoppingCart.cart).toFixed(2);
    this.setState({
      total,
    });
  }

  updateCoupon = (e) => {
    var field = e.target.name;
    var inputValue = e.target.value;
    this.props.couponInputUpdate(field, inputValue);
  };

  resetCoupon = () => {
    this.props.resetCouponError();
  };

  handleCoupon = (e) => {
    e.preventDefault();
    let couponData = {};
    couponData.coupon = this.props.discount.coupon;
    this.props.applyCoupon(couponData);
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.checkout.buttonLoading !== prevProps.checkout.buttonLoading
    ) {
      if (
        !this.props.checkout.buttonLoading &&
        Object.keys(this.props.checkout.errors).length === 0
      ) {
        this.props.nextStep();
      }
    }

    if (this.props.checkout.country !== prevProps.checkout.country) {
      this.shippingOptions();
    }

    if (
      this.props.discount.errors !== prevProps.discount.errors &&
      Object.keys(this.props.discount.errors).length > 0
    ) {
      setTimeout(this.resetCoupon, 4000);
    }

    if (
      this.props.discount !== prevProps.discount &&
      Object.keys(this.props.discount.userCoupon).length > 0
    ) {
      this.shippingOptions();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimension);
  }
  updateWindowDimension = (e) => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };

  getShippingOptions = () => {
    let total = getCartTotal(this.props.shoppingCart.cart);

    let options =
      this.props.checkout.country === "Australia"
        ? shippingOptions.domestic.filter((item) => item.minimum < total)
        : shippingOptions.international.filter((item) => item.minimum < total);

    for (let i = 0; i < options.length; i++) {
      if (options[i].name === "free-shipping") {
        options = options.filter((item) => item.name !== "standard-shipping");
        break;
      }
    }

    return options;
  };

  nextStep = (e) => {
    e.preventDefault();

    if (this.props.checkout.shippingMethod !== null) {
      let shippingType = this.getShippingOptions();

      let shippingFee = shippingType.filter(
        (item) => item.name === this.props.checkout.shippingMethod
      );

      this.props.setShippingFee(shippingFee[0].fee);
      this.props.nextStep();
    }
  };

  setShippingMethod = (e) => {
    this.props.setShippingMethod(e.target.name);
  };

  shippingOptions = () => {
    this.setState({
      shippingMethods: this.getShippingOptions(),
    });
  };

  render() {
    let subTotal = 0;

    if (this.props.shoppingCart.cart.length !== 0) {
      subTotal = getCartTotal(this.props.shoppingCart.cart);
    }

    let total = subTotal;
    let discount;

    if (this.props.checkout.shippingFee !== null) {
      total = total + this.props.checkout.shippingFee;
    }

    if (Object.keys(this.props.discount.userCoupon).length > 0) {
      if (subTotal >= this.props.discount.userCoupon.minimumSpend) {
        if (this.props.discount.userCoupon.isPercent) {
          discount = (subTotal / 100) * this.props.discount.userCoupon.amount;
        } else {
          discount = this.props.discount.userCoupon.amount;
        }
      }
    }

    if (discount !== undefined) {
      total = total - discount;
    }

    return (
      <div className="ml-3">
        <div className="mx-3 py-3">
          <h5 className="mx-2 py-3 text-muted font-weight-bold">
            Shipping Method
          </h5>
          <div className="border ">
            {this.state.shippingMethods.length > 0 &&
              this.state.shippingMethods.map((method) => (
                <Row className="mx-3 py-3" key={method.name}>
                  <Col xs="9">
                    <Form className="d-inline pr-3">
                      <Input
                        type="radio"
                        checked={
                          this.props.checkout.shippingMethod === method.name
                        }
                        onChange={this.setShippingMethod}
                        name={method.name}
                      />
                    </Form>
                    {method.label}
                  </Col>
                  <Col xs="3">
                    <span className="float-right">
                      {method.fee === 0 ? "Free" : "$" + method.fee}
                    </span>
                  </Col>
                </Row>
              ))}
          </div>

          <div>
            <h5 className="mx-2 mt-1 py-3 text-muted font-weight-bold">
              Discount Code
            </h5>

            <Row>
              <Col>
                <Form className="mb-3 " onSubmit={(e) => e.preventDefault()}>
                  <FormGroup>
                    <Input
                      name="coupon"
                      className={classnames(" d-inline", {
                        "is-invalid": this.props.discount.errors.coupon,
                      })}
                      value={this.props.discount.coupon}
                      onChange={this.updateCoupon}
                      placeholder="Gift card or discount code"
                    />
                    <div className="invalid-feedback">
                      {this.props.discount.errors.coupon}
                    </div>
                  </FormGroup>
                  {discount !== undefined && (
                    <p className="text-success">
                      Discount Code Applied
                      <i className="fas fa-check ml-2"></i>
                    </p>
                  )}
                  <Button
                    size="sm"
                    color="success"
                    className="my-2 mb-3"
                    disabled={this.props.discount.coupon === ""}
                    outline
                    onClick={this.handleCoupon}
                  >
                    {this.props.discount.loading ? (
                      <ButtonSpinner />
                    ) : (
                      <span>Apply</span>
                    )}
                  </Button>
                </Form>
              </Col>
            </Row>
          </div>

          <div>
            <Row className="my-3">
              <Col md="6" xs="12" className="order-2 order-md-1  my-2">
                <Button
                  disabled={this.props.checkout.buttonLoading}
                  size="sm"
                  color="dark"
                  outline
                  className="w-100 font-weight-bold"
                  onClick={() => this.props.goToStep(1)}
                >
                  &lt; User Details
                </Button>
              </Col>

              <Col
                md="6"
                xs="12"
                className="order-1 text-right my-2 order-md-2 "
              >
                <Button
                  className="w-100"
                  size="sm"
                  disabled={this.props.checkout.shippingMethod === null}
                  color="success"
                  onClick={this.nextStep}
                  style={{ minWidth: 182 }}
                >
                  {this.props.checkout.buttonLoading ? (
                    <ButtonSpinner />
                  ) : (
                    <span> Continue to Payment &gt;</span>
                  )}
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  checkout: state.checkout,
  shoppingCart: state.cart,
  guest: state.guest,
  discount: state.discount,
});

export default connect(mapStateToProps, {
  setShippingFee,
  setShippingMethod,
  setDate,
  couponInputUpdate,
  applyCoupon,
  resetCouponError,
  updateInputs,
})(withRouter(Shipping));
