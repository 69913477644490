export const GET_PRODUCTS = "GET_PRODUCTS";
export const PRODUCT_LOADING = "PRODUCT_LOADING";
export const PRODUCT_LOADING_RESET = "PRODUCT_LOADING_RESET";
export const GET_PRODUCT_COUNT = "GET_PRODUCT_COUNT";
export const FORM_VALIDATION_ERROR = "FORM_VALIDATION_ERROR";
export const GET_DB_CART = "GET_DB_CART";
export const RESET_LOCALSTORAGE_USER = "RESET_LOCALSTORAGE_USER";
export const DISABLE_CART_SYNC = "DISABLE_CART_SYNC";
export const GET_GUEST_USER = "GET_GUEST_USER";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const FULL_PAGE_LOADING = "FULL_PAGE_LOADING";
export const RESET_FULL_PAGE_LOADING = "RESET_FULL_PAGE_LOADING";
export const RESET_SHOW_RESET_PASSWORD = "RESET_SHOW_RESET_PASSWORD";
export const PASSWORD_UPDATED = "PASSWORD_UPDATED";
export const RESEND_VERIFICATION = "RESEND_VERIFICATION";
export const SHOW_SPINNER = "SHOW_SPINNER";
export const RESET_SPINNER = "RESET_SPINNER";
export const REGISTRATION_SUCCESSFUL = "REGISTRATION_SUCCESSFUL";
export const RESET_SIGNUP = "RESET_SIGNUP";
export const TOGGLE_CART = "TOGGLE_CART";
export const UPDATE_SHOPPING_CART = "UPDATE_SHOPPING_CART";
export const ADD_TO_CART_LOADING = "ADD_TO_CART_LOADING";
export const RESET_REMOVE_FROM_CART = "RESET_REMOVE_FROM_CART";
export const RESET_ADD_TO_CART_LOADING = "RESET_ADD_TO_CART_LOADING";
export const REMOVE_FROM_CART_LOADING = "REMOVE_FROM_CART_LOADING";
export const RESET_CART_SYNC = "RESET_CART_SYNC";
export const CHECKOUT_CART_LOADING = "CHECKOUT_CART_LOADING";
export const CART_WARNINGS = "CART_WARNINGS";
export const VALIDATE_CART = "VALIDATE_CART";
export const RESET_WARNINGS = "RESET_WARNINGS";
export const TRANSPARENT_FULL_PAGE_LOADING = "TRANSPARENT_FULL_PAGE_LOADING";
export const RESET_FULL_PAGE_LOADING_CHECKOUT =
  "RESET_FULL_PAGE_LOADING_CHECKOUT";
export const REMOVE_GUEST_USER = "REMOVE_GUEST_USER";
export const VERIFY_USER = "VERIFY_USER";
export const RESET_CART = "RESET_CART";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const NOT_FOUND = "NOT_FOUND";
export const FOUND = "FOUND";
export const GET_SINGLE_ORDER = "GET_SINGLE_ORDER";
export const GET_USER_ORDERS = "GET_USER_ORDERS";
export const RESET_ORDER = "RESET_ORDER";
export const USER_ORDERS_LOADING = "USER_ORDERS_LOADING";
export const USER_ORDERS_LOADING_FINISHED = "USER_ORDERS_LOADING_FINISHED";
export const NEW_ORDER = "NEW_ORDER";
export const BUTTON_LOADING = "BUTTON_LOADING";
export const CHECKOUT_FORM_ERROR = "CHECKOUT_FORM_ERROR";
export const CLICK_PAY = "CLICK_PAY";
export const GUEST_DATA_SAVED = "GUEST_DATA_SAVED";
export const INPUT_UPDATE = "INPUT_UPDATE";
export const RADIOBOX_UPDATE = "RADIOBOX_UPDATE";
export const RESET_CHECKOUT_FORM = "RESET_CHECKOUT_FORM";
export const RESET_CONTINUE_BUTTON = "RESET_CONTINUE_BUTTON";
export const SET_CARD_CVC = "SET_CARD_CVC";
export const SET_CARD_EXPIRY = "SET_CARD_EXPIRY";
export const SET_CARD_NUMBER = "SET_CARD_NUMBER";
export const SET_DATE = "SET_DATE";
export const SET_PAYMENT_TYPE = "SET_PAYMENT_TYPE";
export const SET_PICKUP_TIME = "SET_PICKUP_TIME";
export const SET_SHIPPING_FEE = "SET_SHIPPING_FEE";
export const SET_SHIPPING_METHOD = "SET_SHIPPING_METHOD";
export const SHIPPING_DATA_VALIDATED = "SHIPPING_DATA_VALIDATED";
export const USER_CHECKOUT_VERIFIED = "USER_CHECKOUT_VERIFIED";
export const COUPON_ENTERED = "COUPON_ENTERED";
export const COUPON_ERROR = "COUPON_ERROR";
export const COUPON_LOADING = "COUPON_LOADING";
export const RESET_COUPON = "RESET_COUPON";
export const RESET_COUPON_ERRORS = "RESET_COUPON_ERRORS";
export const UPDATE_COUPON = "UPDATE_COUPON";
export const SUBSCRIBE_NEWSLETTER = "SUBSCRIBE_NEWSLETTER";
export const GET_SINGLE_PRODUCT = "GET_SINGLE_PRODUCT";
export const RESET_PRODUCTS = "RESET_PRODUCTS";
export const RESET_SINGLE_PRODUCT_LOADING = "RESET_SINGLE_PRODUCT_LOADING";
export const SINGLE_PRODUCT_LOADING = "SINGLE_PRODUCT_LOADING";
export const GET_YOU_MAY_ALSO_LIKE_PRODUCTS = "GET_YOU_MAY_ALSO_LIKE_PRODUCTS";
export const RESET_YOU_MAY_ALSO_LIKE_PRODUCTS =
  "RESET_YOU_MAY_ALSO_LIKE_PRODUCTS";
export const CART_ERRORS = "CART_ERRORS";
export const CHANGE_STREAM_SET_TODAYS_TOTAL = "CHANGE_STREAM_SET_TODAYS_TOTAL";
export const CHANGE_STREAM_SET_TOTAL_ORDERS_TODAY =
  "CHANGE_STREAM_SET_TOTAL_ORDERS_TODAY";
export const CHANGE_STREAM_SET_TOTAL_ORDERS_YESTERDAY =
  "CHANGE_STREAM_SET_TOTAL_ORDERS_YESTERDAY";
export const CHANGE_STREAM_SET_YESTERDAYS_TOTAL =
  "CHANGE_STREAM_SET_YESTERDAYS_TOTAL";
export const DAILY_TOTAL_REVENUE = "DAILY_TOTAL_REVENUE";
export const RESET_REVENUE_DETAILS_LOADING = "RESET_REVENUE_DETAILS_LOADING";
export const REVENUE_DETAILS_LOADING = "REVENUE_DETAILS_LOADING";
export const TODAYS_ORDER_COUNT = "TODAYS_ORDER_COUNT";
export const YESTERDAYS_ORDER_COUNT = "YESTERDAYS_ORDER_COUNT";
export const YESTERDAY_REVENUE = "YESTERDAY_REVENUE";
export const ADMIN_USER_DETAILS_LOADING = "ADMIN_USER_DETAILS_LOADING";
export const CHANGE_STREAM_TOTAL_USER_COUNT = "CHANGE_STREAM_TOTAL_USER_COUNT";
export const RESET_ADMIN_USER_DETAILS_LOADING =
  "RESET_ADMIN_USER_DETAILS_LOADING";
export const TOTAL_USER_COUNT = "TOTAL_USER_COUNT";
export const TOTAL_WEEKLY_USER_COUNT = "TOTAL_WEEKLY_USER_COUNT";
export const CHANGE_STREAM_SALES_GRAPH = "CHANGE_STREAM_SALES_GRAPH";
export const MONTLY_SALES = "MONTLY_SALES";
export const MONTLY_SALES_LOADING = "MONTLY_SALES_LOADING";
export const MONTLY_SALES_LOADING_RESET = "MONTLY_SALES_LOADING_RESET";
export const GET_LATEST_ORDERS = "GET_LATEST_ORDERS";
export const GET_LATEST_ORDERS_LOADING = "GET_LATEST_ORDERS_LOADING";
export const GET_LATEST_ORDERS_LOADING_RESET =
  "GET_LATEST_ORDERS_LOADING_RESET";
export const TODAY_ORDERS_UPDATE = "TODAY_ORDERS_UPDATE";
export const ADMIN_USER_LIST_LOADING = "ADMIN_USER_LIST_LOADING";
export const ADMIN_USER_LIST_LOADING_RESET = "ADMIN_USER_LIST_LOADING_RESET";
export const DELETE_SINGLE_USER = "DELETE_SINGLE_USER";
export const DELETE_SINGLE_USER_BUTTON_LOADING =
  "DELETE_SINGLE_USER_BUTTON_LOADING";
export const DELETE_SINGLE_USER_BUTTON_LOADING_RESET =
  "DELETE_SINGLE_USER_BUTTON_LOADING_RESET";
export const GET_ADMIN_USER_LIST = "GET_ADMIN_USER_LIST";
export const GET_ADMIN_USER_LIST_COUNT = "GET_ADMIN_USER_LIST_COUNT";
export const GET_SINGLE_USER = "GET_SINGLE_USER";
export const RESET_SINGLE_USER = "RESET_SINGLE_USER";
export const UPDATE_SINGLE_USER = "UPDATE_SINGLE_USER";
export const UPDATE_SINGLE_USER_LOADING = "UPDATE_SINGLE_USER_LOADING";
export const UPDATE_SINGLE_USER_LOADING_RESET =
  "UPDATE_SINGLE_USER_LOADING_RESET";
export const USER_COUNT_UPDATED = "USER_COUNT_UPDATED";
export const USER_TABLE_UPDATED = "USER_TABLE_UPDATED";
export const ADMIN_PRODUCTS_LOADING = "ADMIN_PRODUCTS_LOADING";
export const ADMIN_PRODUCTS_LOADING_RESET = "ADMIN_PRODUCTS_LOADING_RESET";
export const DELETE_BUTTON_LOADING = "DELETE_BUTTON_LOADING";
export const DELETE_SINGLE_ADMIN_PRODUCT = "DELETE_SINGLE_ADMIN_PRODUCT";
export const GET_ADMIN_PRODUCTS = "GET_ADMIN_PRODUCTS";
export const GET_ADMIN_PRODUCTS_COUNT = "GET_ADMIN_PRODUCTS_COUNT";
export const GET_SINGLE_ADMIN_PRODUCT = "GET_SINGLE_ADMIN_PRODUCT";
export const NEW_PRODUCT_CREATED = "NEW_PRODUCT_CREATED";
export const PRODUCT_FORM_ERRORS = "PRODUCT_FORM_ERRORS";
export const RESET_ADMIN_PRODUCT_FORM = "RESET_ADMIN_PRODUCT_FORM";
export const RESET_DELETE_BUTTON_LOADING = "RESET_DELETE_BUTTON_LOADING";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const GET_MAP_DATA = "GET_MAP_DATA";
export const MAP_DATA_LOADING = "MAP_DATA_LOADING";
export const MAP_DATA_LOADING_RESET = "MAP_DATA_LOADING_RESET";
export const ADMIN_ANALYTICS_LOADING = "ADMIN_ANALYTICS_LOADING";
export const ADMIN_ANALYTICS_LOADING_RESET = "ADMIN_ANALYTICS_LOADING_RESET";
export const SESSION_BY_DEVICE_TOTAL_DESKTOP =
  "SESSION_BY_DEVICE_TOTAL_DESKTOP";
export const SESSION_BY_DEVICE_TOTAL_MOBILE = "SESSION_BY_DEVICE_TOTAL_MOBILE";
export const SESSION_BY_DEVICE_TOTAL_USERS = "SESSION_BY_DEVICE_TOTAL_USERS";
export const GET_ALL_ORDERS = "GET_ALL_ORDERS";
export const GET_ALL_ORDERS_COUNT = "GET_ALL_ORDERS_COUNT";
export const GET_ALL_ORDERS_LOADING = "GET_ALL_ORDERS_LOADING";
export const GET_ALL_ORDERS_LOADING_RESET = "GET_ALL_ORDERS_LOADING_RESET";
export const GET_SELECTED_ORDER = "GET_SELECTED_ORDER";
export const ORDER_UPDATE_LOADING = "ORDER_UPDATE_LOADING";
export const ORDER_UPDATE_LOADING_RESET = "ORDER_UPDATE_LOADING_RESET";
export const RESET_SELECTED_ORDER = "RESET_SELECTED_ORDER";
export const ADMIN_ANALYTICS_LIST_LOADING = "ADMIN_ANALYTICS_LIST_LOADING";
export const ADMIN_ANALYTICS_LIST_LOADING_RESET =
  "ADMIN_ANALYTICS_LIST_LOADING_RESET";
export const UPDATE_DELIVERY_DATE = "UPDATE_DELIVERY_DATE";
export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST";
export const GET_REGION_LIST = "GET_REGION_LIST";
export const GET_CITY_LIST = "GET_CITY_LIST";
export const SESSION_BY_DEVICE_TOTAL_TABLET = "SESSION_BY_DEVICE_TOTAL_TABLET";

export const SEND_NEWSLETTER = "SEND_NEWSLETTER";
export const SEND_NEWSLETTER_LOADING = "SEND_NEWSLETTER_LOADING";
export const SEND_NEWSLETTER_LOADING_RESET = "SEND_NEWSLETTER_LOADING_RESET";
export const SEND_NEWSLETTER_ERRORS = "SEND_NEWSLETTER_ERRORS";
export const RESET_SEND_NEWSLETTER = "RESET_SEND_NEWSLETTER";
export const SEND_TESTMAIL_LOADING = "SEND_TESTMAIL_LOADING";
export const SEND_TESTMAIL_LOADING_RESET = "SEND_TESTMAIL_LOADING_RESET";
export const TESTMAIL_SENT = "TESTMAIL_SENT";
export const RESET_TESTMAIL_DATA = "RESET_TESTMAIL_DATA";
export const GO_GREEN_UPDATE = "GO_GREEN_UPDATE";

export const DELETE_ORDER = "DELETE_ORDER";
export const DELETE_ORDER_LOADING = "DELETE_ORDER_LOADING";
export const DELETE_ORDER_LOADING_RESET = "DELETE_ORDER_LOADING_RESET";

export const COUPON_COUNT = "COUPON_COUNT";
export const GET_COUPONS = "GET_COUPONS";
export const GET_COUPONS_LOADING = "GET_COUPONS_LOADING";
export const GET_COUPONS_LOADING_RESET = "GET_COUPONS_LOADING_RESET";

export const RESET_FORM_REDUCER = "RESET_FORM_REDUCER";

export const SET_RATING = "SET_RATING";
export const RESET_REVIEW_FORM = "RESET_REVIEW_FORM";
export const UPDATE_REVIEW = "UPDATE_REVIEW";

export const SET_REVIEW_IMAGES = "SET_REVIEW_IMAGES";
export const REVIEW_SUBMITTED = "REVIEW_SUBMITTED";
export const REVIEW_ERRORS = "REVIEW_ERRORS";
export const REVIEW_SUBMITTED_LOADING = "REVIEW_SUBMITTED_LOADING";

export const GET_ADMIN_REVIEWS = "GET_ADMIN_REVIEWS";
export const GET_ADMIN_REVIEWS_LOADING = "GET_ADMIN_REVIEWS_LOADING";
export const RESET_GET_ADMIN_REVIEWS_LOADING =
  "RESET_GET_ADMIN_REVIEWS_LOADING";
export const GET_ADMIN_REVIEWS_COUNT = "GET_ADMIN_REVIEWS_COUNT";

export const DELETE_REVIEW = "DELETE_REVIEW";
export const UPDATE_ADMIN_REVIEW = "UPDATE_ADMIN_REVIEW";
export const RESET_ADMIN_REVIEWS = "RESET_ADMIN_REVIEWS";

export const GET_ADMIN_COUPONS = "GET_ADMIN_COUPONS";
export const GET_ADMIN_COUPONS_COUNT = "GET_ADMIN_COUPONS_COUNT";
export const GET_ADMIN_COUPONS_LOADING = "GET_ADMIN_COUPONS_LOADING";
export const GET_ADMIN_COUPONS_LOADING_RESET =
  "GET_ADMIN_COUPONS_LOADING_RESET";

export const CREATE_NEW_COUPON = "CREATE_NEW_COUPON";
export const CREATE_NEW_COUPON_LOADING = "CREATE_NEW_COUPON_LOADING";
export const CREATE_NEW_COUPON_LOADING_RESET =
  "CREATE_NEW_COUPON_LOADING_RESET";
export const CREATE_NEW_COUPON_ERRORS = "CREATE_NEW_COUPON_ERRORS";

export const RESET_CREATE_NEW_COUPON_FORM = "RESET_CREATE_NEW_COUPON_FORM";

export const DEACTIVATE_COUPON = "DEACTIVATE_COUPON";
export const DELETE_COUPON = "DELETE_COUPON";
export const RESET_COUPON_STATUS = "RESET_COUPON_STATUS";

export const GET_BEST_SELLERS = "GET_BEST_SELLERS";
export const AVERAGE_ORDER_SIZE = "AVERAGE_ORDER_SIZE";
export const AVERAGE_ORDER_VALUE = "AVERAGE_ORDER_VALUE";
export const GET_AVERAGE_PER_SHIPPING = "GET_AVERAGE_PER_SHIPPING";
export const GET_COUPON_STATS = "GET_COUPON_STATS";

export const RESET_ALL_ORDERS = "RESET_ALL_ORDERS";
export const CREATE_NEW_CATEGORY = "CREATE_NEW_CATEGORY";
export const CREATE_NEW_CATEGORY_LOADING = "CREATE_NEW_CATEGORY_LOADING";
export const CREATE_NEW_CATEGORY_LOADING_RESET =
  "CREATE_NEW_CATEGORY_LOADING_RESET";
export const CREATE_NEW_CATEGORY_ERRORS = "CREATE_NEW_CATEGORY_ERRORS";

export const GET_ADMIN_CATEGORIES = "GET_ADMIN_CATEGORIES";
export const GET_ADMIN_CATEGORIES_LOADING = "GET_ADMIN_CATEGORIES_LOADING";
export const GET_ADMIN_CATEGORIES_LOADING_RESET =
  "GET_ADMIN_CATEGORIES_LOADING_RESET";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const RESET_CATEGORY_REDUCER = "RESET_CATEGORY_REDUCER";
export const CATEGORY_ACTIVATION_STATUS_UPDATE =
  "CATEGORY_ACTIVATION_STATUS_UPDATE";

export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_PENDING_ORDER_COUNT = "GET_PENDING_ORDER_COUNT";
export const UPDATE_COLLAPSE_MENU = "UPDATE_COLLAPSE_MENU";
