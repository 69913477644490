import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import image2 from '../images/2.PNG'
import image3 from '../images/3.jpg'
import image4 from '../images/4.jpg'
import image5 from '../images/5.jpg'
import image6 from '../images/6.jpg'

export default class HowToApply extends Component {
    render() {
        return (
            <div className='container'>
                <Row>
                    <Col className='text-center'>
                        <h2 className='mt-5'>How To Apply</h2>
                        <p>As simple as <span className='font-weight-bold'>PEEL, PLACE, FILE</span>, and <span className='font-weight-bold'>TOP COAT </span>for a finishing touch.</p>
                    </Col>
                </Row>
                <Row className='my-4'>
                    <Col className='text-center' md='3' sm='6' xs='12'>
                        <img style={{ maxHeight: '400px' }} className='img-fluid' src={image2} alt='1. Remove the clear protected film. PEEL OFF selected nail wrap from the silver end.' />

                    </Col>
                    <Col className='text-center' md='3' sm='6' xs='12'>
                        <img style={{ maxHeight: '400px' }} className='img-fluid' src={image3} alt='2. PLACE the nail wrap and press down firmly to smooth the edges. Make sure to not cover the skin or cuticles.' />

                    </Col>
                    <Col className='text-center' md='3' sm='6' xs='12'>
                        <img style={{ maxHeight: '400px' }} className='img-fluid' src={image4} alt='3. FILE DOWNWARDS to remove the excess wrap. Repeat on all 10 nails.' />

                    </Col>
                    <Col className='text-center' md='3' sm='6' xs='12'>
                        <img style={{ maxHeight: '400px' }} className='img-fluid' src={image5} alt='4. Apply TOP COAT for longer lasting finish.' />

                    </Col>
                </Row>

                <Row>
                    <Col className='text-center'>
                        <img style={{ maxHeight: '400px' }} className='img-fluid' src={image6} alt='INSTANT manicure in 10 minutes. Lasts up to 10-14 days.' />
                    </Col>
                </Row>
                <Row className='mb-5'>
                    <Col>
                        <div style={{ marginTop: '20px' }} className='embed-responsive embed-responsive-16by9'>
                            <iframe
                                title='How to apply oje australia nail wraps'
                                className='embed-responsive-item' src="https://www.youtube.com/embed/VQs13bIOOz4" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen="allowfullscreen"
                                mozallowfullscreen="mozallowfullscreen"
                                msallowfullscreen="msallowfullscreen"
                                oallowfullscreen="oallowfullscreen"
                                webkitallowfullscreen="webkitallowfullscreen"
                            ></iframe>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
