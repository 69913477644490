import React, { Component } from 'react'

export default class Header extends Component {
    render() {
        return (
            <div className='my-3 w-100 h-100 text-center' >
                <h2 className='font-weight-bold ' style={{ color: 'rgb(161,99,72)', fontSize: 24 }}>{this.props.header} </h2>
                {this.props.subHeader !== '' && (
                    <p className='mx-3'>{this.props.subHeader}</p>
                )}
            </div>
        )
    }
}
