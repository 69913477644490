import {
    CREATE_NEW_CATEGORY,
    CREATE_NEW_CATEGORY_LOADING,
    CREATE_NEW_CATEGORY_LOADING_RESET,
    CREATE_NEW_CATEGORY_ERRORS,
    GET_ADMIN_CATEGORIES,
    GET_ADMIN_CATEGORIES_LOADING,
    GET_ADMIN_CATEGORIES_LOADING_RESET,
    DELETE_CATEGORY,
    RESET_CATEGORY_REDUCER,
    CATEGORY_ACTIVATION_STATUS_UPDATE

} from './types'
import axios from 'axios'
import { logoutUser } from './formActions'


export const createNewCategory = data => dispatch => {
    dispatch({
        type: CREATE_NEW_CATEGORY_LOADING
    })
    axios.post(`/api/admin/createNewCategory`, data)
        .then(res => {
            dispatch({
                type: CREATE_NEW_CATEGORY,
            })
            dispatch({
                type: CREATE_NEW_CATEGORY_LOADING_RESET
            })
        })
        .catch(err => {
            dispatch({
                type: CREATE_NEW_CATEGORY_LOADING_RESET
            })
            dispatch({
                type: CREATE_NEW_CATEGORY_ERRORS,
                payload: err.response.data
            })
        })
}

export const getCategories = () => dispatch => {
    dispatch({
        type: GET_ADMIN_CATEGORIES_LOADING
    })
    axios.get(`/api/admin/getCategories`)
        .then(res => {
            dispatch({
                type: GET_ADMIN_CATEGORIES,
                payload: res.data
            })

            dispatch({
                type: GET_ADMIN_CATEGORIES_LOADING_RESET
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ADMIN_CATEGORIES_LOADING_RESET
            })
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }
        })

}

export const activationStatus = (id, data) => dispatch => {
    axios.post(`/api/admin/setCategoryStatus/${id}`, data)
        .then(res => {
            dispatch({
                type: CATEGORY_ACTIVATION_STATUS_UPDATE,
                payload: res.data
            })
        })
        .catch(err => {
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }
        })
}


export const deleteCategory = (id) => dispatch => {
    axios.post(`/api/admin/deleteCategory/${id}`)
        .then(res => {
            dispatch({
                type: DELETE_CATEGORY,
                payload: res.data
            })
        })
        .catch(err => {
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }
        })
}


export const resetCategoryReducer = () => dispatch => {
    dispatch({
        type: RESET_CATEGORY_REDUCER
    })
}