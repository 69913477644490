import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getAdminCoupons, activationStatus, deleteCoupon } from '../../actions/couponActions'
import { Helmet } from "react-helmet";
import moment from 'moment'
import { Row, Col, Button, Modal, ModalBody, ModalFooter } from 'reactstrap'
import 'react-datepicker/dist/react-datepicker.css'


class Coupon extends Component {

    state = {
        page: 1,
        askToDeleteModal: false,
        deleteId: '',
    }

    componentDidUpdate(prevProps) {
        if (this.props.coupons.coupons !== prevProps.coupons.coupons && this.state.deleteId !== '') {
            if (this.props.coupons.coupons.filter(coupon => coupon._id === this.state.deleteId).length === 0) {
                this.setState({
                    deleteId: '',
                    askToDeleteModal: false
                })
            }
        }
    }

    toggleAsktoDelete = e => {
        this.setState({
            askToDeleteModal: !this.state.askToDeleteModal,
        })
    }

    deleteClick = e => {
        this.props.deleteCoupon(this.state.deleteId)

    }

    componentDidMount() {
        this.props.getAdminCoupons(this.state.page)
    }
    handleNewCouponClick = e => {
        this.props.history.push('/adminpanel/coupons/newCoupon')
    }

    handleActivationStatus = (item) => {
        let data = {}
        if (item.isActive) {
            data.isActive = false
        } else {
            data.isActive = true
        }
        this.props.activationStatus(item._id, data)

    }

    deleteSelectedCoupon = item => {
        this.setState({
            deleteId: item._id,
            askToDeleteModal: true
        })
    }

    loadMore = (e) => {
        this.setState({
            page: this.state.page + 1
        }, () => {
            this.props.getAdminCoupons(this.state.page)
        })

    }
    render() {
        return (
            <div>
                <Row>
                    <Col>
                        <div>
                            <Button size='sm' color='success' onClick={this.handleNewCouponClick}>New Coupon</Button>

                        </div>
                    </Col>
                </Row>

                <div>
                    <Modal className=' p-4' style={{ borderRadius: 10 }} isOpen={this.state.askToDeleteModal} toggle={this.toggleAsktoDelete} >
                        <ModalBody>
                            Are you sure? This action can't be reversed.

                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={this.deleteClick}>Delete</Button>{' '}
                            <Button color="secondary" onClick={this.toggleAsktoDelete}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </div>
                <div className='my-2'>
                    {this.props.coupons.loading ? (
                        <div className='text-center text-light my-5'>
                            <h5>Please wait. Coupons are being fetched.</h5>
                        </div>
                    ) : (
                            this.props.coupons.coupons.length === 0 ? (
                                <div className='text-center text-light my-5'>
                                    <h5>No results found.</h5>
                                </div>
                            ) : (
                                    <div className='bg-light' style={{ borderRadius: 10 }}>
                                        <Row>
                                            <Col className='px-3'>
                                                <div className='table-responsive'>
                                                    <table className="table">
                                                        <thead className='table-borderless'>
                                                            <tr>
                                                                <th scope="col">DISCOUNT CODE</th>
                                                                <th scope="col">EXPIRY DATE</th>
                                                                <th scope="col">AMOUNT</th>
                                                                <th scope="col">ACTIVE</th>
                                                                <th scope="col">CATEGORIES</th>
                                                                <th scope="col"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody >
                                                            {this.props.coupons.coupons.map(item => (
                                                                <tr
                                                                    // onClick={() => this.handleProductClick(item)} 
                                                                    key={item._id} className={item.isActive ? 'admin-each-product' : 'admin-each-product bg-warning'} style={{ cursor: 'pointer' }}>
                                                                    <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>  {item.code}</td>
                                                                    <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>  {moment(item.expireDate).format("Do MMM  YY")}</td>
                                                                    <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>{item.amount}</td>
                                                                    <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>{item.isActive ? 'Active' : 'Inactive'}</td>
                                                                    <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>{item.categories? (item.categories.length ===0 ? 'All': (item.categories.map(category=>(
                                                                        category+', '
                                                                    )))): 'All'}</td>
                                                                    <td style={{ verticalAlign: 'middle' }} className='font-weight-bold text-center'><Button onClick={() => this.handleActivationStatus(item)}
                                                                        color={item.isActive ? 'warning' : 'success'}>{item.isActive ? 'Deactivate' : 'Activate'}</Button>
                                                                        <Button className='ml-2' onClick={() => this.deleteSelectedCoupon(item)} color='danger'>Delete</Button></td>
                                                                </tr>
                                                            ))}

                                                        </tbody>
                                                    </table>
                                                </div>

                                            </Col>
                                        </Row>
                                    </div>
                                )
                        )}

                    <Row>
                        <Col className='text-center my-3'>
                            {this.props.coupons.couponCount > this.props.coupons.coupons.length && (
                                <Button onClick={this.loadMore} outline color='dark' size='sm'>Load More</Button>
                            )}
                        </Col>
                    </Row>
                </div>

            </div>
        )
    }
}



const mapStateToProps = state => ({
    coupons: state.coupons
})

export default connect(mapStateToProps, { getAdminCoupons, activationStatus, deleteCoupon })(Coupon)