import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
    Row, Col
} from 'reactstrap'
import { getAverageOrderSize } from '../../actions/LatestOrders'
import { withRouter } from 'react-router-dom'


class AverageOrderSize extends Component {

    getRandomColor = () => {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }


    componentDidMount() {
        this.props.getAverageOrderSize()
    }
    render() {
        return (
            <div>
                {this.props.latestOrders.averageOrderSize !== 0 && (
                    <div className=' text-left bg-light px-3 py-3 my-2' style={{ borderRadius: 10 }}>

                        <Row>
                            <Col xs='8' >
                                <h6 className='font-weight-bold text-secondary'>AVERAGE PRODUCT SOLD PER ORDER</h6>
                                <div>
                                    <h5 className='font-weight-bold text-dark'>{this.props.latestOrders.averageOrderSize.toFixed(2)}</h5>
                                </div>

                            </Col>
                            <Col xs='4' className='my-3  text-center'>
                                <div className='rounded-circle text-white shadow px-3 py-3 ' style={{ width: '3em', height: '3em', alignItems: 'center', justifyContent: 'center', display: 'inline-flex', textAlign: 'center', backgroundColor: this.getRandomColor(), }}>
                                    <i className="fas fa-tag   " style={{ fontSize: '1.25em' }}></i>
                                </div>
                            </Col>
                        </Row>
                    </div>

                )}
            </div>

        )
    }
}

const mapStateToProps = state => ({
    latestOrders: state.latestOrders
})


export default connect(mapStateToProps, { getAverageOrderSize })(withRouter(AverageOrderSize))
