import React, { Component } from "react";
import {
  Row,
  Col,
  Input,
  InputGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import { newProduct, resetForm } from "../../actions/adminProducts";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import classnames from "classnames";
import { getCategories } from "../../actions/categoryActions.js";
import { arrayMoveImmutable } from "array-move";

const mapStateToProps = (state) => ({
  adminProducts: state.adminProducts,
  categories: state.categories,
});

class NewProduct extends Component {
  state = {
    name: "",
    description: "",
    selectedImages: [],
    errors: {},
    showSuccessModal: false,
    category: "",
    price: "",
    variants: [
      {
        variantName: "",
        variantStockLevel: "",
      },
    ],
  };

  handleVariant = (e, index) => {
    let variants = [...this.state.variants];

    variants[index][e.target.name] = e.target.value;

    let errors = this.state.errors;
    if (errors.variants) {
      delete errors.variants;
    }

    this.setState({
      variants,
      errors,
    });
  };

  handleNewVariant = (e) => {
    let variant = {
      variantName: "",
      variantStockLevel: "",
    };

    this.setState({
      variants: [...this.state.variants, variant],
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.adminProducts.errors !== prevProps.adminProducts.errors) {
      this.setState({
        errors: this.props.adminProducts.errors,
      });
    }
    if (
      this.props.adminProducts.newProduct !== prevProps.adminProducts.newProduct
    ) {
      this.setState({
        showSuccessModal: true,
        name: "",
        description: "",
        selectedImages: [],
        price: "",
        errors: {},
        category: "",
      });
    }
  }

  componentDidMount() {
    this.props.getCategories();
  }
  onChange = (e) => {
    let errors = this.state.errors;
    if (errors[e.target.name]) {
      delete errors[e.target.name];
    }
    let value = e.target.value;

    this.setState({ [e.target.name]: value });
  };

  onImageSelect = (e) => {
    this.setState({
      selectedImages: e.target.files,
    });
  };

  handleRemoveVariant = (index) => {
    var array = [...this.state.variants]; // make a separate copy of the array
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ variants: array });
    }
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    const { name, description, price, category, selectedImages } = this.state;

    let formData = new FormData();

    if (selectedImages.length !== 0) {
      for (let i = 0; i < selectedImages.length; i++) {
        formData.append(`images`, selectedImages[i]);
      }
    }

    formData.append("name", name);
    formData.append("description", description);
    formData.append("price", price);
    formData.append("category", category);
    formData.append("variants", JSON.stringify(this.state.variants));
    this.props.newProduct(formData);
  };

  toggle = () => {
    this.setState({
      showSuccessModal: !this.state.showSuccessModal,
    });
  };

  handleNewProductRoute = () => {
    this.props.history.push(
      `/shop/${this.props.adminProducts.newProduct.name.replace(
        /[^A-Z0-9]+/gi,
        "-"
      )}/${this.props.adminProducts.newProduct._id}`
    );
  };

  componentWillUnmount() {
    this.props.resetForm();
  }

  render() {
    const { errors } = this.state;
    const { adminProducts } = this.props;
    return (
      <div>
        <div>
          <Helmet>
            <title>New Product - Remote Trails</title>
            <meta name="description" content="New Product - Remote Trails" />
          </Helmet>
        </div>
        <Row className="pb-5">
          <Col>
            {Object.keys(adminProducts.newProduct).length > 0 && (
              <Modal isOpen={this.state.showSuccessModal} toggle={this.toggle}>
                <ModalHeader>
                  New product has been successfully created.
                </ModalHeader>
                <ModalBody>
                  <img
                    className="img-fluid"
                    style={{ maxHeight: 300 }}
                    src={adminProducts.newProduct.imageList[0].imageUrl}
                    alt={adminProducts.newProduct.name}
                  />
                  <p>{adminProducts.newProduct.name}</p>
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={this.handleNewProductRoute}>
                    Go to new product
                  </Button>
                  <Button color="danger" onClick={this.toggle}>
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            )}

            <form
              encType="multipart/form-data"
              onSubmit={this.handleFormSubmit}
            >
              <Label className=" h6 my-3">
                *All fields are required. The form is case sensitive.
              </Label>

              <InputGroup
                className="my-2 bg-light p-3"
                style={{ borderRadius: 10 }}
              >
                <FormGroup className="w-100 ">
                  <Label>Title of product </Label>
                  <Input
                    placeholder="Title of product"
                    onChange={this.onChange}
                    className={classnames("", { "is-invalid": errors.name })}
                    value={this.state.name}
                    name="name"
                  />
                  <div className="invalid-feedback">{errors.name}</div>
                </FormGroup>
              </InputGroup>
              <InputGroup
                className="my-2 bg-light p-3"
                style={{ borderRadius: 10 }}
              >
                <FormGroup className="w-100 ">
                  <Label>Description of product </Label>
                  <Input
                    type="textarea"
                    placeholder="Description of product"
                    onChange={this.onChange}
                    className={classnames("", {
                      "is-invalid": errors.description,
                    })}
                    value={this.state.description}
                    name="description"
                  />
                  <div className="invalid-feedback">{errors.description}</div>
                </FormGroup>
              </InputGroup>

              <InputGroup
                className="my-2 bg-light p-3"
                style={{ borderRadius: 10 }}
              >
                <FormGroup className="w-100">
                  <Label>Category</Label>

                  <Input
                    type="select"
                    onChange={this.onChange}
                    className={classnames("", {
                      "is-invalid": errors.category,
                    })}
                    value={this.state.category}
                    name="category"
                  >
                    <option hidden>Category</option>
                    <option value="tops">Tops</option>
                    <option value="bottoms">Bottoms</option>
                    <option value="headwear">Headwear</option>
                    <option value="sale">Sale</option>
                  </Input>
                  <div className="invalid-feedback">{errors.category}</div>
                </FormGroup>
              </InputGroup>

              <div className="my-2 bg-light p-3" style={{ borderRadius: 10 }}>
                <Label>Product Variants </Label>
                {errors.variants && (
                  <div style={{ fontSize: "80%" }} className="text-danger">
                    {errors.variants}
                  </div>
                )}

                {this.state.variants.map((variant, index) => (
                  <div key={index}>
                    <Label className="text-secondary font-weight-bold w-100">
                      {index + 1}. VARIANT
                      {index > 0 && (
                        <span
                          onClick={() => this.handleRemoveVariant(index)}
                          style={{ cursor: "pointer" }}
                          className="float-right"
                        >
                          &times;
                        </span>
                      )}
                    </Label>

                    <InputGroup>
                      <FormGroup className="w-100 ">
                        <Label>Size </Label>
                        <Input
                          placeholder="Size"
                          onChange={(e) => this.handleVariant(e, index)}
                          value={variant.variantName}
                          name="variantName"
                        />
                      </FormGroup>
                    </InputGroup>

                    <InputGroup>
                      <FormGroup className="w-100 ">
                        <Label>Stock Level </Label>
                        <Input
                          placeholder="Stock Level"
                          onChange={(e) => this.handleVariant(e, index)}
                          value={variant.variantStockLevel}
                          name="variantStockLevel"
                          className={classnames("", {
                            "is-invalid": errors.variantStockLevel,
                          })}
                        />
                        <div className="invalid-feedback">
                          {errors.variantStockLevel}
                        </div>
                      </FormGroup>
                    </InputGroup>
                  </div>
                ))}

                <Button
                  color="dark"
                  outline
                  className="border"
                  onClick={this.handleNewVariant}
                >
                  {" "}
                  Create a New Variant
                </Button>
              </div>

              <InputGroup
                className="my-2 bg-light p-3"
                style={{ borderRadius: 10 }}
              >
                <FormGroup className="w-100 ">
                  <Label>Price of product</Label>
                  <Input
                    min="0"
                    step="0.01"
                    type="number"
                    placeholder="Price of product"
                    onChange={this.onChange}
                    className={classnames("", { "is-invalid": errors.price })}
                    value={this.state.price}
                    name="price"
                  />
                  <div className="invalid-feedback">{errors.price}</div>
                </FormGroup>
              </InputGroup>

              <InputGroup
                className="my-2 bg-light p-3"
                style={{ borderRadius: 10 }}
              >
                <Label className=" h5" for="image">
                  Select images for new product
                </Label>
                <Input
                  multiple
                  ref="productImage"
                  type="file"
                  name="image"
                  id="image"
                  onChange={this.onImageSelect}
                  className={classnames("", { "is-invalid": errors.image })}
                />
                <div className="invalid-feedback">{errors.image}</div>
              </InputGroup>

              <Row>
                <Col xs="6">
                  <Input
                    type="submit"
                    value={
                      this.props.adminProducts.loading
                        ? "Please Wait.."
                        : "Create new product"
                    }
                    className="bg-success text-light  btn-block mt-4 p-2 border-0 d-inline"
                  />
                </Col>
                <Col xs="6">
                  <Button
                    onClick={() =>
                      this.props.history.push("/adminpanel/products")
                    }
                    color="light"
                    className=" btn-block mt-4 p-2 border-0 d-inline"
                  >
                    Go back to products{" "}
                  </Button>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect(mapStateToProps, {
  newProduct,
  resetForm,
  getCategories,
})(NewProduct);
