import React, { Component } from "react";
import { Input, InputGroup, Label, FormGroup } from "reactstrap";

//syntax

// radioGroups: {
//     title: "Visiblity",
//     buttons: [
//       {
//         id: "visible",
//         label: "Visible",
//         value: "visible"
//       },
//       {
//         id: "hidden",
//         label: "Hidden",
//         value: "hidden"
//       }
//     ]
//   }

export default class RadioInput extends Component {
  render() {
    const { radioGroups, isVisible, handleRadioButton, title } = this.props;
    return (
      <div>
        <InputGroup className="my-2 bg-light p-3" style={{ borderRadius: 10 }}>
          {title && <Label> {title} </Label>}
          {radioGroups.map((radioGroup, index) => (
            <FormGroup
              className="w-100"
              style={{ marginLeft: "1.25rem" }}
              key={index}
            >
              <Label>
                <Input
                  checked={isVisible === radioGroup.id}
                  type="radio"
                  id={radioGroup.id}
                  onChange={handleRadioButton}
                />
                {radioGroup.label}
              </Label>
            </FormGroup>
          ))}
        </InputGroup>
      </div>
    );
  }
}
