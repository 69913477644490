import React from "react";
import { Collapse, Navbar as Nbar, Nav, NavItem, NavLink } from "reactstrap";
import Logo from "./Logo";
import NavbarIcons from "./NavbarIcons";
import useWindowDimensions from "./useWindowDimensions";

export const navbarLinks = [
  {
    title: "SHOP",
    path: "/shop",
  },
  {
    title: "EXPLORE",
    path: "/explore",
  },
  {
    title: "CONTACT",
    path: "/help-centre",
  },
];

const NavBar = () => {
  const { height, width } = useWindowDimensions();

  return (
    <div
      className={`w-100 ${width > 991 ? "px-5" : ""}`}
      style={{
        backgroundColor: "white",
        height: "80px",
      }}
    >
      <Nbar expand="md" id="nav-header border">
        <Logo />
        <Collapse
          id="nav-collapse"
          isOpen={false}
          navbar
          className="text-center"
        >
          <Nav className="mx-auto " navbar>
            {navbarLinks.map((link) => (
              <NavItem key={link.title}>
                <NavLink
                  style={{ fontSize: 14, fontWeight: 600 }}
                  href={link.path}
                  className="text-dark  px-4"
                >
                  {link.title}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Collapse>
        <NavbarIcons />
      </Nbar>
    </div>
  );
};

export default NavBar;
