import {
  GUEST_DATA_SAVED,
  BUTTON_LOADING,
  CHECKOUT_FORM_ERROR,
  GET_GUEST_USER,
  DISABLE_CART_SYNC,
  RESET_CART_SYNC,
  INPUT_UPDATE,
  RADIOBOX_UPDATE,
  SET_SHIPPING_FEE,
  SET_SHIPPING_METHOD,
  SET_DATE,
  SET_PAYMENT_TYPE,
  SET_CARD_NUMBER,
  SET_CARD_EXPIRY,
  SET_CARD_CVC,
  CLICK_PAY,
  RESET_CART,
  RESET_CHECKOUT_FORM,
  RESET_FULL_PAGE_LOADING_CHECKOUT,
  SET_CURRENT_USER,
  USER_CHECKOUT_VERIFIED,
  // SET_PICKUP_TIME,
  // SHIPPING_DATA_VALIDATED,
  NEW_ORDER,
  TRANSPARENT_FULL_PAGE_LOADING,
  CART_WARNINGS,
  VALIDATE_CART,
  RESET_CONTINUE_BUTTON,
} from "./types";
import axios from "axios";
import jwt_decode from "jwt-decode";
import setAuthToken from "../setAuthToken";
import { purchase } from "../utils/analytics";

export const fullPageLoading = () => (dispatch) => {
  dispatch({
    type: TRANSPARENT_FULL_PAGE_LOADING,
  });
};

export const sendPaypalPayment = (data) => (dispatch) => {
  dispatch({
    type: BUTTON_LOADING,
  });

  axios
    .post("/api/orders/receivePaypalPayment", data)
    .then((res) => {
      dispatch({
        type: NEW_ORDER,
        payload: res.data,
      });
      dispatch({
        type: RESET_CART,
      });
      dispatch({
        type: RESET_CHECKOUT_FORM,
      });
    })
    .catch((err) => console.log(err));
};

export const expressCheckout = (data) => (dispatch) => {
  dispatch({
    type: TRANSPARENT_FULL_PAGE_LOADING,
  });

  axios
    .post("/api/orders/expressCheckout", data)
    .then((res) => {
      dispatch({
        type: NEW_ORDER,
        payload: res.data,
      });
      dispatch({
        type: RESET_CART,
      });
      dispatch({
        type: RESET_CHECKOUT_FORM,
      });
      dispatch({
        type: RESET_FULL_PAGE_LOADING_CHECKOUT,
      });
    })
    .catch((err) => {
      dispatch({
        type: RESET_FULL_PAGE_LOADING_CHECKOUT,
      });
      dispatch({
        type: CHECKOUT_FORM_ERROR,
        payload: err.response.data,
      });
    });
};

export const sendPayment = (order) => (dispatch) => {
  axios
    .post("/api/orders/receiveStripePayment", order)
    .then((res) => {
      if (res.data.dbCart) {
        dispatch({
          type: CART_WARNINGS,
          payload: res.data.warnings,
        });
        dispatch({
          type: VALIDATE_CART,
          payload: res.data.dbCart,
        });
        dispatch({
          type: RESET_FULL_PAGE_LOADING_CHECKOUT,
        });
        dispatch({
          type: RESET_CONTINUE_BUTTON,
        });
      } else {
        dispatch({
          type: NEW_ORDER,
          payload: res.data,
        });

        let products = res.data.products.map((product) => ({
          id: product.productId,
          quantity: product.productQuantity,
          price: product.productPrice,
        }));

        purchase(products);

        dispatch({
          type: RESET_CART,
        });
        dispatch({
          type: RESET_CHECKOUT_FORM,
        });
        dispatch({
          type: RESET_FULL_PAGE_LOADING_CHECKOUT,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: RESET_FULL_PAGE_LOADING_CHECKOUT,
      });
      dispatch({
        type: CHECKOUT_FORM_ERROR,
        payload: err.response.data,
      });
    });
};

export const clickPay = () => (dispatch) => {
  dispatch({
    type: CLICK_PAY,
  });
  dispatch({
    type: TRANSPARENT_FULL_PAGE_LOADING,
  });
};

export const setCardCvc = (cvc) => (dispatch) => {
  dispatch({
    type: SET_CARD_CVC,
    payload: cvc,
  });
};

export const setExpiryDate = (expiry) => (dispatch) => {
  dispatch({
    type: SET_CARD_EXPIRY,
    payload: expiry,
  });
};

export const setCardNumber = (card) => (dispatch) => {
  dispatch({
    type: SET_CARD_NUMBER,
    payload: card,
  });
};

export const setPaymentType = (payment_type) => (dispatch) => {
  dispatch({
    type: SET_PAYMENT_TYPE,
    payload: payment_type,
  });
};

export const setDate = (date) => (dispatch) => {
  dispatch({
    type: SET_DATE,
    payload: date,
  });
};

export const setShippingMethod = (shipping) => (dispatch) => {
  dispatch({
    type: SET_SHIPPING_METHOD,
    payload: shipping,
  });
};

export const setShippingFee = (fee) => (dispatch) => {
  dispatch({
    type: SET_SHIPPING_FEE,
    payload: fee,
  });
};

export const updateRadioBox = (value) => (dispatch) => {
  dispatch({
    type: RADIOBOX_UPDATE,
    payload: value,
  });
};

export const updateInputs = (field, value) => (dispatch) => {
  dispatch({
    type: INPUT_UPDATE,
    payload: {
      field,
      value,
    },
  });
};

export const guestUser = (data) => (dispatch) => {
  dispatch({
    type: BUTTON_LOADING,
  });

  axios
    .post("/api/guestUsers/guestUserData", data)
    .then((res) => {
      if (res.data.sync === false) {
        dispatch({
          type: DISABLE_CART_SYNC,
        });
      } else {
        dispatch({
          type: RESET_CART_SYNC,
        });
      }

      dispatch({
        type: GET_GUEST_USER,
        payload: res.data.user,
      });
      dispatch({
        type: GUEST_DATA_SAVED,
      });
    })
    .catch((err) =>
      dispatch({
        type: CHECKOUT_FORM_ERROR,
        payload: err.response.data,
      })
    );
};

export const verifyUser = (data) => (dispatch) => {
  dispatch({
    type: BUTTON_LOADING,
  });

  axios
    .post("/api/users/verifyUser", data)
    .then((res) => {
      if (res.data.newAddress) {
        const { token } = res.data;
        localStorage.setItem("jwtToken", token);

        setAuthToken(token);

        const decoded = jwt_decode(token);

        dispatch({
          type: SET_CURRENT_USER,
          payload: decoded.userDetails,
        });
        dispatch({
          type: USER_CHECKOUT_VERIFIED,
        });
      } else {
        dispatch({
          type: USER_CHECKOUT_VERIFIED,
        });
      }
    })
    .catch((err) =>
      dispatch({
        type: CHECKOUT_FORM_ERROR,
        payload: err.response.data,
      })
    );
};
