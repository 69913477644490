import React from "react";
import { Drawer } from "@material-ui/core";
import { navbarLinks } from "./NavBar";
import { Nav, NavItem, NavLink, Row, Col } from "reactstrap";
import TouchableOpacity from "./TouchableOpacity";
import CloseIcon from "./CloseIcon";
import SocialIcons from "./SocialIcons";
import { connect } from "react-redux";
import { setCollapseMenu } from "../actions/collapseMenu";
import useWindowDimensions from "./useWindowDimensions";

const CollapseMenu = (props) => {
  const { collapseMenu } = props.collapseMenu;
  const { isAuthenticated } = props.auth;
  const { height, width } = useWindowDimensions();

  const footerLinks = [
    {
      title: "Help Centre",
      path: "/help-centre",
    },
    {
      title: "Order Status",
      path: "/order-status",
    },
    {
      title: "Returns & Exchanges",
      path: "/returns-policy",
    },
    {
      title: "Frequently Asked Questions",
      path: "/faqs",
    },
    {
      title: "Become a Stockist",
      path: "/becomestockist",
    },
    {
      title: "Account",
      path: isAuthenticated ? "/dashboard" : "/login",
    },
  ];

  return (
    <Drawer
      anchor="left"
      open={collapseMenu}
      onClose={() => props.setCollapseMenu(false)}
    >
      <div style={{ width: "350px", lineHeight: 1 }} role="presentation">
        <div
          className="side-drawer position-absolute px-3"
          style={{
            overflowY: "scroll",
            overflowX: "hidden",
            width: "100%",
            height: height - 55,
          }}
        >
          <CloseIcon />
          <Nav className="mx-auto " navbar>
            {navbarLinks.map((link) => (
              <TouchableOpacity key={link.title}>
                <NavItem className=" border-bottom py-3">
                  <NavLink
                    style={{ fontSize: 14, fontWeight: 600 }}
                    href={link.path}
                    className="text-dark "
                  >
                    {link.title}
                  </NavLink>
                </NavItem>
              </TouchableOpacity>
            ))}
            {footerLinks.map((link) => (
              <TouchableOpacity key={link.title}>
                <NavItem className="  py-2">
                  <NavLink
                    style={{
                      fontSize: 14,
                      fontWeight: 400,
                      color: "grey",
                    }}
                    href={link.path}
                  >
                    {link.title}
                  </NavLink>
                </NavItem>
              </TouchableOpacity>
            ))}
          </Nav>
        </div>

        <div className="inner-buttons-container border-top w-100 overflow-hidden">
          <Row>
            <Col className="text-center">
              <SocialIcons />
            </Col>
          </Row>
        </div>
      </div>
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  collapseMenu: state.collapseMenu,
  auth: state.form,
});

export default connect(mapStateToProps, { setCollapseMenu })(CollapseMenu);
