import React, { Component } from 'react'
import termsofUse from './Policies/TermsofUse.json'
import BreadCrumbs from './BreadCrumbs.js'

const crumbs = [{
    name: 'Help Centre',
    route: '/help-centre'
}, {
    name: 'Terms of Use',
    route: '/termsofuse'
}]


export default class TermsandConditions extends Component {
    render() {
        return (
            <div >
                <div className='container'>
                    <div >
                        <BreadCrumbs crumbs={crumbs} title='TERMS OF USE' />
                    </div>
                </div>
                <div className='container my-2'>
                    {termsofUse[0].termsofUse.map(term => (
                        <>
                            <h1 className='my-4' style={{ fontSize: 25 }}>{term.title}</h1>
                            {term.paragraphs.map(eachParagraph => (
                                <li style={{ lineHeight: 2, fontSize: 15 }}>{eachParagraph.paragraph}</li>
                            ))}
                        </>
                    ))}
                </div>
            </div>
        )
    }
}
