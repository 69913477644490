export default {
  categoryOptions: [
    {
      hidden: true,
      label: "Category",
      value: ""
    },
    {
      label: "Solid Colours",
      value: "solid"
    },
    {
      label: "Nail Patterns",
      value: "nailart"
    },
    {
      label: "Overlay",
      value: "overlay"
    },
    {
      label: "Marble Designs",
      value: "marble"
    },
    {
      label: "Miss Frankie",
      value: "missfrankie"
    },
    {
      label: "Sale",
      value: "sale"
    }
  ],

  buttons: {
    addToCartLabel: "ADD TO CART",
    selectBundle: "SELECT NAIL WRAPS"
  }
};
