import React, { Component } from "react";
import { Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import NewLabel from "./NewLabel";

class ProductTile extends Component {
  state = {
    hover: "",
  };
  onMouseEnter = (product) => {
    this.setState({
      hover: product._id,
    });
  };

  routeToSpecificPage = (product) => {
    this.props.history.push({
      pathname: `/shop/${product.name.replace(/[^A-Z0-9]+/gi, "-")}/${
        product._id
      }`,
    });
  };

  render() {
    const { product, bestSellers } = this.props;

    return (
      <Col
        className="my-2"
        md={bestSellers ? "12" : "4"}
        xs={bestSellers ? "12" : "6"}
        key={product._id}
        onClick={() => this.routeToSpecificPage(product)}
      >
        <div className="mx-auto text-center">
          <div style={{ cursor: "pointer" }}>
            {product.imageList.length !== 0 && (
              <React.Fragment>
                <img
                  src={
                    this.state.hover === product._id
                      ? product.imageList.length > 1
                        ? product.imageList[1].imageUrl
                        : product.imageList[0].imageUrl
                      : product.imageList[0].imageUrl
                  }
                  alt={product.name}
                  className="img-fluid"
                  onMouseEnter={() => this.onMouseEnter(product)}
                  onMouseLeave={() => this.setState({ hover: "" })}
                />

                <NewLabel time={product.created_at} />
              </React.Fragment>
            )}
          </div>

          <p
            style={{ fontSize: 14 }}
            className="mt-3 mb-0  text-left font-weight-bold"
          >
            {product.name}
          </p>
          {product.reviews && product.reviews.length !== 0 && (
            <div className="text-left">
              <i className="fas fa-star" style={{ color: "gold" }}></i>
              <i className="fas fa-star" style={{ color: "gold" }}></i>
              <i className="fas fa-star" style={{ color: "gold" }}></i>
              <i className="fas fa-star" style={{ color: "gold" }}></i>
              <i className="fas fa-star " style={{ color: "gold" }}></i>
              <span style={{ fontSize: 12 }}> ({product.reviews.length})</span>
            </div>
          )}
          <div
            className="text-left"
            style={{ cursor: "pointer", fontSize: 14 }}
          >
            {product.originalPrice && product.originalPrice !== "" && (
              <React.Fragment>
                <span
                  style={{
                    textDecoration:
                      product.originalPrice &&
                      product.originalPrice !== "" &&
                      product.price < product.originalPrice &&
                      "line-through",
                    color:
                      product.originalPrice &&
                      product.originalPrice !== "" &&
                      product.price < product.originalPrice &&
                      "#fbcdc3",
                  }}
                >
                  {" "}
                  ${product.originalPrice.toFixed(2)}{" "}
                </span>
                <br />
              </React.Fragment>
            )}
            {((product.originalPrice &&
              product.originalPrice !== "" &&
              product.originalPrice > product.price) ||
              !product.originalPrice) && (
              <span> ${product.price.toFixed(2)} </span>
            )}
          </div>
        </div>
      </Col>
    );
  }
}

export default withRouter(ProductTile);
