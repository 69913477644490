import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'


class LoginIcon extends Component {

    handleUserLogoClick=()=>{
        const { isAuthenticated } = this.props.auth;

        if(isAuthenticated){
            this.props.history.push('/dashboard')
        }else{
            this.props.history.push('/login')
        }
    }
    render() {
        return (
            <div className='d-inline'>
                <i onClick={this.handleUserLogoClick} className="fas fa-user pr-2 nav-cart" style={{ fontSize: '17px', cursor: 'pointer' }} ></i>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.form,
})


export default connect(mapStateToProps, { })(withRouter(LoginIcon))
