import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Collapse,
  FormGroup,
  Label,
} from "reactstrap";
import { connect } from "react-redux";
import {
  setPaymentType,
  clickPay,
  sendPaypalPayment,
  fullPageLoading,
} from "../actions/checkOutActions";
import Stripe from "./Stripe";
import visa from "../images/visa.png";
import mastercard from "../images/mastercard.png";
import { withRouter } from "react-router-dom";
import { PayPalButton } from "react-paypal-button-v2";
import Spinner from "./Spinner";
import shippingOptions from "../utils/shippingOptions";
import { addPaymentInfo } from "../utils/analytics";

class Payment extends Component {
  state = {
    paypalPaymentAuthorized: false,
    paypalButtonLoading: true,
    windowWidth: 0,
    verifyForm: false,
  };
  componentDidMount() {
    this.setState({
      windowWidth: window.innerWidth,
    });
    window.addEventListener("resize", this.updateWindowDimension);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimension);
  }
  updateWindowDimension = (e) => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };

  setPaymentType = (e) => {
    addPaymentInfo(e.target.name, this.props.shoppingCart.cart);
    this.props.setPaymentType(e.target.name);
  };
  handlePayment = (e) => {
    this.props.clickPay();
  };

  getShippingMethod = () => {
    return this.props.checkout.country === "Australia"
      ? shippingOptions.domestic.filter(
          (item) => item.name === this.props.checkout.shippingMethod
        )
      : shippingOptions.international.filter(
          (item) => item.name === this.props.checkout.shippingMethod
        );
  };

  onApprove = (paymentData, actions) => {
    this.setState({
      paypalPaymentAuthorized: true,
    });
    this.props.fullPageLoading();
    actions.order.capture().then((details) => {
      if (this.props.checkout.paymentType === "paypal") {
        let data = {};
        data.firstName = this.props.checkout.firstname;
        data.lastName = this.props.checkout.lastname;
        data.address = this.props.checkout.address;
        data.city = this.props.checkout.city;
        data.postcode = this.props.checkout.postcode;
        data.region = this.props.checkout.region;
        data.country = this.props.checkout.country;
        data.message = this.props.checkout.description;
        data.shippingFee = this.props.checkout.shippingFee;
        data.shippingType = this.props.checkout.shippingMethod;
        data.phoneNumber = this.props.checkout.phoneNumber;
        data.email = this.props.checkout.email;
        data.paymentId = details.purchase_units[0].payments.captures[0].id;
        data.cart = this.props.shoppingCart.cart;
        if (Object.keys(this.props.discount.userCoupon).length > 0) {
          data.coupon = this.props.discount.userCoupon;
        }

        if (this.props.auth.isAuthenticated) {
          data.userid = this.props.auth.user.id;
        } else if (this.props.guest.userid !== "") {
          data.userid = this.props.guest.userid;
        }
        this.props.sendPaypalPayment(data);
      }
    });
  };
  onReady = () => {
    this.setState({
      paypalButtonLoading: false,
    });
  };

  handleCheckBox = (e) => {
    this.setState({
      verifyForm: !this.state.verifyForm,
    });
  };

  render() {
    let paymentOptions = {
      clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
      currency: "AUD",
    };
    let buttonStyle = {
      color: "blue",
      layout: "horizontal",
      label: "paypal",
      shape: "pill",
      tagline: "false",
    };

    let subTotal = 0;

    if (this.props.shoppingCart.cart.length !== 0) {
      subTotal =
        Math.round(
          this.props.shoppingCart.cart
            .map((eachItem) => eachItem.quantity * eachItem.product.price)
            .reduce((total, currentValue) => total + currentValue) * 100
        ) / 100;
    }

    let total = subTotal;
    let discount;

    if (this.props.checkout.shippingFee !== null) {
      total = total + this.props.checkout.shippingFee;
    }

    if (Object.keys(this.props.discount.userCoupon).length > 0) {
      if (subTotal >= this.props.discount.userCoupon.minimumSpend) {
        if (this.props.discount.userCoupon.isPercent) {
          discount = (subTotal / 100) * this.props.discount.userCoupon.amount;
        } else {
          discount = this.props.discount.userCoupon.amount;
        }
      }
    }

    if (discount !== undefined) {
      total = total - discount;
    }
    if (total < 0) {
      total = 0;
    }

    let shippingMethod = this.getShippingMethod();

    return (
      <div>
        <div className=" mx-4">
          <Row className="">
            <Col className="">
              <table className="table border">
                <tbody>
                  <tr>
                    <td>Contact</td>
                    <td> {this.props.checkout.email}</td>
                  </tr>
                  <tr>
                    <td> Ship To</td>
                    <td>
                      {" "}
                      <span>
                        {this.props.checkout.address}&nbsp;
                        <br />
                        {this.props.checkout.city}&nbsp;
                        {this.props.checkout.region}&nbsp;
                        <br />
                        {this.props.checkout.country}&nbsp;
                        {this.props.checkout.postcode}&nbsp;
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Method</td>
                    <td>
                      {" "}
                      <span>
                        {shippingMethod.length !== 0 && shippingMethod[0].label}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>

              <Form>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" onClick={this.handleCheckBox} />
                    I've checked that all the information above is correct.
                  </Label>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </div>
        <div className="mx-3 py-3">
          <h5 className="mx-2 pt-3 text-muted font-weight-bold">Payment</h5>
          <p className="mx-2">All transactions are secure and encrypted.</p>
          <div className="border ">
            <Row className={`mx-3 `}>
              <Col xs="12" className=" py-3">
                <Form className="d-inline pr-3">
                  <Input
                    type="radio"
                    name="credit-card"
                    checked={this.props.checkout.paymentType === "credit-card"}
                    onChange={this.setPaymentType}
                  />
                </Form>
                Debit / Credit Card
                <span
                  className="float-right text-muted"
                  style={{ cursor: "default" }}
                >
                  <img src={visa} alt="visa payment" style={{ height: 15 }} />
                  <img
                    src={mastercard}
                    alt="visa payment"
                    style={{ height: 20 }}
                  />
                  and more..
                </span>
              </Col>
              <Col xs="12">
                <Collapse
                  isOpen={this.props.checkout.paymentType === "credit-card"}
                >
                  <Row className="py-3 ">
                    <Col>
                      <Stripe />
                    </Col>
                  </Row>
                </Collapse>
              </Col>
            </Row>
          </div>
        </div>

        <div>
          <Row className="mx-3 py-3 ">
            <Col md="6" xs="12" className="order-2 order-md-1  my-2  ">
              <Button
                disabled={this.props.checkout.buttonLoading}
                size="sm"
                color="dark"
                outline
                className="w-100 font-weight-bold"
                onClick={() => this.props.previousStep()}
              >
                &lt; Shipping
              </Button>
            </Col>
            <Col md="6" xs="12" className="order-1 text-right my-2 order-md-2 ">
              {this.props.checkout.paymentType === "credit-card" ||
              this.props.checkout.paymentType === null ? (
                <Button
                  className="w-100"
                  size="sm"
                  disabled={
                    this.props.checkout.paymentType !== "paypal" &&
                    (this.props.checkout.buttonLoading ||
                      this.props.checkout.cardNumber === null ||
                      !this.props.checkout.cardNumber.complete ||
                      this.props.checkout.cardExpiry === null ||
                      !this.props.checkout.cardExpiry.complete ||
                      this.props.checkout.cardCvc === null ||
                      !this.props.checkout.cardCvc.complete ||
                      !this.state.verifyForm)
                  }
                  onClick={this.handlePayment}
                  color="success"
                >
                  <span>Pay Now &gt;</span>
                </Button>
              ) : (
                <div className="text-center">
                  <PayPalButton
                    amount={total.toFixed(2)}
                    shippingPreference="NO_SHIPPING"
                    onApprove={this.onApprove}
                    options={paymentOptions}
                    style={buttonStyle}
                    onButtonReady={this.onReady}
                  />
                  {this.state.paypalButtonLoading && <Spinner />}
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  checkout: state.checkout,
  shoppingCart: state.cart,
  guest: state.guest,
  auth: state.form,
  discount: state.discount,
});

export default connect(mapStateToProps, {
  setPaymentType,
  clickPay,
  sendPaypalPayment,
  fullPageLoading,
})(withRouter(Payment));
