import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'reactstrap'
import Spinner from '../Spinner'

class YesterdayRevenue extends Component {
    render() {
        return (
            <div className=' text-left bg-light px-3 py-3 my-2' style={{ borderRadius: 10 }}>
                <Row>
                    <Col xs='8' >
                        <h6 className='font-weight-bold text-secondary'>TOTAL SALES YESTERDAY</h6>
                        {this.props.adminRevenueCalc.loading ? (
                            <div className='text-center my-auto'>
                                <Spinner />

                            </div>
                        ) : (
                                <h5 className='font-weight-bold text-dark'>${(this.props.adminRevenueCalc.yesterdayRevenue / 100).toFixed(2)}</h5>
                            )}

                    </Col>

                    <Col xs='4' className='my-3  text-center'>
                        <div className='rounded-circle text-white shadow px-3 py-3 bg-danger' style={{ width: '3em', height: '3em', alignItems: 'center', justifyContent: 'center', display: 'inline-flex', textAlign: 'center' }}>
                            <i className="fas fa-dollar-sign  " style={{ fontSize: '1.25em' }}></i>
                        </div>
                    </Col>

                </Row>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.form,
    adminRevenueCalc: state.adminRevenueCalc
})


export default connect(mapStateToProps, {})(YesterdayRevenue)