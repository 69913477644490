import {
    GET_USER_ORDERS,
    FORM_VALIDATION_ERROR,
    RESET_ORDER,
    GET_SINGLE_ORDER,
    USER_ORDERS_LOADING_FINISHED,
    USER_ORDERS_LOADING
} from './types'
import axios from 'axios'

export const resetOrder = () => dispatch => {
    dispatch({
        type: RESET_ORDER
    })
}


export const getUserOrders = userid => dispatch => {
    dispatch({
        type: USER_ORDERS_LOADING,
    })


    axios.get(`/api/orders/getProfileOrders/${userid}`)
        .then(res => {
            dispatch({
                type: GET_USER_ORDERS,
                payload: res.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            })
            dispatch({
                type: USER_ORDERS_LOADING_FINISHED,
            })
        })
        .catch(err => {
            dispatch({
                type: FORM_VALIDATION_ERROR,
                payload: err.response.data
            })
            dispatch({
                type: USER_ORDERS_LOADING_FINISHED,
            })
        }
        )

}
export const getOrder = orderid => dispatch => {
    dispatch({
        type: USER_ORDERS_LOADING,
    })

    axios.get(`/api/orders/getOrder/${orderid}`)
        .then(res => {
            dispatch({
                type: GET_SINGLE_ORDER,
                payload: res.data
            })
            dispatch({
                type: USER_ORDERS_LOADING_FINISHED,
            })
        })
        .catch(err => {
            dispatch({
                type: FORM_VALIDATION_ERROR,
                payload: err.response.data
            })
            dispatch({
                type: USER_ORDERS_LOADING_FINISHED,
            })
        }
        )

}
