import {
    DAILY_TOTAL_REVENUE,
    YESTERDAY_REVENUE,
    REVENUE_DETAILS_LOADING,
    RESET_REVENUE_DETAILS_LOADING,
    TODAYS_ORDER_COUNT,
    YESTERDAYS_ORDER_COUNT,
    CHANGE_STREAM_SET_TOTAL_ORDERS_YESTERDAY,
    CHANGE_STREAM_SET_TOTAL_ORDERS_TODAY,
    CHANGE_STREAM_SET_YESTERDAYS_TOTAL,
    CHANGE_STREAM_SET_TODAYS_TOTAL
} from './types'
import axios from 'axios'
import { logoutUser } from './formActions'



export const revenueDetails = () => dispatch => {
    dispatch({
        type: REVENUE_DETAILS_LOADING
    })
    axios.get(`/api/admin/revenueDetails`)
        .then(res => {
            dispatch({
                type: DAILY_TOTAL_REVENUE,
                payload: res.data.todaysTotal
            })
            dispatch({
                type: YESTERDAY_REVENUE,
                payload: res.data.yesterdayTotal
            })
            dispatch({
                type: YESTERDAYS_ORDER_COUNT,
                payload: res.data.totalOrdersYesterday
            })
            dispatch({
                type: TODAYS_ORDER_COUNT,
                payload: res.data.totalOrdersToday
            })
            dispatch({
                type: RESET_REVENUE_DETAILS_LOADING
            })
        })
        .catch(err => {
            dispatch({
                type: RESET_REVENUE_DETAILS_LOADING
            })
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }

            console.log(err)
        })

}

export const changeStreamRevenueData = data => dispatch => {
    dispatch({
        type: CHANGE_STREAM_SET_TODAYS_TOTAL,
        payload: data.todaysTotal
    })
    dispatch({
        type: CHANGE_STREAM_SET_YESTERDAYS_TOTAL,
        payload: data.yesterdayTotal
    })
    dispatch({
        type: CHANGE_STREAM_SET_TOTAL_ORDERS_TODAY,
        payload: data.totalOrdersToday
    })
    dispatch({
        type: CHANGE_STREAM_SET_TOTAL_ORDERS_YESTERDAY,
        payload: data.totalOrdersYesterday
    })
}
