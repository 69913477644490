import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getLatestOrders,
  todaysOrdersUpdate,
} from "../../actions/LatestOrders";
import { Row, Col } from "reactstrap";
import Spinner from "../Spinner";
import { withRouter } from "react-router-dom";
import Orders from "./Orders";

class LatestOrders extends Component {
  componentDidMount() {
    this.props.getLatestOrders();
  }

  render() {
    return (
      <div
        className=" text-left bg-light px-3 py-3  h-100 "
        style={{ borderRadius: 10, overflow: "scroll" }}
      >
        <h6 className="font-weight-bold text-secondary">Latest Orders</h6>
        <Row>
          <Col>
            <Orders orders={this.props.latestOrders.orders} />

            {this.props.latestOrders.loading && (
              <div className="text-center">
                <Spinner />
              </div>
            )}
            {!this.props.latestOrders.loading &&
              this.props.latestOrders.orders.length === 0 && (
                <div>
                  <h5 className="text-center text-muted">No orders found.</h5>
                </div>
              )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  latestOrders: state.latestOrders,
});

export default connect(mapStateToProps, {
  getLatestOrders,
  todaysOrdersUpdate,
})(withRouter(LatestOrders));
