import React, { useState } from "react";
import { Collapse } from "reactstrap";

export default function ProductShippingDescriptions() {
  const [open, setOpen] = useState(true);

  return (
    <div className="mt-3 ">
      <div>
        <p
          className="font-weight-bold border-bottom"
          style={{ fontSize: 14, cursor: "pointer" }}
          onClick={() => setOpen(!open)}
        >
          Shipping & Returns
          <span className="float-right">
            <i
              className={
                open ? "fa-solid fa-caret-up" : "fa-solid fa-caret-down"
              }
            />
          </span>
        </p>
      </div>

      <Collapse isOpen={open}>
        <p style={{ fontSize: 14 }} className=" pb-3">
          <ul className="m-0 px-3">
            <li>Free shipping on all domestic orders over $100</li>
            <li>Free international shipping on all orders over $150</li>
            <li>Free returns and exchanges for 30 days.</li>
            <li>Your order will ship within 24 hours on business days.</li>
          </ul>
        </p>
      </Collapse>
    </div>
  );
}
