import React from "react";

export default function TotalLabel({ total }) {
  return (
    <div className="my-3">
      <h4>
        Total <span className="float-right">AU ${total.toFixed(2)}</span>
      </h4>
    </div>
  );
}
