import {
    CREATE_NEW_CATEGORY,
    CREATE_NEW_CATEGORY_LOADING,
    CREATE_NEW_CATEGORY_LOADING_RESET,
    CREATE_NEW_CATEGORY_ERRORS,
    GET_ADMIN_CATEGORIES,
    GET_ADMIN_CATEGORIES_LOADING,
    GET_ADMIN_CATEGORIES_LOADING_RESET,
    DELETE_CATEGORY,
    RESET_CATEGORY_REDUCER,
    CATEGORY_ACTIVATION_STATUS_UPDATE
} from '../actions/types.js'


const initialState = {
    categories: [],
    loading: false,
    category: {},
    errors: {},
    newCategoryCreated: false,
    newCategoryLoading: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CATEGORY_ACTIVATION_STATUS_UPDATE:
            return {
                ...state,
                categories: state.categories.map(eachCategory => eachCategory._id === action.payload._id ? action.payload : eachCategory)
            }
        case RESET_CATEGORY_REDUCER:
            return {
                ...state,
                categories: [],
                loading: false,
                category: {},
                errors: {},
                newCategoryCreated: false,
                newCategoryLoading: false
            }
        case DELETE_CATEGORY:
            return {
                ...state,
                categories: state.categories.filter(eachCategory => eachCategory._id !== action.payload._id)
            }
        case GET_ADMIN_CATEGORIES:
            return {
                ...state,
                categories: action.payload
            }
        case GET_ADMIN_CATEGORIES_LOADING:
            return {
                ...state,
                loading: true
            }
        case GET_ADMIN_CATEGORIES_LOADING_RESET:
            return {
                ...state,
                loading: false
            }
        case CREATE_NEW_CATEGORY:
            return {
                ...state,
                newCategoryCreated: true,
                newCategoryLoading: false,
                errors: {}
            }
        case CREATE_NEW_CATEGORY_ERRORS:
            return {
                ...state,
                errors: action.payload
            }
        case CREATE_NEW_CATEGORY_LOADING:
            return {
                ...state,
                newCategoryLoading: true
            }
        case CREATE_NEW_CATEGORY_LOADING_RESET:
            return {
                ...state,
                newCategoryLoading: false
            }
        default: return state;
    }
}