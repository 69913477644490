import React, { Component } from 'react'

export default class FooterTitle extends Component {
    render() {
        return (
            <div className='container w-100 h-100 mx-0 px-0'>
                <h1 style={{ fontSize: 16 }} className='font-weight-bold' >{this.props.title}</h1>
            </div>
        )
    }
}
