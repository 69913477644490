import React, { Component } from 'react'
import FooterTitle from './FooterTitle'
import FooterParagraph from './FooterParagraph'

export default class Acknowledgement extends Component {
    render() {
        return (

            <div>
                <div className='container'>
                    <div >
                        <FooterTitle title='Acknowledgement of Country' />
                        <FooterParagraph
                            paragraph='Remote Trails acknowledges the Traditional Owners of the country on which we work, live and play. We recognise their continuing connection to land, waters and culture. We pay our respects to their Elders past, present and emerging. '
                        />
                    </div>
                </div>
            </div>
        )
    }
}
