import React from "react";
// import SearchBarIcon from "./SearchBarIcon";
import LoginIcon from "./LoginIcon";
import Cart from "./Cart";
import useWindowDimensions from "./useWindowDimensions";
import HamburgerIcon from "./HamburgerIcon";

const NavbarIcons = () => {
  const { height, width } = useWindowDimensions();

  return (
    <div>
      {/* <SearchBarIcon /> */}
      <LoginIcon />
      <Cart />
      {width < 768 && <HamburgerIcon />}
    </div>
  );
};

export default NavbarIcons;
