import {
  TOGGLE_CART,
  UPDATE_SHOPPING_CART,
  ADD_TO_CART_LOADING,
  RESET_REMOVE_FROM_CART,
  RESET_ADD_TO_CART_LOADING,
  REMOVE_FROM_CART_LOADING,
  RESET_CART_SYNC,
  CHECKOUT_CART_LOADING,
  CART_WARNINGS,
  VALIDATE_CART,
  RESET_WARNINGS,
  TRANSPARENT_FULL_PAGE_LOADING,
  RESET_FULL_PAGE_LOADING_CHECKOUT,
  REMOVE_GUEST_USER,
  CART_ERRORS,
} from "./types";
import axios from "axios";

export const resetWarnings = () => (dispatch) => {
  dispatch({
    type: RESET_WARNINGS,
  });
};

export const validateCart = (cart, userid) => (dispatch) => {
  const data = {
    cart,
    userid,
  };
  dispatch({
    type: CHECKOUT_CART_LOADING,
  });
  dispatch({
    type: TRANSPARENT_FULL_PAGE_LOADING,
  });
  axios
    .post(`/api/cart/validateCart/${userid}`, data)
    .then((res) => {
      dispatch({
        type: VALIDATE_CART,
        payload: res.data.dbCart,
      });
      dispatch({
        type: RESET_FULL_PAGE_LOADING_CHECKOUT,
      });

      dispatch({
        type: CART_WARNINGS,
        payload: res.data.warnings,
      });
    })
    .catch((err) => console.log(err));
};

export const resetSyncCart = () => (dispatch) => {
  dispatch({
    type: RESET_CART_SYNC,
  });
};

export const toggleCart = () => (dispatch) => {
  dispatch({
    type: TOGGLE_CART,
  });
};

export const updateShoppingCart = (cart, product, variant) => (dispatch) => {
  if (product) {
    dispatch({
      type: ADD_TO_CART_LOADING,
      payload: product._id,
    });

    axios
      .post("/api/cart/checkStockLevel", { params: { cart, product, variant } })
      .then((res) => {
        dispatch({
          type: UPDATE_SHOPPING_CART,
          payload: res.data,
        });

        dispatch({
          type: RESET_ADD_TO_CART_LOADING,
        });
      })
      .catch((err) => {
        dispatch({
          type: CART_ERRORS,
          payload: err.response.data,
        });
        dispatch({
          type: RESET_ADD_TO_CART_LOADING,
        });
      });
  } else {
    //remove item from cart
    dispatch({
      type: UPDATE_SHOPPING_CART,
      payload: cart,
    });
  }
};

export const removeFromCart = (product, uid, variant) => (dispatch) => {
  dispatch({
    type: REMOVE_FROM_CART_LOADING,
    payload: product._id,
  });
  axios
    .post("/api/cart/removeFromCart", { params: { product, uid, variant } })
    .then((res) => {
      dispatch({
        type: UPDATE_SHOPPING_CART,
        payload: res.data,
      });
      dispatch({
        type: RESET_REMOVE_FROM_CART,
      });
    })
    .catch((err) => console.log(err));
};

export const addTotalQuantityToCart =
  (product, quantity, uid, variant) => (dispatch) => {
    dispatch({
      type: ADD_TO_CART_LOADING,
      payload: product._id,
    });
    axios
      .post("/api/cart/addQuantityToCart", {
        params: { product, quantity, uid, variant },
      })
      .then((res) => {
        if (res.data.resetGuest) {
          dispatch({
            type: REMOVE_GUEST_USER,
          });
        } else {
          dispatch({
            type: UPDATE_SHOPPING_CART,
            payload: res.data,
          });
        }
        dispatch({
          type: RESET_ADD_TO_CART_LOADING,
        });
      })
      .catch((err) => {
        dispatch({
          type: CART_ERRORS,
          payload: err.response.data,
        });
        dispatch({
          type: RESET_ADD_TO_CART_LOADING,
        });
      });
  };
