import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Button } from "reactstrap";
import { Helmet } from "react-helmet";
import { getUserOrders, resetOrder } from "../actions/orderActions";
import DashboardOrderList from "./DashboardOrderList";
import Spinner from "./Spinner";

class Dashboard extends Component {
  componentDidMount() {
    this.props.getUserOrders(this.props.auth.user.id);
  }

  handleClick = (e) => {
    this.props.history.push("/dashboard/addresses");
  };
  handleSignleOrder = (item) => {
    this.props.history.push(`/dashboard/order-details/${item._id}`);
  };

  render() {
    return (
      <div className="dashboard-view">
        <div>
          <Helmet>
            <title>Account - Remote Trails</title>
            <meta name="description" content="Account - Remote Trails" />
          </Helmet>
        </div>
        <Row className="mx-5 my-3 border-bottom py-3">
          <Col>
            <h4 style={{ fontWeight: "bolder" }}>My Account</h4>
          </Col>
        </Row>
        <Row className="mx-5 my-3 border-bottom  py-3">
          <Col>
            <h5 style={{ fontWeight: "bold" }}>Order History</h5>
            {this.props.orders.fetching ? (
              <div>
                <Spinner />
              </div>
            ) : this.props.orders.userOrders.length === 0 ? (
              <p>You haven't placed any orders yet.</p>
            ) : (
              <DashboardOrderList />
            )}
          </Col>
        </Row>
        <Row className="mx-5 my-3  py-3">
          <Col>
            <h5 style={{ fontWeight: "bold" }}>Account Details</h5>
            <p>
              {this.props.auth.user.firstName} {this.props.auth.user.lastName}
            </p>

            {this.props.auth.user.adress.length !== 0 &&
              this.props.auth.user.adress.map(
                (eachAdress, index) =>
                  eachAdress.addressType === "default" && (
                    <p key={index}>
                      {" "}
                      Address:
                      <span> {eachAdress.streetAdress} </span>
                      <span>
                        {" "}
                        {eachAdress.city.toUpperCase()}{" "}
                        {eachAdress.region && eachAdress.region}{" "}
                        {eachAdress.country} {eachAdress.zipCode}{" "}
                      </span>
                    </p>
                  )
              )}
            <Button
              onClick={this.handleClick}
              color="dark"
              outline
              className="btn btn-sm font-weight-bold my-3"
            >
              View Addresses ({this.props.auth.user.adress.length})
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  errors: state.errors,
  auth: state.form,
  cart: state.cart,
  fullPageLoading: state.fullPageLoading,
  orders: state.orders,
});

export default connect(mapStateToProps, { getUserOrders, resetOrder })(
  Dashboard
);
