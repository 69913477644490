import React, { Component } from "react";
import { Col, Row, Button } from "reactstrap";
import image1 from "../images/camping.jpg";

class Landing extends Component {
  state = {
    offsetY: 0,
    windowWidth: 0,
  };
  componentDidMount() {
    this.setState({
      windowWidth: window.innerWidth,
    });
    window.addEventListener("resize", this.updateWindowDimension);
    window.addEventListener("scroll", this.listenToScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimension);
    window.removeEventListener("scroll", this.listenToScroll);
  }
  listenToScroll = () => {
    this.setState({
      offsetY: window.pageYOffset,
    });
  };
  updateWindowDimension = (e) => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };

  render() {
    return (
      <div>
        <Row className=" my-auto h-100 ">
          <Col
            xl="6"
            lg="12"
            className=" my-auto position-relative order-xl-2 order-xs-1"
          >
            <div className="position-relative">
              <img
                src="https://remotetrails.s3.ap-southeast-2.amazonaws.com/DSC00229.JPG"
                className="img-fluid"
                alt="Remote Trails - Australian, Ethical, Sustainable Outdoor Apparel + Gear"
              />
            </div>
          </Col>

          <Col xl="6" lg="12" className=" my-auto h-100 order-xl-1 order-xs-2">
            <div
              style={{
                paddingLeft: "10%",
                paddingRight: "10%",
                marginTop: this.state.windowWidth < 1199 && "5%",
              }}
            >
              <div>
                <h1 style={{ fontSize: "58px" }}>
                  {/* We're changing the way things get made */}
                  Start Your Soul Searching Journey
                </h1>
              </div>

              <div className="my-5 ">
                <Button
                  href="/shop"
                  style={{
                    width: "200px",
                    borderRadius: "20px",
                    fontWeight: 700,
                    background: "black",
                    color: "white",
                    cursor: "pointer",
                  }}
                >
                  SHOP NOW{" "}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
export default Landing;
