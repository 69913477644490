import React, { Component } from 'react'
import { injectStripe, PaymentRequestButtonElement } from 'react-stripe-elements';
import { connect } from 'react-redux'

import { guestUser, updateInputs, verifyUser, expressCheckout, setShippingFee }
    from '../actions/checkOutActions'


class PaymentRequestForm extends Component {

    state = {
        canMakePayment: false,
        paymentRequest: null,
        subTotal: 0,
        total: 0,
    }

    componentDidMount() {
        let subTotal = Math.round((this.props.shoppingCart.cart.map(eachItem => eachItem.quantity * eachItem.product.price).reduce((total, currentValue) => total + currentValue)) * 100) / 100

        this.setState({
            subTotal,
            total: subTotal
        })
    }



    componentDidUpdate(prevProps, prevState) {


        if (this.props.discount.userCoupon !== prevProps.discount.userCoupon) {

            let discount;
            let orderTotal

            if (Object.keys(this.props.discount.userCoupon).length > 0) {
                if (this.state.subTotal >= this.props.discount.userCoupon.minimumSpend) {
                    if (this.props.discount.userCoupon.isPercent) {
                        discount = (this.state.subTotal / 100) * this.props.discount.userCoupon.amount
                    } else {
                        discount = this.props.discount.userCoupon.amount
                    }
                }
            }


            if (discount !== undefined) {
                orderTotal = this.state.subTotal - discount
                this.setState({
                    total: orderTotal
                })
            }


        }


        if (prevProps.stripe !== this.props.stripe) {
            const paymentRequest = this.props.stripe.paymentRequest({
                country: 'AU',
                currency: 'aud',
                total: {
                    label: 'OJE AUSTRALIA',
                    amount: this.state.total * 100,
                },
                requestPayerName: true,
                requestPayerEmail: true,
                requestShipping: true,
            });


            paymentRequest.on('shippingoptionchange', function (ev) {
                ev.updateWith({
                    status: 'success', total: {
                        label: 'OJE AUSTRALIA',
                        amount: this.state.total * 100 + ev.shippingOption.amount,
                    }
                });

            }.bind(this));

            paymentRequest.on('shippingaddresschange', function (ev) {

                let shippingOptions = []

                let shippingOption = {}

                if (ev.shippingAddress.country === 'AU') {
                    if (this.state.subTotal > 50) {
                        shippingOption.label = 'Free Express Shipping'
                        shippingOption.amount = 0
                        shippingOption.id = 'freeExpressShipping'
                        shippingOptions.push(shippingOption)
                        shippingOption = {}

                        ev.updateWith({
                            status: 'success', shippingOptions, total: {
                                label: 'OJE AUSTRALIA',
                                amount: this.state.total * 100 + 500,
                            }
                        });


                    } else {
                        shippingOption.label = 'Standard Shipping'
                        shippingOption.amount = 500
                        shippingOption.id = 'standardShipping'
                        shippingOptions.push(shippingOption)
                        shippingOption = {}

                        shippingOption.label = 'Express Shipping'
                        shippingOption.amount = 1000
                        shippingOption.id = 'expressShipping'
                        shippingOptions.push(shippingOption)
                        shippingOption = {}



                        ev.updateWith({
                            status: 'success', shippingOptions, total: {
                                label: 'OJE AUSTRALIA',
                                amount: this.state.total * 100 + 500,
                            }
                        });

                    }

                } else {

                    shippingOption.label = 'International Shipping'
                    shippingOption.amount = 2000
                    shippingOption.id = 'internationalShipping'
                    shippingOptions.push(shippingOption)
                    shippingOption = {}

                    ev.updateWith({
                        status: 'success', shippingOptions, total: {
                            label: 'OJE AUSTRALIA',
                            amount: this.state.total * 100 + 2000,
                        }
                    });

                }
            }.bind(this))



            paymentRequest.on('token', function ({ complete, token, ...data }) {

                let userData = {}

                userData.firstName = data.payerName.substr(0, data.payerName.indexOf(' '))
                userData.lastName = data.payerName.substr(data.payerName.indexOf(' ') + 1)
                userData.email = data.payerEmail
                userData.address = data.shippingAddress.addressLine[0]
                userData.city = data.shippingAddress.city
                userData.country = data.shippingAddress.country
                if (data.shippingAddress.region) {
                    userData.region = data.shippingAddress.region
                }
                if (data.shippingAddress.postalCode) {
                    userData.postcode = data.shippingAddress.postalCode
                }
                userData.shippingType = data.shippingOption.id
                userData.shippingFee = data.shippingOption.amount / 100
                userData.cart = this.props.shoppingCart.cart
                userData.goGreen = this.props.checkout.goGreen
                userData.token = token

                if (this.props.auth.isAuthenticated) {
                    userData.userid = this.props.auth.user.id
                }
                else if (this.props.guest.userid !== '') {
                    userData.userid = this.props.guest.userid
                }

                if (Object.keys(this.props.discount.userCoupon).length > 0) {
                    userData.coupon = this.props.discount.userCoupon
                }
                complete('success');

                this.props.expressCheckout(userData)
            }.bind(this))


            paymentRequest.canMakePayment().then((result) => {

                this.setState({ canMakePayment: !!result });
            });


            this.setState({ paymentRequest })
        }
    }

    handleClick = e => {

        this.state.paymentRequest.update({
            total: {
                label: 'OJE AUSTRALIA',
                amount: this.state.total * 100,
            },
            shippingOptions: []
        })


    }


    render() {
        return this.state.canMakePayment ? (
            <div className='my-3'>
                <h5 style={{ fontSize: '1.28571em' }}>Express Checkout</h5>
                <PaymentRequestButtonElement
                    paymentRequest={this.state.paymentRequest}
                    onClick={this.handleClick}
                    className="PaymentRequestButton border"
                    style={{
                        paymentRequestButton: {
                            theme: 'dark',
                            height: '64px',
                        },
                    }}
                />
            </div>

        ) : null;
    }
}



const mapStateToProps = (state) => ({
    checkout: state.checkout,
    shoppingCart: state.cart,
    guest: state.guest,
    auth: state.form,
    discount: state.discount

})


export default connect(mapStateToProps, {
    guestUser, updateInputs, verifyUser, setShippingFee, expressCheckout
})(injectStripe(PaymentRequestForm))
