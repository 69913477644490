import React, { Component } from 'react'
import {
    ListGroup, ListGroupItem, Row, Col

} from 'reactstrap'
import FooterTitle from './FooterTitle'
import FooterLinks from './FooterLinks'



const firstLinks = [{
    title: 'Contact Us',
    route: '/help-centre'
},
{
    title: 'Shipping',
    route: '/shipping-policy'
},
{
    title: 'Terms of Use',
    route: '/termsofuse'
},

]

const secondLinks = [{
    title: 'Privacy Policy',
    route: '/privacypolicy'
},
]



export default class MoreInfo extends Component {
    render() {
        return (
            <div>
                <div className='container'>
                    <div >
                        <FooterTitle title='More Info' />

                        <div>
                            <Row>
                                <Col sm='12' md='6'>
                                    <FooterLinks links={firstLinks} />
                                </Col>
                                <Col sm='12' md='6'>
                                    <FooterLinks links={secondLinks} />
                                </Col>

                            </Row>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
