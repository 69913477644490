import React, { Component } from "react";
import { Col } from "reactstrap";
import Carousel from "react-multi-carousel";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 3000, min: 1210 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1210, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
export default class PageLinkCarousel extends Component {
  state = {
    selectedImage: null,
  };

  handleFeaturedImage = (image) => {
    this.setState({
      selectedImage: image.imageUrl,
    });
  };
  render() {
    const { product } = this.props;
    return (
      <Col xs="12" className="mb-4">
        <Carousel
          showDots={true}
          responsive={responsive}
          ssr={true}
          infinite={true}
          keyBoardControl={true}
          className="pb-5"
        >
          {product.imageList.map((eachImage) => (
            <img
              className="img-fluid px-2"
              style={{ objectFit: "contain" }}
              src={eachImage.imageUrl}
              alt={product.description}
              onClick={() => this.handleFeaturedImage(eachImage)}
            />
          ))}
        </Carousel>
      </Col>
    );
  }
}
