const shippingOptions = {
  domestic: [
    {
      name: "free-shipping",
      label: "Standard Shipping - Tracking Included",
      fee: 0,
      minimum: 100,
    },
    {
      name: "standard-shipping",
      label: "Standard Shipping - Tracking Included",
      fee: 10,
      minimum: 0,
    },
    {
      name: "express-shipping",
      label: "Express Shipping - Tracking Included",
      fee: 15,
      minimum: 0,
    },
  ],
  international: [
    {
      name: "free-international-shipping",
      label: "Free International Shipping",
      fee: 0,
      minimum: 150,
    },
    {
      name: "international-shipping",
      label: "DHL Express",
      fee: 35,
      minimum: 0,
    },
  ],
};

export default shippingOptions;
