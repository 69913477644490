import React, { Component } from "react";
import { Col } from "reactstrap";
import SocialMedia from "./SocialMedia";

export default class CopyrightBar extends Component {
  render() {
    return (
      <div className="container">
        <div className="container">
          <div className="row py-4 d-flex align-items-center">
            <Col className="text-left ">
              <div className="py-3">
                <i className="far fa-copyright pr-2"></i>
                {new Date().getFullYear()} Copyright:
                <a className="text-dark" href="https://remotetrails.com.au/">
                  {" "}
                  remotetrails.com.au
                </a>
              </div>
            </Col>
            <Col className="text-right ">
              <SocialMedia />
            </Col>
          </div>
        </div>
      </div>
    );
  }
}
