import React, { Component } from "react";
import { Row, Col } from "reactstrap";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { logoutUser } from "../actions/formActions";
import { getCategories } from "../actions/categoryActions.js";

class Topbar extends Component {
  render() {
    return (
      <div
        id="topbar-header"
        style={{ backgroundColor: "whitesmoke", fontSize: 14, height: 40 }}
      >
        <div className="container">
          <Row>
            <Col>
              <div className=" h-100 my-auto">
                <p className=" text-center py-2 m-0">
                  Free shipping on all domestic orders over $100.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  shoppingCart: state.cart,
  products: state.products,
  auth: state.form,
  categories: state.categories,
});

export default connect(mapStateToProps, { logoutUser, getCategories })(
  withRouter(Topbar)
);
