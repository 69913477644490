import React, { Component } from "react";
import { Fade } from "react-reveal";
import { Badge, Button } from "reactstrap";
import moment from "moment";
import Masonry from "react-masonry-css";

const breakpointColumnsObj = {
  default: 4,
  // 1100: 4,
  700: 3,
  500: 2,
};

class ReviewCards extends Component {
  state = {
    page: 1,
    showButton: true,
    reviews: [],
    size: 10,
  };

  handleMoreClick = (e) => {
    this.setState({
      page: this.state.page + 1,
    });
  };

  componentDidMount() {
    this.setState({
      reviews: this.props.reviews.slice(0, this.state.page * this.state.size),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.page !== prevState.page) {
      if (
        this.props.reviews.slice(0, this.state.page * this.state.size).length <
        this.props.reviews
      ) {
        this.setState({
          reviews: this.props.reviews.slice(
            0,
            this.state.page * this.state.size
          ),
        });
      } else {
        this.setState({
          reviews: this.props.reviews.slice(
            0,
            this.state.page * this.state.size
          ),
          showButton: false,
        });
      }
    }
  }

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  capitalizeLastName = (string) => {
    return string.charAt(0).toUpperCase() + ".";
  };

  render() {
    return (
      <div className="mt-5">
        <Fade bottom>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {this.state.reviews.map(
              (review, i) =>
                i < this.state.page * 10 && (
                  <div key={review._id}>
                    <div className=" border" style={{ cursor: "default" }}>
                      {review.reviewImage && review.reviewImage !== "" && (
                        <img
                          className="img-fluid position-relative"
                          style={{ objectFit: "cover" }}
                          src={review.reviewImage}
                          alt={review.review}
                        />
                      )}

                      <div className="p-3">
                        <h5 className="m-0 d-inline" style={{ fontSize: 15 }}>
                          {this.capitalizeFirstLetter(review.firstName)}{" "}
                          {this.capitalizeLastName(review.lastName)}{" "}
                        </h5>
                        {review.userId?.isActive && (
                          <h6 className="m-0 d-inline">
                            <Badge size="sm" color="success">
                              {" "}
                              <i className="fas fa-check"></i> Verified
                            </Badge>
                          </h6>
                        )}
                        <p className="m-0 text-muted" style={{ fontSize: 12 }}>
                          {" "}
                          {moment(review.lastUpdated).format("Do MMM YY")}
                        </p>
                        <span
                          style={{ fontSize: "18px", display: "inline-block" }}
                        >
                          {[...Array(Math.ceil(review.rating))].map(
                            (star, index) => (
                              <i
                                key={index}
                                className="fas fa-star"
                                style={{ fontSize: 15, color: "gold" }}
                              ></i>
                            )
                          )}
                          {[...Array(Math.ceil(5 - review.rating))].map(
                            (star, index) => (
                              <i
                                key={index}
                                className="far fa-star"
                                style={{ fontSize: 15, color: "gold" }}
                              ></i>
                            )
                          )}
                        </span>
                        <br />
                        <span style={{ fontSize: 14 }}>
                          {this.capitalizeFirstLetter(review.review)}
                        </span>
                      </div>
                    </div>
                  </div>
                )
            )}
          </Masonry>
          <div className=" text-center">
            {this.props.reviews.length > 10 && this.state.showButton && (
              <Button
                onClick={this.handleMoreClick}
                className="border"
                outline
                color="dark"
              >
                Read More
              </Button>
            )}
          </div>
        </Fade>
      </div>
    );
  }
}
export default ReviewCards;
