import React, { Component } from "react";
import SizeGuideModal from "./SizeGuideModal";

export default class Variants extends Component {
  render() {
    const { variants, setVariant, selectedVariant } = this.props;
    return (
      <div>
        {variants && variants.length > 1 && (
          <>
            <SizeGuideModal />
            {variants.map((variant) => (
              <div
                key={variant._id}
                className=" my-2 p-2 position-relative"
                style={{
                  fontSize: 12,
                  cursor: "pointer",
                  fontWeight: selectedVariant === variant && "600",
                  border:
                    selectedVariant === variant ? "1px solid" : "0.2px solid",
                }}
                onClick={() => {
                  if (
                    variant.variantStockLevel !== 0 &&
                    selectedVariant !== variant
                  ) {
                    setVariant(variant);
                  }
                }}
              >
                {variant.variantName}
                {variant.variantStockLevel === 0 && (
                  <span
                    className="text-muted"
                    style={{ position: "absolute", right: 10 }}
                  >
                    Out of stock
                  </span>
                )}
              </div>
            ))}
          </>
        )}
      </div>
    );
  }
}
