import ReactPixel from "react-facebook-pixel";
import { IN_DEVELOPMENT } from "../utils/constants";
import lodash from "lodash";
import ReactGA from "react-ga4";

export function initializeTracking(user) {
  const options = {
    autoConfig: true,
    debug: IN_DEVELOPMENT ? true : false,
  };

  const advancedMatching = {
    em: "",
    firstName: "",
    lastName: "",
  };

  if (user) {
    advancedMatching.em = user.email;
    advancedMatching.firstName = user.firstName;
    advancedMatching.lastName = user.lastName;
  }
  if (!IN_DEVELOPMENT) {
    ReactPixel.init("732861361473885", advancedMatching, options);
    ReactPixel.pageView();

    ReactGA.initialize("G-1BHF2KGGQX");
    ReactGA.send("pageview");
  }
}

const getValue = (items) => {
  return !lodash.isEmpty(items)
    ? items
        .map((item) => item.quantity * item.product.price)
        .reduce((total, currentValue) => total + currentValue)
    : 0;
};

const getNumofItems = (items) => {
  return items
    .map((item) => item.quantity)
    .reduce((total, currentValue) => total + currentValue);
};

const getCurrency = () => {
  return "AUD";
};

const getContents = (items) => {
  return items.map((item) => item.product._id);
};

export function initiateCheckout(items) {
  if (!IN_DEVELOPMENT) {
    ReactPixel.track("InitiateCheckout", {
      content_ids: getContents(items),
      currency: getCurrency(),
      num_items: getNumofItems(items),
      value: getValue(items),
      content_type: "product",
    });
  }
}

export function purchase(items) {
  if (!IN_DEVELOPMENT) {
    ReactPixel.track("Purchase", {
      content_name: "Purchase",
      contents: items.map((item) => {
        return { id: item.id, quantity: item.quantity };
      }),
      currency: getCurrency(),
      value: Number(
        items
          .map((item) => item.quantity * item.price)
          .reduce((total, currentValue) => total + currentValue)
      ),
      content_type: "product",
    });
  }
}

export function addPaymentInfo(payment_type, items) {
  if (!IN_DEVELOPMENT) {
    ReactPixel.track("AddPaymentInfo", {
      content_category: payment_type,
      content_ids: getContents(items),
      currency: getCurrency(),
      value: getValue(items),
    });
  }
}

export function viewContent(item) {
  if (!IN_DEVELOPMENT) {
    ReactPixel.track("ViewContent", {
      content_category: item.category,
      content_name: item.name,
      content_type: "product",
      content_ids: [item._id],
      currency: getCurrency(),
      value: item.price,
    });
  }
}

export function pageView() {
  if (!IN_DEVELOPMENT) {
    ReactPixel.pageView();
    ReactGA.send("pageview");
  }
}
export function addToCartEvent(item) {
  if (!IN_DEVELOPMENT) {
    ReactPixel.track("AddToCart", {
      content_name: item.name,
      content_type: "product",
      content_ids: [item._id],
      currency: getCurrency(),
      value: item.price,
    });

    window.gtag("event", "conversion", {
      send_to: "AW-10785059388/WcHNCK6GqMcDELzk25Yo",
      value: item.price,
      currency: "AUD",
    });
  }
}

export function completeRegistration(content_name, value) {
  if (!IN_DEVELOPMENT) {
    ReactPixel.track("CompleteRegistration", {
      content_name: content_name,
      currency: getCurrency(),
      status: 1,
      value,
    });
  }
}

export function lead(items, type) {
  if (!IN_DEVELOPMENT) {
    ReactPixel.track("Lead", {
      content_category: "User Info Added",
      content_name: type ? type : "Check Out",
      currency: getCurrency(),
      value: getValue(items),
    });
  }
}

export function search(items, search_string) {
  if (!IN_DEVELOPMENT) {
    ReactPixel.track("Search", {
      content_category: "Search Bar",
      content_ids: getContents(items),
      currency: getCurrency(),
      search_string,
      value: getValue(items),
    });
  }
}
export function contact() {
  if (!IN_DEVELOPMENT) {
    ReactPixel.track("Contact");
  }
}
