import {
    GET_LATEST_ORDERS,
    GET_LATEST_ORDERS_LOADING,
    GET_LATEST_ORDERS_LOADING_RESET,
    TODAY_ORDERS_UPDATE,
    AVERAGE_ORDER_SIZE,
    AVERAGE_ORDER_VALUE,
    GET_AVERAGE_PER_SHIPPING,
    GET_COUPON_STATS
} from './types'
import axios from 'axios'

export const getCouponStats = () => dispatch => {
    axios.get('/api/admin/calculateCouponStats')
        .then(res => {
            dispatch({
                type: GET_COUPON_STATS,
                payload: res.data
            })
        })
}



export const getAveragePerShipping = () => dispatch => {
    axios.get('/api/admin/getAveragePerShipping')
        .then(res => {
            dispatch({
                type: GET_AVERAGE_PER_SHIPPING,
                payload: res.data
            })
        })
}


export const getAverageOrderValue = () => dispatch => {
    axios.get('/api/admin/getAverageOrderValue')
        .then(res => {
            dispatch({
                type: AVERAGE_ORDER_VALUE,
                payload: res.data
            })
        })
}



export const getAverageOrderSize = () => dispatch => {
    axios.get('/api/admin/getAverageOrderSize')
        .then(res => {
            dispatch({
                type: AVERAGE_ORDER_SIZE,
                payload: res.data
            })
        })
}


export const getLatestOrders = () => dispatch => {
    dispatch({
        type: GET_LATEST_ORDERS_LOADING,
    })

    axios.get(`/api/admin/getLatestOrders`)
        .then(res => {
            dispatch({
                type: GET_LATEST_ORDERS,
                payload: res.data
            })
            dispatch({
                type: GET_LATEST_ORDERS_LOADING_RESET,
            })
        })
        .catch(err => {
            dispatch({
                type: GET_LATEST_ORDERS_LOADING_RESET,
            })
            console.log(err)
        })

}


export const todaysOrdersUpdate = (orders) => dispatch => {
    dispatch({
        type: TODAY_ORDERS_UPDATE,
        payload: orders
    })
}
