import React, { Component } from "react";
import { Helmet } from "react-helmet";
import {
  Row,
  Col,
  Input,
  InputGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import {
  getSingleProduct,
  UpdateStoreProduct,
  deleteProduct,
  resetForm,
  deleteProductImage,
} from "../../actions/adminProducts";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import OuterDropzone from "./OuterDropzone";
import { getCategories } from "../../actions/categoryActions.js";
import ImageList from "./ImageList";
import StandardButton from "./StandardButton";

const mapStateToProps = (state) => ({
  adminProducts: state.adminProducts,
  categories: state.categories,
});

const SortableItem = connect(mapStateToProps, { deleteProductImage })(
  SortableElement((props) => {
    const handleClick = (value) => {
      let data = {};
      data.image = value;

      props.deleteProductImage(data, props.product._id);
    };
    return (
      <div className="py-2 px-2 sortable-item">
        <div>
          <Button
            className="border-0 text-muted p-0 float-right"
            style={{ backgroundColor: "snow" }}
            onClick={() => handleClick(props.value)}
          >
            &times;
          </Button>
        </div>
        <img style={{ height: 100 }} src={props.value.imageUrl} />
      </div>
    );
  })
);

const SortableList = SortableContainer((props) => {
  return (
    <div style={{ backgroundColor: "snow" }}>
      {props.items.map((item, index) => (
        <SortableItem
          product={props.product}
          key={item._id}
          index={index}
          value={item}
          axis="x"
        />
      ))}
    </div>
  );
});

class UpdateProduct extends Component {
  state = {
    name: "",
    description: "",
    images: [],
    price: "",
    errors: {},
    productImageUrl: "",
    showUpdateModal: false,
    showDeleteModal: false,
    product: {},
    selectedImages: [],
    category: "",
    imageToBeDeleted: {},
    variants: [
      {
        variantName: "",
        variantStockLevel: "",
      },
    ],
  };

  handleBundleModal = () => {
    this.setState({
      bundleModal: !this.state.bundleModal,
    });
  };

  componentWillUnmount() {
    this.props.resetForm();
  }

  componentDidMount() {
    this.props.getSingleProduct(this.props.match.params.productid);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.adminProducts.errors !== prevProps.adminProducts.errors) {
      this.setState({
        errors: this.props.adminProducts.errors,
      });
    }

    if (this.props.adminProducts.product !== prevProps.adminProducts.product) {
      this.setState({
        name: this.props.adminProducts.product.name,
        description: this.props.adminProducts.product.description,
        price: this.props.adminProducts.product.price,
        product: this.props.adminProducts.product,
        images: this.props.adminProducts.product.imageList,
        category: this.props.adminProducts.product.category,
        variants: this.props.adminProducts.product.variants,
      });
    }

    if (
      this.props.adminProducts.productDeleted !==
        prevProps.adminProducts.productDeleted &&
      this.props.adminProducts.productDeleted
    ) {
      this.setState({
        showDeleteModal: true,
        name: "",
        description: "",
        images: [],
        price: "",
        errors: {},
        selectedImages: [],
        category: "",
        showProductDeleteModal: false,
        variants: [
          {
            variantName: "",
            variantStockLevel: "",
          },
        ],
      });

      setTimeout(this.sendToProductsPage, 3000);
    }

    if (
      this.props.adminProducts.productUpdated !==
        prevProps.adminProducts.productUpdated &&
      this.props.adminProducts.productUpdated
    ) {
      this.setState({
        showUpdateModal: true,
        selectedImages: [],
      });
    }

    if (
      this.state.showUpdateModal !== prevState.showUpdateModal &&
      this.state.showUpdateModal
    ) {
      setTimeout(this.resetShowUpdateModal, 2000);
    }
  }

  resetShowUpdateModal = () => {
    this.setState({
      showUpdateModal: false,
    });
  };
  sendToProductsPage = () => {
    this.props.history.push("/adminpanel/products");
  };

  handleDeleteButton = (e) => {
    this.props.deleteProduct(this.state.product._id);
  };

  onChange = (e) => {
    let value = e.target.value;
    this.setState({ [e.target.name]: value });

    if (this.props.adminProducts.productUpdated) {
      this.props.resetForm();
    }
  };

  onImageSelect = (files) => {
    this.setState({
      selectedImages: this.state.selectedImages.concat(files),
    });

    if (this.props.adminProducts.productUpdated) {
      this.props.resetForm();
    }
  };

  handleFormSubmit = (e) => {
    e.preventDefault();

    let formData = new FormData();

    if (this.state.selectedImages.length !== 0) {
      for (let i = 0; i < this.state.selectedImages.length; i++) {
        formData.append(`images`, this.state.selectedImages[i]);
      }
    }
    formData.append("imageList", JSON.stringify(this.state.images));
    formData.append("variants", JSON.stringify(this.state.variants));
    formData.append("name", this.state.name);
    formData.append("description", this.state.description);
    formData.append("price", this.state.price);
    formData.append("category", this.state.category);

    this.props.UpdateStoreProduct(formData, this.state.product._id);
  };

  handleNewProductRoute = () => {
    this.props.history.push(
      `/shop/${this.props.adminProducts.product.name.replace(
        /[^A-Z0-9]+/gi,
        "-"
      )}/${this.props.adminProducts.product._id}`
    );
  };

  toggleDeleteModal = () => {
    this.setState({
      showDeleteModal: !this.state.showDeleteModal,
    });
  };

  toggleUpdateModal = () => {
    this.setState({
      showUpdateModal: !this.state.showUpdateModal,
    });
  };

  deleteImage = () => {
    let data = {};
    data.image = this.state.imageToBeDeleted;

    this.props.deleteProductImage(data, this.state.product._id);
  };

  handleSale = (e) => {
    this.setState(
      {
        saleActive: !this.state.saleActive,
      },
      () => {
        this.setState({
          originalPrice: this.state.saleActive ? this.state.price : "",
          price: this.state.saleActive ? "" : this.state.originalPrice,
        });
      }
    );
  };

  handleProductDeleteButton = (e) => {
    this.setState({
      showProductDeleteModal: !this.state.showProductDeleteModal,
    });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ images }) => ({
      images: arrayMoveImmutable(images, oldIndex, newIndex),
    }));
  };

  imageSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ selectedImages }) => ({
      selectedImages: arrayMoveImmutable(selectedImages, oldIndex, newIndex),
    }));
  };

  handleImageRemove = (value) => {
    this.setState({
      selectedImages: this.state.selectedImages.filter(
        (image) => image.path !== value.path
      ),
    });
  };

  toggleProductDeleteModal = (e) => {
    this.setState({
      showProductDeleteModal: !this.state.showProductDeleteModal,
    });
  };

  handleVariant = (e, index) => {
    let variants = [...this.state.variants];

    variants[index][e.target.name] = e.target.value;

    let errors = this.state.errors;
    if (errors.variants) {
      delete errors.variants;
    }

    this.setState({
      variants,
      errors,
    });
  };

  handleNewVariant = (e) => {
    let variant = {
      variantName: "",
      variantStockLevel: "",
    };

    this.setState({
      variants: [...this.state.variants, variant],
    });
  };

  handleRemoveVariant = (index) => {
    var array = [...this.state.variants]; // make a separate copy of the array
    if (index !== -1) {
      array.splice(index, 1);
    }

    let data = {};
    data.product = this.state.product;
    data.newVariants = array;

    this.props.updateVariants(data);
  };

  render() {
    const { errors } = this.state;
    return (
      <div>
        <div>
          <Helmet>
            <title>
              {this.props.location.state !== undefined &&
              this.props.location.state.product
                ? this.props.location.state.product.name
                : this.props.match.params.title.replace(/-/g, " ")}{" "}
              - Remote Trails
            </title>
            <meta
              name="description"
              content={`${
                this.props.location.state !== undefined &&
                this.props.location.state.product
                  ? this.props.location.state.product.name
                  : this.props.match.params.title.replace(/-/g, " ")
              } - Remote Trails`}
            />
          </Helmet>
        </div>

        <div>
          {this.state.showProductDeleteModal && (
            <Modal
              isOpen={this.state.showProductDeleteModal}
              toggle={this.toggleProductDeleteModal}
            >
              <ModalHeader>
                Are you sure you want to delete this product?
              </ModalHeader>
              <ModalFooter>
                <Button color="success" onClick={this.handleDeleteButton}>
                  Yes
                </Button>
                <Button color="danger" onClick={this.toggleProductDeleteModal}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </div>
        <div>
          {this.props.adminProducts.loading &&
          Object.keys(this.state.product).length === 0 ? (
            <div className="text-center  my-5">
              <h5>Please wait. Product details are being fetched.</h5>
            </div>
          ) : (
            <Row className="pb-5">
              <Col>
                <form
                  encType="multipart/form-data"
                  onSubmit={this.handleFormSubmit}
                >
                  <Label className=" h6 my-3">
                    *All fields are required. The form is case sensitive.
                  </Label>

                  <InputGroup className="my-2">
                    <FormGroup className="w-100 ">
                      <Label>Title of product </Label>
                      <Input
                        placeholder="Title of product"
                        onChange={this.onChange}
                        className={classnames("", {
                          "is-invalid": errors.name,
                        })}
                        value={this.state.name}
                        name="name"
                      />
                      <div className="invalid-feedback">{errors.name}</div>
                    </FormGroup>
                  </InputGroup>
                  <InputGroup className="my-2">
                    <FormGroup className="w-100 ">
                      <Label>Description of product </Label>
                      <Input
                        type="textarea"
                        placeholder="Description of product"
                        onChange={this.onChange}
                        className={classnames("", {
                          "is-invalid": errors.description,
                        })}
                        value={this.state.description}
                        name="description"
                      />
                      <div className="invalid-feedback">
                        {errors.description}
                      </div>
                    </FormGroup>
                  </InputGroup>

                  {this.state.variants.map((variant, index) => (
                    <div key={index}>
                      <Label className="text-secondary font-weight-bold w-100">
                        {index + 1}. VARIANT
                        {index > 0 && (
                          <span
                            onClick={() => this.handleRemoveVariant(index)}
                            style={{ cursor: "pointer" }}
                            className="float-right"
                          >
                            &times;
                          </span>
                        )}
                      </Label>

                      <InputGroup>
                        <FormGroup className="w-100 ">
                          <Label>Size </Label>
                          <Input
                            placeholder="Size"
                            onChange={(e) => this.handleVariant(e, index)}
                            value={variant.variantName}
                            name="variantName"
                          />
                        </FormGroup>
                      </InputGroup>

                      <InputGroup>
                        <FormGroup className="w-100 ">
                          <Label>Stock Level </Label>
                          <Input
                            placeholder="Stock Level"
                            onChange={(e) => this.handleVariant(e, index)}
                            value={variant.variantStockLevel}
                            name="variantStockLevel"
                            className={classnames("", {
                              "is-invalid": errors.variantStockLevel,
                            })}
                          />
                          <div className="invalid-feedback">
                            {errors.variantStockLevel}
                          </div>
                        </FormGroup>
                      </InputGroup>
                    </div>
                  ))}

                  <Button
                    color="dark"
                    outline
                    className="border"
                    onClick={this.handleNewVariant}
                  >
                    {" "}
                    Create a New Variant
                  </Button>

                  <InputGroup className="my-2">
                    <FormGroup className="w-100">
                      <Label>Category</Label>
                      <Input
                        type="select"
                        onChange={this.onChange}
                        className={classnames("", {
                          "is-invalid": errors.category,
                        })}
                        value={this.state.category}
                        name="category"
                      >
                        <option hidden>Category</option>
                        <option value="tops">Tops</option>
                        <option value="bottoms">Bottoms</option>
                        <option value="headwear">Headwear</option>
                        <option value="sale">Sale</option>
                      </Input>
                      <div className="invalid-feedback">{errors.category}</div>
                    </FormGroup>
                  </InputGroup>

                  {this.state.saleActive && (
                    <InputGroup className="my-2">
                      <FormGroup className="w-100 ">
                        <Label>Price of product </Label>
                        <Input
                          min="0"
                          step="0.01"
                          type="number"
                          placeholder="Price of product "
                          onChange={this.onChange}
                          className={classnames("", {
                            "is-invalid": errors.originalPrice,
                          })}
                          value={this.state.originalPrice}
                          name="originalPrice"
                        />
                        <div className="invalid-feedback">
                          {errors.originalPrice}
                        </div>
                      </FormGroup>
                    </InputGroup>
                  )}

                  <InputGroup className="my-2">
                    <FormGroup className="w-100 ">
                      <Label>
                        {this.state.saleActive
                          ? "Sale Price"
                          : "Price of product"}
                      </Label>
                      <Input
                        min="0"
                        step="0.01"
                        type="number"
                        placeholder={
                          this.state.saleActive
                            ? "Sale Price"
                            : "Price of product"
                        }
                        onChange={this.onChange}
                        className={classnames("", {
                          "is-invalid": errors.price,
                        })}
                        value={this.state.price}
                        name="price"
                      />
                      <div className="invalid-feedback">{errors.price}</div>
                    </FormGroup>
                  </InputGroup>

                  <div className="my-3">
                    <OuterDropzone
                      updateRemoveImage={this.handleImageRemove}
                      onSortChange={this.imageSortEnd}
                      images={this.state.selectedImages}
                      onFileSelect={this.onImageSelect}
                    />
                  </div>

                  <div className="my-3">
                    <ImageList
                      updateRemoveImage={this.handleImageRemove}
                      onSortChange={this.imageSortEnd}
                      images={this.state.selectedImages}
                    />
                  </div>

                  <div>
                    <SortableList
                      items={this.state.images}
                      product={this.state.product}
                      onSortEnd={this.onSortEnd}
                      lockAxis="x"
                      axis="x"
                    />
                  </div>

                  {this.state.showUpdateModal && (
                    <p className="text-success"> Product Updated </p>
                  )}

                  {this.state.showDeleteModal && (
                    <p className="text-success"> Product Deleted </p>
                  )}

                  <Row>
                    <Col xs="6">
                      <StandardButton
                        className=" btn-block mt-4 p-2 border-0"
                        loadingLabel="Please wait."
                        loading={this.props.adminProducts.deleteButtonLoading}
                        size="sm"
                        label="Save"
                        color="success"
                        style={{
                          borderRadius: 50,
                        }}
                        onClick={this.handleFormSubmit}
                      />
                    </Col>
                    <Col xs="6">
                      <StandardButton
                        className=" btn-block mt-4 p-2 border-0"
                        loadingLabel="Please wait."
                        loading={this.props.adminProducts.deleteButtonLoading}
                        size="sm"
                        label="Delete product"
                        color="danger"
                        style={{
                          borderRadius: 50,
                        }}
                        onClick={this.handleProductDeleteButton}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <StandardButton
                        className=" btn-block mt-4 p-2 border-0"
                        size="sm"
                        label="Go back to products"
                        style={{
                          backgroundColor: "whitesmoke",
                          color: "black",
                          borderRadius: 50,
                        }}
                        onClick={() =>
                          this.props.history.push("/adminpanel/products")
                        }
                      />
                    </Col>
                  </Row>
                </form>
              </Col>
            </Row>
          )}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, {
  getSingleProduct,
  UpdateStoreProduct,
  deleteProduct,
  resetForm,
  deleteProductImage,
  getCategories,
})(UpdateProduct);
