import {
    GET_LATEST_ORDERS,
    GET_LATEST_ORDERS_LOADING,
    GET_LATEST_ORDERS_LOADING_RESET,
    TODAY_ORDERS_UPDATE,
    AVERAGE_ORDER_SIZE,
    AVERAGE_ORDER_VALUE,
    GET_AVERAGE_PER_SHIPPING,
    GET_COUPON_STATS
} from '../actions/types.js'


const initialState = {
    loading: false,
    orders: [],
    averageOrderSize: 0,
    averageOrderValue: 0,
    shippingData: [],
    couponData: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_COUPON_STATS:
            return {
                ...state,
                couponData: action.payload
            }
        case GET_AVERAGE_PER_SHIPPING:
            return {
                ...state,
                shippingData: action.payload
            }
        case AVERAGE_ORDER_VALUE:
            return {
                ...state,
                averageOrderValue: action.payload
            }
        case AVERAGE_ORDER_SIZE:
            return {
                ...state,
                averageOrderSize: action.payload
            }
        case TODAY_ORDERS_UPDATE:
            return {
                ...state,
                orders: action.payload
            }
        case GET_LATEST_ORDERS:
            return {
                ...state,
                orders: action.payload
            }
        case GET_LATEST_ORDERS_LOADING:
            return {
                ...state,
                loading: true
            }

        case GET_LATEST_ORDERS_LOADING_RESET:
            return {
                ...state,
                loading: false
            }
        default: return state;
    }
}