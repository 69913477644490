import React, { Component } from "react";
const links = [
  {
    title: "Instagram",
    route: "https://www.instagram.com/remotetrails/",
    icon: "fab fa-instagram",
  },
  {
    title: "Facebook",
    route: "https://www.facebook.com/Remote-Trails-101665432111114/",
    icon: "fab fa-facebook-f",
  },
];

export default class SocialMedia extends Component {
  render() {
    return (
      <div>
        {links.map((link, index) => (
          <a
            href={link.route}
            key={index}
            className="px-3"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className={link.icon}></i>
          </a>
        ))}
      </div>
    );
  }
}
