import {
  SET_RATING,
  RESET_REVIEW_FORM,
  UPDATE_REVIEW,
  SET_REVIEW_IMAGES,
  REVIEW_ERRORS,
  REVIEW_SUBMITTED,
  REVIEW_SUBMITTED_LOADING,
} from "./types";
import axios from "axios";

export const newReview = (data) => (dispatch) => {
  dispatch({
    type: REVIEW_SUBMITTED_LOADING,
  });
  axios
    .post("/api/reviews/newReview", data)
    .then((res) => {
      dispatch({
        type: REVIEW_SUBMITTED,
      });
    })
    .catch((err) => {
      dispatch({
        type: REVIEW_ERRORS,
        payload: err.response.data,
      });
    });
};

export const setImages = (data) => (dispatch) => {
  dispatch({
    type: SET_REVIEW_IMAGES,
    payload: data,
  });
};

export const resetReviewForm = () => (dispatch) => {
  dispatch({
    type: RESET_REVIEW_FORM,
  });
};

export const setRating = (rating) => (dispatch) => {
  dispatch({
    type: SET_RATING,
    payload: rating,
  });
};

export const updateReview = (field, value) => (dispatch) => {
  dispatch({
    type: UPDATE_REVIEW,
    payload: { field, value },
  });
};
