import React from "react";
import TouchableOpacity from "./TouchableOpacity";
import { connect } from "react-redux";
import { setCollapseMenu } from "../actions/collapseMenu";
const HamburgerMenu = (props) => {
  return (
    <TouchableOpacity
      className="px-2 d-inline"
      onClick={() => props.setCollapseMenu(true)}
    >
      <i
        className="fa-solid fa-bars-staggered"
        style={{ fontSize: "17px" }}
      ></i>
    </TouchableOpacity>
  );
};

const mapStateToProps = (state) => ({
  collapseMenu: state.collapseMenu,
});

export default connect(mapStateToProps, { setCollapseMenu })(HamburgerMenu);
