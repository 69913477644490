import React, { Component } from 'react'
import {
    Input, InputGroup, Form,
} from 'reactstrap';
import { withRouter } from 'react-router-dom'


class SearchBar extends Component {
    state = {
        productSearch: '',
        searchModal: false,

    }

    handleSearch = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmit = e => {
        e.preventDefault()
        this.props.history.push(`/shop?productSearch=${this.state.productSearch}`)
        this.setState({
            productSearch: '',
            searchModal: false
        })
    }
    toggleModal = e => {
        this.setState({
            searchModal: !this.state.searchModal
        })
    }

    render() {
        return (
            <div className='container'>
                <div className='w-50 mx-auto'>
                    <Form onSubmit={this.handleSubmit} >
                        <InputGroup>
                            <Input className='search-bar' style={{ borderRadius: 30 }} placeholder="&#xF002; Search" name='productSearch' value={this.state.productSearch} onChange={this.handleSearch} />
                        </InputGroup>
                    </Form>
                </div>

            </div>
        )
    }
}
export default withRouter(SearchBar)