import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
import { StripeProvider } from "react-stripe-elements";
import {
  resetLocalStorageUser,
  logoutUser,
  resetFormReducer,
} from "../src/actions/formActions";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import FullPageSpinner from "./Components/FullPageSpinner";
import Routes from "./Routes";
import ScrollToTop from "./Components/ScrollToTop";
import { initializeTracking } from "./utils/analytics";

class App extends React.Component {
  state = {
    stripe: null,
  };
  componentDidMount() {
    const { auth } = this.props;

    if (auth.isAuthenticated) {
      initializeTracking(auth.user);
    } else {
      initializeTracking();
    }

    this.setState({
      stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY),
    });

    if (!this.props.auth.checkUser.hasOwnProperty("userName")) {
      this.props.resetFormReducer();
    }

    if (this.props.auth.checkUser.userName !== null) {
      if (!localStorage.jwtToken) {
        this.props.resetLocalStorageUser();
      }
    }
  }

  render() {
    return (
      <LoadingOverlay
        active={this.props.fullPageLoading.fullPageLoading}
        spinner={<FullPageSpinner />}
        classNamePrefix="fullPageLoading_"
      >
        <StripeProvider stripe={this.state.stripe}>
          <div className="App">
            <BrowserRouter>
              <ScrollToTop />
              <Routes />
            </BrowserRouter>
          </div>
        </StripeProvider>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => ({
  notfound: state.notfound,
  auth: state.form,
  fullPageLoading: state.fullPageLoading,
});

export default connect(mapStateToProps, {
  resetLocalStorageUser,
  logoutUser,
  resetFormReducer,
})(App);
