import React, { Component } from 'react'
import { Row, Col, Container } from 'reactstrap'
import Features from './Features';
import Subscribe from './Subscribe';
import NeedHelp from './NeedHelp';
import MoreInfo from './MoreInfo';
import Acknowledgement from './Acknowledgement';
import CopyrightBar from './CopyrightBar';
import { withRouter } from 'react-router'


class Footer extends Component {
    render() {

        let showFooter = true

        let pathnamesArray = this.props.history.location.pathname.split('/')

        if (pathnamesArray.includes('help-centre')) {
            showFooter = false
        }

        return (
            <footer className="page-footer font-small indigo  bg-light" >
                {showFooter &&
                    <React.Fragment>
                        <Features />

                        <Container>
                            <Row>
                                <Col sm='12' md='3' className='my-3'>
                                    <Acknowledgement />
                                </Col>
                                <Col sm='12' md='3' className='my-3'>
                                    <Subscribe />
                                </Col>
                                <Col sm='12' md='3' className='my-3'>
                                    <NeedHelp />
                                </Col>
                                <Col sm='12' md='3' className='my-3'>
                                    <MoreInfo />
                                </Col>

                            </Row>

                        </Container>


                    </React.Fragment>}



                <CopyrightBar />


            </footer>
        )
    }
}

export default withRouter(Footer)