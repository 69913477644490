import React, { Component } from "react";
import { Button } from "reactstrap";
import ButtonSpinner from "./ButtonSpinner";

export default class StandardButton extends Component {
  state = {
    touched: false,
  };

  toggleTouched = () => {
    this.setState((prevState) => ({
      touched: !prevState.touched,
    }));
  };

  handleMouseUp = () => {
    setTimeout(() => {
      this.setState({ touched: false });
    }, 150);
  };

  render() {
    const { handleClick, text, isLoading, style, size, disabled } = this.props;
    const { touched } = this.state;
    const className = touched
      ? "touchable touched  btn-block mt-4 py-3"
      : "touchable  btn-block mt-4 py-3";

    return (
      <div>
        <Button
          disabled={disabled}
          size={size}
          className={className}
          onClick={handleClick}
          style={style}
          onMouseDown={this.toggleTouched}
          onMouseUp={this.handleMouseUp}
        >
          {isLoading ? (
            <ButtonSpinner />
          ) : (
            <span style={{ fontWeight: 900 }}>{text}</span>
          )}
        </Button>
      </div>
    );
  }
}
