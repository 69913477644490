import React, { Component } from "react";

export default class BreadCrumbs extends Component {
  render() {
    return (
      <div>
        <div>
          <p className="mb-0 d-inline pr-2">
            <a
              href="/"
              style={{
                textDecoration: "underline",
                color: "black",
                fontSize: 14,
              }}
            >
              Remote Trails
            </a>
            {" >"}
          </p>
          {this.props.crumbs?.map((crumb, index) =>
            index !== this.props.crumbs.length - 1 ? (
              <p className="mb-0 d-inline pr-2">
                <a
                  href={crumb.route}
                  style={{
                    textDecoration: "underline",
                    color: "black",
                    fontSize: 14,
                  }}
                >
                  {crumb.name}
                </a>
                {" >"}
              </p>
            ) : (
              <p className="mb-0 d-inline pr-2">
                <a
                  href={crumb.route}
                  style={{
                    textDecoration: "underline",
                    color: "black",
                    fontSize: 14,
                  }}
                >
                  {crumb.name}
                </a>
              </p>
            )
          )}

          {this.props.title && (
            <h1 className="font-weight-bold" style={{ fontSize: "1.25rem" }}>
              {this.props.title}
            </h1>
          )}
        </div>
      </div>
    );
  }
}
