import React from "react";
import { Badge } from "reactstrap";
export default function DiscountSection({ coupon, discount, subTotal }) {
  return (
    <div>
      {coupon && Object.keys(coupon).length > 0 && (
        <React.Fragment>
          <p>
            Discount{" "}
            <span className="float-right">
              {coupon.isPercent ? "%" : "AU $"}
              {coupon.amount}
            </span>
            {subTotal < coupon.minimumSpend && (
              <React.Fragment>
                <br />
                <span className="text-danger" style={{ fontSize: 12 }}>
                  Minimum spend of AU ${coupon.minimumSpend} is required.
                  Otherwise discount won't be applied.
                </span>
              </React.Fragment>
            )}
          </p>
          {discount && (
            <p className="text-danger">
              Discount{" "}
              <h6 className="d-inline">
                <Badge color="danger">
                  <i className="fas fa-tags"></i> {coupon.code}
                </Badge>
              </h6>{" "}
              <span className="float-right text-danger">
                - AU ${discount.toFixed(2)}
              </span>
            </p>
          )}
        </React.Fragment>
      )}
    </div>
  );
}
