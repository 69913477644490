import React, { Component } from "react";
import { Row, Col } from "reactstrap";

import UsersByDevice from "./UsersByDevice.js";
import { Helmet } from "react-helmet";
import SalesGraph from "./SalesGraph";
import Map from "./Map.js";
import LatestOrders from "./LatestOrders.js";
import ListData from "./ListData.js";
import AverageOrderSize from "./AverageOrderSize.js";
import AverageOrderValue from "./AverageOrderValue.js";
import ShippingStats from "./ShippingStats.js";
import CouponData from "./CouponData.js";

export default class AdminDash extends Component {
  render() {
    return (
      <div className="pb-3">
        <div>
          <Helmet>
            <title>Admin Dashboard - Remote Trails</title>
            <meta
              name="description"
              content="Admin Dashboard - Remote Trails"
            />
          </Helmet>
        </div>

        {/* <Row>
          <Col lg="6" md="12">
            <CouponData />

            <AverageOrderSize />
          </Col>

          <Col lg="6" md="12">
            <ShippingStats />
            <AverageOrderValue />
          </Col>
        </Row> */}
        {/* <Row>
          <Col>
            <Map />
          </Col>
        </Row> */}

        {/* <Row>
          <Col lg="6" md="12">
            <SalesGraph />
          </Col>
          <Col lg="6" md="12">
            <UsersByDevice />
          </Col>
        </Row> */}
        {/* <ListData /> */}
        <Row>
          <Col>
            <LatestOrders />
          </Col>
        </Row>
      </div>
    );
  }
}
