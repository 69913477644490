import React, { Component } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { InputGroup, Input, Button } from "reactstrap";
import { loginUser, sendVerification } from "../actions/formActions";
import { resetSyncCart } from "../actions/shoppingCart";
import { removeGuestReducer } from "../actions/guestActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class LoginForm extends Component {
  state = {
    email: "",
    password: "",
    errors: {},
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    let cart;

    const userData = {
      email: this.state.email.toLowerCase(),
      password: this.state.password,
    };

    // cart = [...this.props.cart.cart]; // no sync for cart when logging in
    cart = [];
    userData.cart = cart;

    if (!this.props.cart.syncCart) {
      userData.sync = false;
    } else {
      userData.sync = true;
    }

    this.props.loginUser(userData);
  };
  componentDidUpdate(prevProps) {
    if (this.props.auth.isAuthenticated !== prevProps.auth.isAuthenticated) {
      this.props.resetSyncCart();
      this.props.removeGuestReducer();

      if (this.props.auth.user.isAdmin) {
        this.props.history.push("/adminpanel");
      } else {
        this.props.history.push("/dashboard");
      }
    }

    if (this.props.errors !== prevProps.errors) {
      this.setState({
        errors: this.props.errors,
      });
    }
  }

  sendVerification = (e) => {
    let data = {};
    data.email = this.state.email;
    if (this.state.email !== "") {
      this.props.sendVerification(data);
    }
  };

  render() {
    const { errors } = this.state;
    return (
      <div>
        {this.props.history.location.pathname !== "/order-status" && (
          <h1
            className="text-left font-weight-bold"
            style={{ color: "#3d4246", fontSize: 36 }}
          >
            Account
          </h1>
        )}
        <form onSubmit={this.onSubmit}>
          <InputGroup className="my-2">
            <Input
              placeholder="E-mail"
              onChange={this.onChange}
              className={classnames("", { "is-invalid": errors.email })}
              value={this.state.email}
              name="email"
            />
            <div className="invalid-feedback" style={{ fontSize: 12 }}>
              {errors.email}
            </div>
          </InputGroup>

          <InputGroup className="my-2">
            <Input
              type="password"
              placeholder="Password"
              onChange={this.onChange}
              className={classnames("", { "is-invalid": errors.password })}
              value={this.state.password}
              name="password"
              autoComplete="on"
            />
            <div className="invalid-feedback" style={{ fontSize: 12 }}>
              {errors.password}
            </div>
          </InputGroup>
          <div
            onClick={() => this.props.setFormType("reset-password")}
            style={{ cursor: "pointer" }}
          >
            <small className="float-left pb-2 text-muted">
              Forgot your password?
            </small>
          </div>
          <input
            className="w-100 py-2 border-0"
            disabled={
              this.state.email === "" || this.state.password === ""
                ? true
                : false
            }
            type="submit"
            value={this.props.auth.spinner ? "Please Wait.." : "Login"}
            style={{
              borderRadius: "20px",
              fontWeight: 700,
              background: "black",
              color: "white",
              cursor: "pointer",
            }}
          />
        </form>

        <div>
          <div>
            <h1
              className=" text-left mt-5 font-weight-bold"
              style={{ color: "#3d4246", fontSize: 24 }}
            >
              New Customer?
            </h1>
            <p style={{ fontSize: 14 }} className="text-muted">
              By creating an account with our store, you will be able to
              checkout faster, store multiple delivery addresses, view and track
              your orders in your account and more.
            </p>
          </div>
          <div>
            <Button
              className="w-100"
              onClick={() => this.props.setFormType("register")}
              style={{
                borderRadius: "20px",
                fontWeight: 700,
                background: "white",
                color: "black",
                cursor: "pointer",
                border: "2px solid",
              }}
            >
              {" "}
              Register
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  errors: state.errors,
  auth: state.form,
  cart: state.cart,
});

export default connect(mapStateToProps, {
  loginUser,
  resetSyncCart,
  removeGuestReducer,
  sendVerification,
})(withRouter(LoginForm));
