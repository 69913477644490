import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { getBestSellers } from '../actions/productActions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Header from './Header'
import ProductTile from './ProductTile'


const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 3000, min: 1210 },
        items: 4,
    },
    desktop: {
        breakpoint: { max: 1210, min: 1024 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    }
};



class BestSellers extends Component {

    state = {
        products: [],
    }

    componentDidMount() {

        let data = {}
        data.cart = this.props.cart.cart
        this.props.getBestSellers(data)
    }

    componentDidUpdate(prevProps) {
        if (this.props.products.bestSellers !== prevProps.products.bestSellers) {
            this.setState({
                products: this.props.products.bestSellers
            })
        }
    }

    routeToSpecificPage = (product) => {
        this.props.history.push({
            pathname: `/shop/${product.name.replace(/[^A-Z0-9]+/ig, "-")}/${product._id}`,
        })
    }
    render() {
        return (
            <div className='my-3'>
                <Row>
                    <Col>
                        <div className=' text-center'>
                            <Header header='Trending Gear' />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className=' text-center'>
                            <Carousel
                                swipeable={true}
                                showDots={true}
                                responsive={responsive}
                                ssr={true}
                                infinite={true}
                                keyBoardControl={true}
                                removeArrowOnDeviceType={["tablet", "mobile"]}
                                deviceType={this.props.deviceType}
                                className='pb-5'
                            >
                                {this.state.products ?.map((product, index) => (
                                  <ProductTile product={product} key={index} bestSellers />
                                ))}
                            </Carousel>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    products: state.products,
    cart: state.cart,
})
export default connect(mapStateToProps, { getBestSellers })(withRouter(BestSellers))
