import React, { Component } from "react";
import { Col, Row, Button } from "reactstrap";
import whoWeAre from "../images/who-we-are.jpeg";
import sustainability from "../images/cotton.jpg";
import community from "../images/community.jpg";

const tiles = [
  {
    title: "WHO WE ARE",
    image: whoWeAre,
    route: "/explore",
  },
  {
    title: "SUSTAINABILITY",
    image: sustainability,
    route: "/explore",
  },
  {
    title: "WE GIVE BACK FOR EVERY SALE",
    image: community,
    route: "/explore",
  },
];

class About extends Component {
  render() {
    return (
      <div className="my-3">
        <div className="mx-2 container mx-auto">
          <Row>
            <Col className="my-2">
              <Row>
                <Col lg="12">
                  <a href={tiles[0].route}>
                    <div
                      className=" h-100 shadow-lg position-relative row-tile overflow-hidden"
                      style={{ backgroundColor: "white", cursor: "pointer" }}
                    >
                      <img
                        src={tiles[0].image}
                        className="img-fluid sustainability-image w-100"
                        style={{
                          height: "100%",
                          overflow: "hidden",
                          objectFit: "cover",
                        }}
                      />
                      <div
                        className="about-overlay position-absolute h-100 w-100 "
                        style={{
                          backgroundColor: "rgba(0, 0, 0, 0.2)",
                          top: 0,
                        }}
                      />
                      <div
                        className="position-absolute "
                        style={{ top: 20, left: 20, right: 20 }}
                      >
                        <p
                          className="text-light d-inline"
                          style={{ fontWeight: 700, fontSize: 20 }}
                        >
                          {tiles[0].title}
                        </p>
                      </div>
                      <div
                        className="position-absolute "
                        style={{ bottom: 0, right: 0 }}
                      >
                        <Button
                          className="mx-3 mb-3  px-4 border-0  row-button "
                          size="sm"
                          style={{ fontSize: 12 }}
                        >
                          Discover
                        </Button>
                      </div>
                    </div>
                  </a>
                </Col>
                <Col lg="12" className="mt-2">
                  <a href={tiles[1].route}>
                    <div
                      className=" h-100 shadow-lg position-relative row-tile overflow-hidden "
                      style={{ backgroundColor: "white", cursor: "pointer" }}
                    >
                      <img
                        src={tiles[1].image}
                        className="img-fluid sustainability-image w-100"
                        style={{
                          height: "100%",
                          overflow: "hidden",
                          objectFit: "cover",
                        }}
                      />
                      <div
                        class="about-overlay position-absolute h-100 w-100 "
                        style={{
                          backgroundColor: "rgba(0, 0, 0, 0.3)",
                          top: 0,
                        }}
                      />
                      <div
                        className="position-absolute "
                        style={{ top: 20, left: 20, right: 20 }}
                      >
                        <p
                          className="text-light d-inline"
                          style={{ fontWeight: 700, fontSize: 20 }}
                        >
                          {tiles[1].title}
                        </p>
                      </div>
                      <div
                        className="position-absolute "
                        style={{ bottom: 0, right: 0 }}
                      >
                        <Button
                          className="mx-3 mb-3  px-4 border-0  row-button "
                          size="sm"
                          style={{ fontSize: 12 }}
                        >
                          Learn More
                        </Button>
                      </div>
                    </div>
                  </a>
                </Col>
              </Row>
            </Col>
            <Col lg="6" md="6" sm="12" className="my-2">
              <a href={tiles[2].route}>
                <div
                  className=" h-100 shadow-lg position-relative row-tile overflow-hidden "
                  style={{ backgroundColor: "white", cursor: "pointer" }}
                >
                  <img
                    src={tiles[2].image}
                    className="img-fluid sustainability-image w-100"
                    style={{
                      height: "100%",
                      overflow: "hidden",
                      objectFit: "cover",
                    }}
                  />
                  <div
                    class="about-overlay position-absolute h-100 w-100 "
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.3)", top: 0 }}
                  />
                  <div
                    className="position-absolute "
                    style={{ top: 20, left: 20, right: 20 }}
                  >
                    <p
                      className="text-light d-inline"
                      style={{ fontWeight: 700, fontSize: 20 }}
                    >
                      {tiles[2].title}
                    </p>
                  </div>
                  <div
                    className="position-absolute "
                    style={{ bottom: 0, right: 0 }}
                  >
                    <Button
                      className="mx-3 mb-3  px-4 border-0  row-button "
                      size="sm"
                      style={{ fontSize: 12 }}
                    >
                      Explore More
                    </Button>
                  </div>
                </div>
              </a>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default About;
