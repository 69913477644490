import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { Helmet } from "react-helmet";
import {
  updateShoppingCart,
  addTotalQuantityToCart,
} from "../actions/shoppingCart";
import {
  getSingleProduct,
  getYouMayAlsoLike,
  resetYouMayAlsoLike,
} from "../actions/productActions";
import Spinner from "../Components/Spinner";
import Reviews from "../Components/Reviews";
import { addToCartEvent, viewContent } from "../utils/analytics";
import lodash from "lodash";
import "react-multi-carousel/lib/styles.css";
import strings from "../utils/strings";
import ProductTile from "../Components/ProductTile";
import ProductTileHeader from "../Components/ProductTileHeader";
import StandardButton from "../Components/StandardButton";
import PageLinkCarousel from "../Components/PageLinkCarousel";
import PageCrumbs from "../Components/PageCrumbs";
import Variants from "./Variants";
import ProductFeatures from "./ProductFeatures";
import ProductShippingDescriptions from "./ProductShippingDescriptions";
import CareGuide from "./CareGuide";
import { pageView } from "../utils/analytics";

class PageLink extends Component {
  state = {
    width: 0,
    height: 0,
    quantity: 1,
    showModal: false,
    product: {},
    errors: {},
    theposition: null,
    stop: false,
    isModalOpen: false,
    variant: "",
  };

  adjustCart = () => {
    let { variant, product } = this.state;

    this.setState({ errors: {} });

    if (variant === "") {
      variant = product.variants[0]._id;
    } else {
      variant = variant._id;
    }

    addToCartEvent(product);

    let totalQuantity;

    if (this.props.cart.addToCartLoading.productId !== product._id) {
      let cart = [...this.props.cart.cart];

      if (!this.props.auth.isAuthenticated) {
        if (this.props.cart.cart.length !== 0) {
          if (
            cart.filter((val) => val.product._id === product._id).length !== 0
          ) {
            var index = cart.findIndex(
              (eachItem) =>
                eachItem.product._id === product._id &&
                variant === eachItem.variant
            );

            if (index === -1) {
              cart = [
                ...cart,
                {
                  product,
                  variant,
                  quantity: this.state.quantity,
                },
              ];
            } else {
              cart = cart.map((eachItem) => {
                if (
                  eachItem.product._id === product._id &&
                  variant === eachItem.variant
                ) {
                  return {
                    ...eachItem,
                    variant,
                    quantity: eachItem.quantity + this.state.quantity,
                  };
                } else {
                  return eachItem;
                }
              });
            }
          } else {
            cart = [
              ...cart,
              {
                product,
                variant,
                quantity: this.state.quantity,
              },
            ];
          }
        } else {
          cart = [
            ...cart,
            {
              product,
              variant,
              quantity: this.state.quantity,
            },
          ];
        }

        this.props.updateShoppingCart(cart, product, variant);
      }
      totalQuantity = this.state.quantity;
      if (this.props.auth.isAuthenticated) {
        if (Object.keys(this.state.errors).length > 0) {
          this.setState({
            errors: {},
          });
        }

        this.props.addTotalQuantityToCart(
          product,
          totalQuantity,
          this.props.auth.user.id,
          variant
        );
      } else if (this.props.guest.userid !== "") {
        if (Object.keys(this.state.errors).length > 0) {
          this.setState({
            errors: {},
          });
        }

        this.props.addTotalQuantityToCart(
          product,
          totalQuantity,
          this.props.guest.userid,
          variant
        );
      }
    }
    this.setState({
      quantity: 1,
    });
  };

  componentDidMount() {
    pageView();
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    if (this.props.location.state === undefined) {
      this.props.getSingleProduct(this.props.match.params.productid);
    }
    if (
      this.props.location.state !== undefined &&
      this.props.location.state.product
    ) {
      this.props.getYouMayAlsoLike(this.props.location.state.product);

      this.setState(
        {
          product: this.props.location.state.product,
          selectedImage:
            this.props.location.state.product.imageList[0].imageUrl,
        },
        () => {
          viewContent(this.state.product);
        }
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({
        product: {},
      });
      this.props.getSingleProduct(this.props.match.params.productid);
    }

    if (this.props.products.product !== prevProps.products.product) {
      this.props.getYouMayAlsoLike(this.props.products.product);

      this.setState({
        product: this.props.products.product,
        selectedImage: this.props.products.product.imageList[0].imageUrl,
      });
    }

    if (this.props.cart.errors !== prevProps.cart.errors) {
      this.setState({
        errors: this.props.cart.errors,
      });
    }
  }

  // scrollToMyRef = () => window.scrollTo(0, this.topRef.offsetTop)

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    this.props.resetYouMayAlsoLike();
  }
  updateWindowDimensions = (e) => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  handleProductRoute = (product) => {
    this.props.history.push({
      pathname: `/shop/${product.name.replace(/[^A-Z0-9]+/gi, "-")}/${
        product._id
      }`,
      // state: { product }
    });
  };

  getHelmet = () => {
    const { product } = this.state;

    if (!lodash.isEmpty(product)) {
      return (
        <Helmet>
          <title>{product.name} by Remote Trails</title>
          <meta
            name="description"
            content={`${product.description} by Remote Trails`}
          />
          <meta property="og:title" content={product.name} />
          <meta property="og:description" content={product.description} />
          <meta property="og:url" content={window.location.href} />
          {product.imageList.map((image) => (
            <meta
              key={image._id}
              property="og:image"
              content={image.imageUrl}
            />
          ))}
          <meta property="product:brand" content="Remote Trails" />
          <meta
            property="product:availability"
            content={product.stockLevel > 0 ? "in stock" : "out of stock"}
          />
          <meta property="product:condition" content="new" />
          <meta property="product:price:amount" content={product.price} />
          <meta property="product:price:currency" content="AUD" />
          <meta property="product:retailer_item_id" content={product._id} />
          <meta property="product:category" content="2975" />
        </Helmet>
      );
    }
  };

  render() {
    const { product, width } = this.state;

    return (
      <div
        className={`overflow-hidden ${this.state.width > 991 ? "p-5" : "px-2"}`}
      >
        <div>{this.getHelmet()}</div>
        <div className="text-center">
          {this.props.products.singleProductLoading && <Spinner />}
        </div>

        {lodash.size(product) > 0 && (
          <React.Fragment>
            <PageCrumbs
              crumbs={[
                { title: "Home", route: "/" },
                { title: "Shop", route: "/shop" },
                { title: this.props.match.params.title.replace(/-/g, " ") },
              ]}
            />

            <Row>
              <Col lg="8" className="text-center">
                <div>
                  <Row>
                    {product.imageList.map(
                      (eachImage) =>
                        width > 992 && (
                          <Col
                            key={eachImage._id}
                            className="px-1"
                            md={product.imageList.length > 1 ? "6" : "8"}
                          >
                            <img
                              src={eachImage.imageUrl}
                              alt={product.description}
                              className="img-fluid  my-1"
                            />
                          </Col>
                        )
                    )}
                    {width < 992 && <PageLinkCarousel product={product} />}
                  </Row>
                </div>
              </Col>
              <Col lg="4" className="position-relative mx-auto ">
                <div>
                  <h1>{product.name}</h1>
                  <h1
                    className="py-2 text-muted font-weight-bold"
                    style={{ fontSize: 15 }}
                  >
                    ${product.price.toFixed(2)}
                  </h1>

                  <div className="border-top  my-3 pt-3">
                    <p
                      style={{
                        whiteSpace: "pre-wrap",
                        fontSize: 14,
                      }}
                    >
                      {product.description}
                    </p>
                  </div>

                  {this.state.errors.stockLevel && (
                    <p className="text-danger">
                      {this.state.errors.stockLevel}
                    </p>
                  )}

                  <Variants
                    variants={product.variants}
                    selectedVariant={this.state.variant}
                    setVariant={(variant) => this.setState({ variant })}
                  />

                  <StandardButton
                    disabled={
                      product.variants.length > 1 && this.state.variant === ""
                    }
                    handleClick={() => this.adjustCart()}
                    isLoading={
                      this.props.cart.addToCartLoading.productId === product._id
                    }
                    text={
                      product.isBundle
                        ? strings.buttons.selectBundle
                        : strings.buttons.addToCartLabel
                    }
                    style={{
                      height: "60px",
                      backgroundColor: "#a36349",
                      borderRadius: "50px",
                      border: 0,
                    }}
                  />
                </div>

                <ProductFeatures />
                <ProductShippingDescriptions />
                <CareGuide />
              </Col>
            </Row>

            <Reviews product={product} />

            {lodash.size(this.props.products.youMayAlsoLike) > 0 && (
              <div>
                <div className="container">
                  <ProductTileHeader header="You May Also Like" />
                  <Row>
                    {this.state.width > 767
                      ? this.props.products.youMayAlsoLike
                          .slice(0, 3)
                          .map(
                            (product) =>
                              product.imageList.length !== 0 && (
                                <ProductTile
                                  key={product._id}
                                  product={product}
                                />
                              )
                          )
                      : this.props.products.youMayAlsoLike.map(
                          (product) =>
                            product.imageList.length !== 0 && (
                              <ProductTile
                                key={product._id}
                                product={product}
                              />
                            )
                        )}
                  </Row>
                </div>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  products: state.products,
  cart: state.cart,
  auth: state.form,
  guest: state.guest,
});

export default connect(mapStateToProps, {
  getSingleProduct,
  getYouMayAlsoLike,
  resetYouMayAlsoLike,
  updateShoppingCart,
  addTotalQuantityToCart,
})(PageLink);
