import {
    GET_ADMIN_COUPONS,
    GET_ADMIN_COUPONS_LOADING,
    GET_ADMIN_COUPONS_LOADING_RESET,
    GET_ADMIN_COUPONS_COUNT,
    CREATE_NEW_COUPON,
    CREATE_NEW_COUPON_LOADING,
    CREATE_NEW_COUPON_LOADING_RESET,
    CREATE_NEW_COUPON_ERRORS,
    RESET_CREATE_NEW_COUPON_FORM,
    DEACTIVATE_COUPON,
    DELETE_COUPON,
    RESET_COUPON_STATUS
} from './types'
import axios from 'axios'
import { logoutUser } from './formActions'


export const getAdminCoupons = (page) => dispatch => {
    if (page === 1) {
        dispatch({
            type: GET_ADMIN_COUPONS_LOADING
        })
    }
    axios.get(`/api/admin/getAdminCoupons/${page}`)
        .then(res => {
            dispatch({
                type: GET_ADMIN_COUPONS,
                payload: res.data.coupons
            })
            dispatch({
                type: GET_ADMIN_COUPONS_COUNT,
                payload: res.data.count
            })

            dispatch({
                type: GET_ADMIN_COUPONS_LOADING_RESET
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ADMIN_COUPONS_LOADING_RESET
            })
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }
        })

}

export const createNewCoupon = data => dispatch => {
    dispatch({
        type: CREATE_NEW_COUPON_LOADING
    })
    axios.post(`/api/admin/createNewCoupon`, data)
        .then(res => {
            dispatch({
                type: CREATE_NEW_COUPON,
            })
            dispatch({
                type: CREATE_NEW_COUPON_LOADING_RESET
            })
        })
        .catch(err => {
            dispatch({
                type: CREATE_NEW_COUPON_LOADING_RESET
            })
            dispatch({
                type: CREATE_NEW_COUPON_ERRORS,
                payload: err.response.data
            })
        })
}

export const activationStatus = (id, data) => dispatch => {
    axios.post(`/api/admin/deactivateCoupon/${id}`, data)
        .then(res => {
            dispatch({
                type: DEACTIVATE_COUPON,
                payload: res.data
            })
        })
        .catch(err => {
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }
        })
}


export const deleteCoupon = (id) => dispatch => {
    axios.post(`/api/admin/deleteCoupon/${id}`)
        .then(res => {
            dispatch({
                type: DELETE_COUPON,
                payload: res.data.coupon
            })
            dispatch({
                type: GET_ADMIN_COUPONS_COUNT,
                payload: res.data.count
            })
        })
        .catch(err => {
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }
        })
}

export const resetCouponStatus = () => dispatch => {
    dispatch({
        type: RESET_COUPON_STATUS
    })
}

export const resetNewCouponForm = () => dispatch => {
    dispatch({
        type: RESET_CREATE_NEW_COUPON_FORM
    })
}