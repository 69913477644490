import {
    GET_ADMIN_USER_LIST,
    ADMIN_USER_LIST_LOADING,
    ADMIN_USER_LIST_LOADING_RESET,
    GET_ADMIN_USER_LIST_COUNT,
    GET_SINGLE_USER,
    DELETE_SINGLE_USER,
    DELETE_SINGLE_USER_BUTTON_LOADING,
    DELETE_SINGLE_USER_BUTTON_LOADING_RESET,
    RESET_SINGLE_USER,
    UPDATE_SINGLE_USER,
    UPDATE_SINGLE_USER_LOADING,
    UPDATE_SINGLE_USER_LOADING_RESET,
    USER_TABLE_UPDATED,
    USER_COUNT_UPDATED
} from '../actions/types.js'


const initialState = {
    loading: false,
    users: [],
    userCount: 0,
    user: {},
    userDeleted: false,
    deleteButtonLoading: false,
    updateButtonLoading: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case USER_COUNT_UPDATED:
            return {
                ...state,
                userCount: action.payload
            }
        case USER_TABLE_UPDATED:
            return {
                ...state,
                users: action.payload
            }
        case RESET_SINGLE_USER:
            return {
                ...state,
                loading: false,
                users: [],
                userCount: 0,
                user: {},
                userDeleted: false,
                deleteButtonLoading: false,
                updateButtonLoading: false

            }
        case UPDATE_SINGLE_USER:
            return {
                ...state,
                user: action.payload
            }
        case DELETE_SINGLE_USER:
            return {
                ...state,
                userDeleted: true
            }
        case GET_SINGLE_USER:
            return {
                ...state,
                user: action.payload
            }
        case GET_ADMIN_USER_LIST:
            return {
                ...state,
                users: state.users.concat(action.payload)
            }
        case GET_ADMIN_USER_LIST_COUNT:
            return {
                ...state,
                userCount: action.payload
            }
        case ADMIN_USER_LIST_LOADING:
            return {
                ...state,
                loading: true
            }

        case ADMIN_USER_LIST_LOADING_RESET:
            return {
                ...state,
                loading: false
            }
        case DELETE_SINGLE_USER_BUTTON_LOADING:
            return {
                ...state,
                deleteButtonLoading: true
            }

        case DELETE_SINGLE_USER_BUTTON_LOADING_RESET:
            return {
                ...state,
                deleteButtonLoading: false
            }
        case UPDATE_SINGLE_USER_LOADING:
            return {
                ...state,
                updateButtonLoading: true
            }

        case UPDATE_SINGLE_USER_LOADING_RESET:
            return {
                ...state,
                updateButtonLoading: false
            }
        default: return state;
    }
}