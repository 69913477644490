import React, { Component } from "react";
import { Input, InputGroup, Label, FormGroup } from "reactstrap";
import classnames from "classnames";

//syntax
// inputGroups: [
//   {
//     title: "Title",
//     name: "name",
//     placeholder: "e.g. Christmas",
//     value: "",
//     type: "text",
//     options:[]
//   }
// ]

export default class InputField extends Component {
  render() {
    const { inputGroup, errors, onChange, saleActive } = this.props;
    return (
      <div>
        {inputGroup.type === "checkbox" ? (
          <InputGroup
            className="my-2 bg-light p-3"
            style={{ borderRadius: 10 }}
          >
            <FormGroup className="w-100 " check>
              <Input
                type="checkbox"
                onChange={onChange}
                checked={saleActive}
                name={inputGroup.name}
              />
              <Label check>{inputGroup.title}</Label>
            </FormGroup>
          </InputGroup>
        ) : (
          (!inputGroup.condition ||
            inputGroup.condition.saleActive === saleActive) && (
            <InputGroup
              className="my-2 bg-light p-3"
              style={{ borderRadius: 10 }}
            >
              <FormGroup className="w-100 ">
                <Label>{inputGroup.title} </Label>
                <Input
                  step={inputGroup.step}
                  type={inputGroup.type}
                  placeholder={inputGroup.placeholder}
                  onChange={onChange}
                  className={classnames("", {
                    "is-invalid": errors[inputGroup.name]
                  })}
                  value={inputGroup.value}
                  name={inputGroup.name}
                >
                  {inputGroup.options?.map((inputOption, index) => (
                    <option
                      key={index}
                      value={inputOption.value}
                      hidden={inputOption.hidden}
                    >
                      {inputOption.label}
                    </option>
                  ))}
                </Input>
                <div className="invalid-feedback">
                  {errors[inputGroup.name]}
                </div>
              </FormGroup>
            </InputGroup>
          )
        )}
      </div>
    );
  }
}
