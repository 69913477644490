import { combineReducers } from "redux";
import errorReducer from "./errorReducer";
import shoppingCart from "./shoppingCart";
import formReducer from "./formReducer";
import notFoundReducer from "./notFoundReducer";
import productsReducer from "./productReducer";
import checkOutReducer from "./checkOutReducer";
import orderReducer from "./orderReducer";
import guestReducer from "./guestReducer";
import discountReducer from "./discountReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import fullPageLoadingReducer from "./fullPageLoadingReducer";
import newsletterReducer from "./newsletterReducer";
import adminUserReducer from "./adminUserReducer";
import adminRevenueReducer from "./adminRevenueReducer";
import adminAnalyticsReducer from "./adminAnalyticsReducer";
import salesGraphReducer from "./salesGraphReducer";
// import recipeReducer from './recipeReducer'
import adminProductReducer from "./adminProductReducer";
// import adminRecipes from './adminRecipes'
import adminMapReducer from "./adminMapReducer";
import LatestOrders from "./LatestOrders";
import adminUserDash from "./adminUserDash";
import adminOrdersDash from "./adminOrdersDash";
import adminLists from "./adminLists";
import adminNewsletter from "./adminNewsletter";
import couponsReducer from "./couponsReducer";
import reviewReducer from "./reviewReducer";
import adminReviews from "./adminReviews";
import adminCategoryReducer from "./adminCategoryReducer";
import categoryReducer from "./categoryReducer";
import collapseMenu from "./collapseMenu";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [],
};

const authPersistConfig = {
  key: "cart",
  storage,
  blacklist: ["cartOpen", "addToCartLoading", "removeFromCartLoading"],
};

const authUserConfig = {
  key: "user",
  storage,
  blacklist: [
    "isAuthenticated",
    "user",
    "verified",
    "verifyloading",
    "showResetPasswordSuccess",
    "passwordUpdated",
    "resendLink",
    "spinner",
    "showRegistrationSuccess",
  ],
};

const authGuestConfig = {
  key: "guest",
  storage,
};

const rootReducer = combineReducers({
  fullPageLoading: fullPageLoadingReducer,
  checkout: checkOutReducer,
  errors: errorReducer,
  cart: persistReducer(authPersistConfig, shoppingCart),
  form: persistReducer(authUserConfig, formReducer),
  notfound: notFoundReducer,
  products: productsReducer,
  orders: orderReducer,
  discount: discountReducer,
  guest: persistReducer(authGuestConfig, guestReducer),
  newsletter: newsletterReducer,
  adminUserCalc: adminUserReducer,
  adminRevenueCalc: adminRevenueReducer,
  adminAnalytics: adminAnalyticsReducer,
  salesGraphReducer: salesGraphReducer,
  adminProducts: adminProductReducer,
  // adminRecipes: adminRecipes,
  map: adminMapReducer,
  latestOrders: LatestOrders,
  users: adminUserDash,
  adminOrders: adminOrdersDash,
  adminLists: adminLists,
  adminNewsletter: adminNewsletter,
  coupons: couponsReducer,
  reviews: reviewReducer,
  adminReviews: adminReviews,
  adminCategories: adminCategoryReducer,
  categories: categoryReducer,
  collapseMenu,

  // adminFaqs: adminFaqReducer
});

export default persistReducer(persistConfig, rootReducer);
