import React from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import outback from "../images/outbackAustralia.jpg";
import { NavbarBrand } from "reactstrap";
import logo from "../images/remote-trails-logo.png";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import ResetPasswordForm from "./ResetPasswordForm";

class LogIn extends React.Component {
  state = {
    formType: "login",
  };

  componentDidMount() {
    this.setState({
      windowWidth: window.innerWidth,
    });
    window.addEventListener("resize", this.updateWindowDimension);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimension);
  }
  updateWindowDimension = (e) => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };

  render() {
    if (localStorage.jwtToken) {
      return <Redirect to="/dashboard" />;
    }
    return (
      <div>
        <div>
          <Helmet>
            <title>Customer Login - Remote Trails</title>
            <meta name="description" content="Customer Login - Remote Trails" />
          </Helmet>
        </div>

        <div
          className="position-absolute "
          style={{ zIndex: 99999, cursor: "pointer" }}
        >
          <div
            onClick={() => this.props.history.push("/shop")}
            className="rounded-circle shadow-lg  mx-2 my-2 px-3 py-3 "
            style={{
              width: "3em",
              height: "3em",
              alignItems: "center",
              justifyContent: "center",
              display: "inline-flex",
              textAlign: "center",
              background: "white",
            }}
          >
            <i
              className="fas fa-long-arrow-alt-left"
              style={{ fontSize: "1.25em" }}
            ></i>
          </div>
        </div>

        <Row
          className="position-absolute w-100  text-center"
          style={{ zIndex: 999 }}
        >
          <Col lg="8" md="12">
            <NavbarBrand href="/">
              <img
                style={{ maxHeight: 60 }}
                className="img-fluid "
                src={logo}
                alt="Inspired by Australia's Natinal Wonders | Remote Trails Apparel + Gear"
              />{" "}
            </NavbarBrand>
          </Col>
        </Row>
        <Row style={{ height: "100vh" }}>
          <Col lg="8" className="d-none d-lg-block m-0 p-0">
            <div
              className=" h-100"
              style={{
                backgroundImage: `url(${outback})`,
                backgroundPosition: "center ",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            ></div>
          </Col>
          <Col className="p-0" style={{ backgroundColor: "snow" }}>
            <div className=" h-100">
              <div className="row my-auto  h-100 p-0 mx-5">
                <div className=" my-auto ">
                  {this.state.formType === "login" && (
                    <LoginForm
                      setFormType={(type) => this.setState({ formType: type })}
                    />
                  )}

                  {this.state.formType === "reset-password" && (
                    <ResetPasswordForm
                      setFormType={(type) => this.setState({ formType: type })}
                    />
                  )}

                  {this.state.formType === "register" && (
                    <RegisterForm
                      setFormType={() => this.setState({ formType: "login" })}
                    />
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  errors: state.errors,
  auth: state.form,
  cart: state.cart,
});

export default connect(mapStateToProps, {})(withRouter(LogIn));
