import { UPDATE_COLLAPSE_MENU } from "../actions/types.js";

const initialState = {
  collapseMenu: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_COLLAPSE_MENU:
      return {
        ...state,
        collapseMenu: action.payload,
      };
    default:
      return state;
  }
}
