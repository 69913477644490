import React from "react";

export default function CheckoutShippingLabel({ shippingFee }) {
  return (
    <p>
      Shipping
      <span
        className="float-right"
        style={{
          fontSize: shippingFee === null ? 12 : 16,
        }}
      >
        {shippingFee === null
          ? "Not yet calculated"
          : shippingFee === 0
          ? "Free"
          : "AU $" + shippingFee.toFixed(2)}
      </span>
    </p>
  );
}
