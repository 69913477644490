import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import ResetPasswordForm from "./ResetPasswordForm";

export default class OrderTracking extends Component {
  state = {
    errors: {},
    formType: "login",
  };

  componentDidMount() {
    this.updateWindowDimension();
    window.addEventListener("resize", this.updateWindowDimension);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimension);
  }

  updateWindowDimension = (e) => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };

  render() {
    const { errors } = this.state;

    return (
      <div className="container">
        <Row className="my-3">
          <Col>
            <div className=" my-5 text-center ">
              <h1 className="font-weight-bold py-3" style={{ fontSize: 24 }}>
                Sign In To View Your Order Status
              </h1>
              <div
                className="text-left"
                style={{
                  paddingLeft: this.state.windowWidth > 1000 && "20%",
                  paddingRight: this.state.windowWidth > 1000 && "20%",
                }}
              >
                <p style={{ fontSize: 14 }}>
                  Order tracking information can be found in the account section
                  while logged in. If you didn't make an account when making
                  your purchase, you can sign up using the email used while
                  ordering to access more information.
                </p>
                {this.state.formType === "login" && (
                  <LoginForm
                    setFormType={(type) => this.setState({ formType: type })}
                  />
                )}

                {this.state.formType === "reset-password" && (
                  <ResetPasswordForm
                    setFormType={(type) => this.setState({ formType: type })}
                  />
                )}

                {this.state.formType === "register" && (
                  <RegisterForm
                    setFormType={() => this.setState({ formType: "login" })}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
