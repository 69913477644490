import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import classnames from "classnames";
import {
  Row,
  Col,
  Input,
  InputGroup,
  Label,
  Button,
  Alert,
  FormGroup,
  InputGroupText,
  InputGroupAddon,
  UncontrolledTooltip,
} from "reactstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import {
  createNewCoupon,
  resetNewCouponForm,
  resetCouponStatus,
} from "../../actions/couponActions";

class NewCoupon extends Component {
  state = {
    code: "",
    isPercent: true,
    amount: "",
    minimumSpend: "",
    expireDate: "",
    errors: {},
    showSuccessAlert: false,
    isOneTime: true,
    categories: [],
    availableCategories: [
      { route: "solid", title: "Solid Colours" },
      { route: "nailart", title: "Patterned Nails" },
      { route: "overlay", title: "Overlay" },
      { route: "marble", title: "Marble Designs" },
      { route: "missfrankie", title: "Miss Frankie" },
      { route: "sale", title: "Sale" },
    ],
  };

  componentWillUnmount() {
    this.props.resetNewCouponForm();
  }

  componentDidUpdate(prevProps) {
    if (this.props.coupons.errors !== prevProps.coupons.errors) {
      this.setState({
        errors: this.props.coupons.errors,
      });
    }
    if (
      this.props.coupons.newCouponCreated !==
        prevProps.coupons.newCouponCreated &&
      this.props.coupons.newCouponCreated
    ) {
      this.setState({
        showSuccessAlert: true,
      });
    }
  }

  setDate = (expireDate) => {
    this.setState({
      expireDate,
    });
  };

  handleNewCode = (e) => {
    let data = {};

    data.code = this.state.code;
    data.isPercent = this.state.isPercent;
    data.amount = this.state.amount;
    data.expireDate = this.state.expireDate;
    data.isActive = true;
    data.minimumSpend =
      this.state.minimumSpend === "" ? 0 : this.state.minimumSpend;
    data.isOneTime = this.state.isOneTime;
    data.categories = this.state.categories;
    this.props.createNewCoupon(data);
  };

  handleRadioButton = (e) => {
    if (e.target.id === "fixedAmount") {
      this.setState({
        isPercent: false,
      });
    } else {
      this.setState({
        isPercent: true,
      });
    }
  };

  handleUsageRadioButton = (e) => {
    if (e.target.id === "unlimited") {
      this.setState({
        isOneTime: false,
      });
    } else {
      this.setState({
        isOneTime: true,
      });
    }
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      showSuccessAlert: this.state.showSuccessAlert && false,
    });

    if (this.props.coupons.newCouponCreated) {
      this.props.resetCouponStatus();
    }
  };

  handleCheckBox = (e) => {
    if (this.state.categories.includes(e.target.name)) {
      this.setState({
        categories: [
          ...this.state.categories.filter(
            (category) => category !== e.target.name
          ),
        ],
      });
    } else {
      this.setState({
        categories: [...this.state.categories, e.target.name],
      });
    }
  };

  render() {
    const { errors } = this.state;

    return (
      <div>
        <div>
          <Helmet>
            <title>New Coupon - Remote Trails</title>
            <meta name="description" content="New Coupon - Remote Trails" />
          </Helmet>
        </div>
        <Row>
          <Col>
            <form>
              <InputGroup
                className="my-2 bg-light p-3"
                style={{ borderRadius: 10 }}
              >
                <FormGroup className="w-100 ">
                  <Label>Discount Code </Label>
                  <Input
                    placeholder="e.g. SPRINGSALE"
                    onChange={this.onChange}
                    className={classnames("", { "is-invalid": errors.code })}
                    value={this.state.code}
                    name="code"
                  />
                  <div className="invalid-feedback">{errors.code}</div>
                </FormGroup>
              </InputGroup>

              <InputGroup
                className="my-2 bg-light p-3"
                style={{ borderRadius: 10 }}
              >
                <Label> Discount Type </Label>

                <FormGroup className="w-100" style={{ marginLeft: "1.25rem" }}>
                  <Label>
                    <Input
                      checked={this.state.isPercent}
                      type="radio"
                      name="radioButton"
                      id="percentage"
                      onChange={this.handleRadioButton}
                    />
                    Percentage
                  </Label>
                </FormGroup>

                <FormGroup className="w-100" style={{ marginLeft: "1.25rem" }}>
                  <Label check>
                    <Input
                      type="radio"
                      name="radioButton"
                      id="fixedAmount"
                      onChange={this.handleRadioButton}
                    />
                    Fixed amount
                  </Label>
                </FormGroup>
              </InputGroup>

              <InputGroup
                className="my-2 bg-light p-3"
                style={{ borderRadius: 10 }}
              >
                <Label> Discount Usage </Label>

                <FormGroup className="w-100" style={{ marginLeft: "1.25rem" }}>
                  <Label>
                    <Input
                      checked={this.state.isOneTime}
                      type="radio"
                      name="radioButton1"
                      id="oneTime"
                      onChange={this.handleUsageRadioButton}
                    />
                    One Time
                    <span id="ScheduleUpdateTooltip">
                      {" "}
                      <i className="fas fa-question-circle"></i>
                      <UncontrolledTooltip
                        placement="right"
                        target="ScheduleUpdateTooltip"
                      >
                        Deactivated after one use.
                      </UncontrolledTooltip>
                    </span>
                  </Label>
                </FormGroup>

                <FormGroup className="w-100" style={{ marginLeft: "1.25rem" }}>
                  <Label check>
                    <Input
                      type="radio"
                      name="radioButton1"
                      id="unlimited"
                      onChange={this.handleUsageRadioButton}
                    />
                    Unlimited
                    <span id="unlimitedToolTip">
                      {" "}
                      <i className="fas fa-question-circle"></i>
                      <UncontrolledTooltip
                        placement="right"
                        target="unlimitedToolTip"
                      >
                        Can be used unlimited times.
                      </UncontrolledTooltip>
                    </span>
                  </Label>
                </FormGroup>
              </InputGroup>

              <InputGroup
                className="my-2 bg-light p-3"
                style={{ borderRadius: 10 }}
              >
                <Label>
                  {" "}
                  Make coupons available in (leave blank for all items)
                </Label>

                <FormGroup check className="w-100">
                  {this.state.availableCategories.map((category) => (
                    <>
                      <Input
                        type="checkbox"
                        checked={this.state.categories.includes(category.route)}
                        name={category.route}
                        onChange={this.handleCheckBox}
                      />
                      <Label check>{category.title}</Label>
                      <br />
                    </>
                  ))}
                </FormGroup>
              </InputGroup>

              <FormGroup
                className="my-2 bg-light p-3"
                style={{ borderRadius: 10 }}
              >
                <Label>Discount Value </Label>

                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      {this.state.isPercent ? "%" : "$"}
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="e.g. 10"
                    onChange={this.onChange}
                    className={classnames("", { "is-invalid": errors.amount })}
                    value={this.state.amount}
                    name="amount"
                  />

                  <div className="invalid-feedback">{errors.amount}</div>
                </InputGroup>
              </FormGroup>
              <InputGroup
                className="my-2 bg-light p-3"
                style={{ borderRadius: 10 }}
              >
                <FormGroup className="w-100 ">
                  <Label>Minimum Spend Value </Label>
                  <Input
                    placeholder="0 if not entered"
                    onChange={this.onChange}
                    className={classnames("", {
                      "is-invalid": errors.minimumSpend,
                    })}
                    value={this.state.minimumSpend}
                    name="minimumSpend"
                  />
                  <div className="invalid-feedback">{errors.minimumSpend}</div>
                </FormGroup>
              </InputGroup>
            </form>

            <div className="my-2 bg-light p-3" style={{ borderRadius: 10 }}>
              <Label> Expiry Date </Label>
              <br />
              <DatePicker
                selected={this.state.expireDate}
                onChange={this.setDate}
                placeholderText="Select a day"
                minDate={moment().toDate()}
                // excludeDates={time >= 14 && [new Date()]}
                excludeDates={[new Date()]}
              />
              {this.state.errors.expireDate && (
                <div className="text-danger" style={{ fontSize: "80%" }}>
                  {errors.expireDate}
                </div>
              )}
            </div>
          </Col>
        </Row>
        {this.state.showSuccessAlert && (
          <Row>
            <Col>
              <div>
                <Alert color="success">
                  Coupon succesfully created.{" "}
                  <span
                    onClick={() =>
                      this.props.history.push("/adminpanel/coupons")
                    }
                    style={{ color: "rgb(235,24,78)", cursor: "pointer" }}
                  >
                    Click here
                  </span>{" "}
                  to go back to the Coupons Dashboard.
                </Alert>
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <Button color="success" size="sm" onClick={this.handleNewCode}>
              {" "}
              Create
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  coupons: state.coupons,
});

export default connect(mapStateToProps, {
  createNewCoupon,
  resetNewCouponForm,
  resetCouponStatus,
})(NewCoupon);
