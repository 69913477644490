import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import queryString from "query-string";
import { getOrderList, getNewOrderList } from "../../actions/adminOrdersDash";
import {
  Row,
  Col,
  Form,
  Input,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Button,
  Badge,
} from "reactstrap";
import moment from "moment";
import Orders from "./Orders";

class OrdersDashboard extends Component {
  state = {
    paymentTypeDropdown: false,
    shippingTypeDropdown: false,
    dateDropdown: false,
    orderStatusDropdown: false,
    page: 1,
    queryInput: "",
  };
  setFilter = (value) => {
    const parsed = queryString.parse(this.props.location.search);

    parsed.filter = value;
    let stringified = queryString.stringify(parsed);
    this.props.history.push(`/adminpanel/orders?${stringified}`);
  };

  shippingType = (value) => {
    const parsed = queryString.parse(this.props.location.search);

    if (value !== "") {
      parsed.shippingType = value;
    } else {
      parsed.shippingType = undefined;
    }

    let stringified = queryString.stringify(parsed);
    this.props.history.push(`/adminpanel/orders?${stringified}`);
  };

  paymentType = (value) => {
    const parsed = queryString.parse(this.props.location.search);

    if (value !== "") {
      parsed.paymentType = value;
    } else {
      parsed.paymentType = undefined;
    }

    let stringified = queryString.stringify(parsed);
    this.props.history.push(`/adminpanel/orders?${stringified}`);
  };

  orderStatus = (value) => {
    const parsed = queryString.parse(this.props.location.search);

    if (value !== "") {
      parsed.orderStatus = value;
    } else {
      parsed.orderStatus = undefined;
    }

    let stringified = queryString.stringify(parsed);
    this.props.history.push(`/adminpanel/orders?${stringified}`);
  };

  handleReset = (e) => {
    const parsed = queryString.parse(this.props.location.search);

    parsed.query = undefined;
    parsed.orderStatus = undefined;
    parsed.filter = undefined;
    parsed.paymentType = undefined;
    parsed.shippingType = undefined;

    let stringified = queryString.stringify(parsed);
    this.props.history.push(`/adminpanel/orders?${stringified}`);
  };

  paymentTypeDropdownToggle = (e) => {
    this.setState({
      paymentTypeDropdown: !this.state.paymentTypeDropdown,
    });
  };
  shippingTypeDropdownToggle = (e) => {
    this.setState({
      shippingTypeDropdown: !this.state.shippingTypeDropdown,
    });
  };
  dateDropdownToggle = (e) => {
    this.setState({
      dateDropdown: !this.state.dateDropdown,
    });
  };
  orderStatusDropdownToggle = (e) => {
    this.setState({
      orderStatusDropdown: !this.state.orderStatusDropdown,
    });
  };

  componentDidMount() {
    const parsed = queryString.parse(this.props.location.search);

    let page = this.state.page;
    let filter;
    let query;
    let orderStatus;
    let paymentType;
    let shippingType;

    if (parsed.shippingType) {
      shippingType = parsed.shippingType;
    }

    if (parsed.paymentType) {
      paymentType = parsed.paymentType;
    }

    if (parsed.orderStatus) {
      orderStatus = parsed.orderStatus;
    }

    if (parsed.filter) {
      filter = parsed.filter;
    }

    if (parsed.query) {
      query = parsed.query;
    }

    this.props.getOrderList(
      page,
      filter,
      query,
      orderStatus,
      paymentType,
      shippingType
    );
  }

  loadMore = (e) => {
    const parsed = queryString.parse(this.props.location.search);

    let page = this.state.page + 1;
    let filter;
    let query;
    let orderStatus;
    let paymentType;
    let shippingType;

    if (parsed.paymentType) {
      paymentType = parsed.paymentType;
    }

    if (parsed.orderStatus) {
      orderStatus = parsed.orderStatus;
    }
    if (parsed.shippingType) {
      shippingType = parsed.shippingType;
    }

    if (parsed.filter) {
      filter = parsed.filter;
    }

    if (parsed.query) {
      query = parsed.query;
    }

    this.setState({
      page,
    });

    this.props.getOrderList(
      page,
      filter,
      query,
      orderStatus,
      paymentType,
      shippingType
    );
  };

  handleInputChange = (e) => {
    this.setState({
      queryInput: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      page: 1,
    });
    const parsed = queryString.parse(this.props.location.search);
    parsed.query = this.state.queryInput;
    let stringified = queryString.stringify(parsed);
    this.props.history.push(`/adminpanel/orders?${stringified}`);
  };

  componentDidUpdate(prevProps, prevState) {
    const parsed = queryString.parse(this.props.location.search);

    if (this.props.location.search !== prevProps.location.search) {
      let page = this.state.page;
      let filter;
      let query;
      let orderStatus;
      let paymentType;
      let shippingType;

      if (parsed.shippingType) {
        shippingType = parsed.shippingType;
      }

      if (parsed.paymentType) {
        paymentType = parsed.paymentType;
      }

      if (parsed.orderStatus) {
        orderStatus = parsed.orderStatus;
      }

      if (parsed.filter) {
        filter = parsed.filter;
      }

      if (parsed.query) {
        query = parsed.query;
      }

      this.props.getNewOrderList(
        page,
        filter,
        query,
        orderStatus,
        paymentType,
        shippingType
      );
    }
  }

  ucFirstAllWords = (str) => {
    var pieces = str.split(" ");
    for (var i = 0; i < pieces.length; i++) {
      var j = pieces[i].charAt(0).toUpperCase();
      pieces[i] = j + pieces[i].substr(1);
    }
    return pieces.join(" ");
  };

  routeToOrder = (order) => {
    this.props.history.push({
      pathname: `/adminpanel/orders/${order._id}`,
      state: { order },
    });
  };

  render() {
    const parsed = queryString.parse(this.props.location.search);
    return (
      <div>
        <div>
          <Helmet>
            <title>Orders - Remote Trails</title>
            <meta name="description" content="Orders - Remote Trails" />
          </Helmet>
        </div>

        <div>
          <Row className="my-3">
            <Col>
              <Form onSubmit={this.handleSubmit}>
                <Input
                  placeholder="Search Order"
                  value={this.state.queryInput}
                  onChange={this.handleInputChange}
                />
              </Form>
            </Col>
          </Row>
        </div>
        <div>
          {parsed.query && (
            <h5
              className="d-inline pr-3"
              style={{ cursor: "pointer" }}
              onClick={this.handleReset}
            >
              {" "}
              <Badge color="danger">
                {parsed.query}
                <span className="pl-2" style={{ fontSize: "1.2rem" }}>
                  &times;
                </span>
              </Badge>
            </h5>
          )}
        </div>

        <React.Fragment>
          <div className="mt-2">
            <h4>Filter by</h4>

            <div className="my-3">
              <Dropdown
                className="btn-sm  ml-auto d-inline border-0"
                isOpen={this.state.dateDropdown}
                toggle={this.dateDropdownToggle}
              >
                <DropdownToggle caret className=" bg-light text-dark">
                  {(this.state.filter === "newest" && "Newest") ||
                    (this.state.filter === "oldest" && "Oldest") ||
                    "Date"}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => this.setFilter("newest")}>
                    Newest
                  </DropdownItem>
                  <DropdownItem onClick={() => this.setFilter("oldest")}>
                    Oldest
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>

              <Dropdown
                className="btn-sm  ml-auto d-inline border-0"
                isOpen={this.state.orderStatusDropdown}
                toggle={this.orderStatusDropdownToggle}
              >
                <DropdownToggle caret className=" bg-light text-dark">
                  {(this.state.orderStatus === "Confirmed" && "Confirmed ") ||
                    (this.state.orderStatus === "Processing" && "Processing") ||
                    (this.state.orderStatus === "Cancelled" && "Cancelled") ||
                    "Order Status"}
                </DropdownToggle>
                <DropdownMenu>
                  {this.state.orderStatus !== "" && (
                    <DropdownItem onClick={() => this.orderStatus("")}>
                      All
                    </DropdownItem>
                  )}
                  <DropdownItem onClick={() => this.orderStatus("Confirmed")}>
                    Confirmed
                  </DropdownItem>
                  <DropdownItem onClick={() => this.orderStatus("Processing")}>
                    Processing
                  </DropdownItem>
                  <DropdownItem onClick={() => this.orderStatus("Cancelled")}>
                    Cancelled
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>

              <Dropdown
                className="btn-sm  ml-auto d-inline border-0"
                isOpen={this.state.paymentTypeDropdown}
                toggle={this.paymentTypeDropdownToggle}
              >
                <DropdownToggle caret className=" bg-light text-dark">
                  {(this.state.paymentType === "stripe" && "Stripe") ||
                    (this.state.paymentType === "paypal" && "Paypal") ||
                    "Payment Type"}
                </DropdownToggle>
                <DropdownMenu>
                  {this.state.paymentType !== "" && (
                    <DropdownItem onClick={() => this.paymentType("")}>
                      All
                    </DropdownItem>
                  )}
                  <DropdownItem onClick={() => this.paymentType("stripe")}>
                    Stripe
                  </DropdownItem>
                  <DropdownItem onClick={() => this.paymentType("paypal")}>
                    Paypal
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <Button color="light" className="ml-2" onClick={this.handleReset}>
                Reset
              </Button>
            </div>
          </div>
          <div>
            {this.props.adminOrders.orders.length !== 0 && (
              <h1 className="float-right " style={{ fontSize: "0.9rem" }}>
                {this.props.adminOrders.orderCount} results
              </h1>
            )}
          </div>
          <div className="bg-light mt-5" style={{ borderRadius: 10 }}>
            <Row className="w-100">
              <Col>
                <Orders
                  orders={this.props.adminOrders.orders}
                  showCustomerData={true}
                />
              </Col>
            </Row>
          </div>
        </React.Fragment>

        <Row>
          <Col className="text-center my-3">
            {this.props.adminOrders.orderCount >
              this.props.adminOrders.orders.length && (
              <Button
                onClick={this.loadMore}
                outline
                color="dark"
                disabled={this.props.adminOrders.loading}
              >
                {this.props.adminOrders.loading ? "Loading" : "Load More"}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  adminOrders: state.adminOrders,
});

export default connect(mapStateToProps, { getOrderList, getNewOrderList })(
  OrdersDashboard
);
