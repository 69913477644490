import React from "react";
import Warnings from "./Warnings";
import ProgressBar from "./ProgressBar";
import TotalLabel from "./TotalLabel";
import CheckoutShippingLabel from "./CheckoutShippingLabel";
import SubTotalLabel from "./SubTotalLabel";
import DiscountSection from "./DiscountSection";
import { Col } from "reactstrap";
import EachProductList from "./EachProductList";
import { getCartTotal } from "../utils/getCartTotal";

export default function CheckoutRightColumn({
  total,
  subTotal,
  discount,
  cart,
  shippingFee,
  coupon,
}) {
  let cartTotal = getCartTotal(cart) || 0;

  return (
    <Col lg={5} className="d-none d-lg-block " style={{ minHeight: "100vh" }}>
      {cart.map((product) => (
        <EachProductList key={product._id} product={product} />
      ))}
      <Warnings />
      <div className="border-bottom pt-3">
        <SubTotalLabel total={subTotal} />
        <CheckoutShippingLabel shippingFee={shippingFee} />
        <DiscountSection
          coupon={coupon}
          discount={discount}
          subTotal={subTotal}
        />
      </div>

      <TotalLabel total={total} />
      <ProgressBar total={cartTotal} />
    </Col>
  );
}
