import React, { useCallback, Component } from 'react'
import { useDropzone } from 'react-dropzone';


function ImageDropZone(props) {
    const onDrop = useCallback((acceptedFiles) => {
        props.onFileSelect(acceptedFiles)

        acceptedFiles.forEach((file) => {
            const reader = new FileReader()

            reader.onload = () => {
                const binaryStr = reader.result
                // props.previews(binaryStr)
            }
            reader.readAsArrayBuffer(file)
        })
    }, [])
    const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/jpeg, image/png' })

    return (
        <div className='border text-center ' style={{ backgroundColor: 'snow', color: 'silver', borderStyle: 'dashed !important', cursor: 'pointer' }} {...getRootProps()}>
            <input {...getInputProps()} />
            <p className='my-0 py-5'>Drag 'n' drop product images here, or click to select files
            <br />
                (Only *.jpeg and *.png images will be accepted)
            </p>
        </div>
    )
}

class OuterDropzone extends Component {
    state = {
        images: []
    }

    componentDidMount() {
        this.setState({
            images: this.props.images
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.images !== prevProps.images) {
            this.setState({
                images: this.props.images
            })
        }
    }

    render() {
        return (
            <div >
                <div>
                    <div>
                        <ImageDropZone images={this.state.images} onFileSelect={this.props.onFileSelect} />
                    </div>
                </div>
            </div>
        );
    }

}

export default OuterDropzone