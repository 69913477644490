import React, { Component } from "react";
import moment from "moment-timezone";
import { withRouter } from "react-router-dom";
import { Badge } from "reactstrap";

class Orders extends Component {
  routeToOrder = (order) => {
    this.props.history.push({
      pathname: `/adminpanel/orders/${order._id}`,
    });
  };
  ucFirstAllWords = (str) => {
    var pieces = str.split(" ");
    for (var i = 0; i < pieces.length; i++) {
      var j = pieces[i].charAt(0).toUpperCase();
      pieces[i] = j + pieces[i].substr(1);
    }
    return pieces.join(" ");
  };

  render() {
    return (
      <div>
        {this.props.title && (
          <h6 className="font-weight-bold text-secondary">
            {this.props.title}
          </h6>
        )}

        <div className="table-responsive">
          <table className="table">
            <thead className="table-borderless">
              <tr>
                <th scope="col">EMAIL</th>
                <th scope="col">TIME</th>
                <th scope="col">TOTAL</th>
                <th scope="col">SHIPPING</th>
                <th scope="col">PAYMENT TYPE</th>
                {this.props.showCustomerData && (
                  <th scope="col">CUSTOMER TYPE</th>
                )}
                <th scope="col">ORDER STATUS</th>
              </tr>
            </thead>

            <tbody>
              {this.props.orders?.map((item) => (
                <tr
                  onClick={(e) => this.routeToOrder(item)}
                  className="admin-each-product"
                  key={item._id}
                  style={{ cursor: "pointer" }}
                >
                  <td
                    style={{ verticalAlign: "middle" }}
                    className="font-weight-bold"
                  >
                    {item.email}
                  </td>
                  <td
                    style={{ verticalAlign: "middle" }}
                    className="font-weight-bold"
                  >
                    {moment(item.created_at).format("Do MMMM YYYY, h:mm:ss a")}
                  </td>
                  <td
                    style={{ verticalAlign: "middle" }}
                    className="font-weight-bold"
                  >
                    {" "}
                    AUD {(item.total / 100).toFixed(2)}
                  </td>
                  <td
                    style={{ verticalAlign: "middle" }}
                    className="font-weight-bold"
                  >
                    <h5>
                      <Badge
                        color={
                          item.shippingType === "expressShipping"
                            ? "danger"
                            : "secondary"
                        }
                      >
                        {item.shippingType === "standardShipping"
                          ? "Standard"
                          : item.shippingType === "freeExpressShipping"
                          ? "Free Shipping"
                          : item.shippingType === "expressShipping"
                          ? "Express"
                          : item.shippingType === "internationalShipping" &&
                            "International Shipping"}
                      </Badge>
                    </h5>
                  </td>
                  <td
                    style={{ verticalAlign: "middle" }}
                    className="font-weight-bold"
                  >
                    {" "}
                    {this.ucFirstAllWords(item.paymentType)}
                  </td>
                  {item.user
                    ? this.props.showCustomerData && (
                        <td
                          style={{ verticalAlign: "middle" }}
                          className="font-weight-bold"
                        >
                          <h5>
                            <Badge
                              color={
                                item.user.orders.length === 1 ||
                                item.user.orders.length == 0
                                  ? "secondary"
                                  : item.user.orders.length > 5
                                  ? "success"
                                  : "primary"
                              }
                            >
                              {item.user.orders.length === 1 ||
                              item.user.orders.length == 0
                                ? "New Customer"
                                : item.user.orders.length > 5
                                ? "Loyal Customer"
                                : "Returning Customer"}
                            </Badge>
                          </h5>{" "}
                        </td>
                      )
                    : this.props.showCustomerData && (
                        <td
                          style={{ verticalAlign: "middle" }}
                          className="font-weight-bold"
                        >
                          {" "}
                          <h5>
                            <Badge color="danger">Not Enough Data</Badge>
                          </h5>
                        </td>
                      )}
                  <td
                    style={{ verticalAlign: "middle" }}
                    className="font-weight-bold"
                  >
                    <h5>
                      <Badge
                        color={
                          item.orderStatus === "Processing"
                            ? "primary"
                            : item.orderStatus === "Confirmed" && "success"
                        }
                      >
                        {item.orderStatus}
                      </Badge>
                    </h5>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
export default withRouter(Orders);
