import React from "react";

const links = [
  {
    title: "Instagram",
    route: "https://www.instagram.com/remotetrails/",
    icon: "fab fa-instagram",
  },
  {
    title: "Facebook",
    route: "https://www.facebook.com/Remote-Trails-101665432111114/",
    icon: "fab fa-facebook-f",
  },
];

const SocialIcons = () => {
  return (
    <div className="py-3">
      {links.map((link) => (
        <a
          key={link.title}
          className="px-4"
          href={link.route}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "grey" }}
        >
          <i className={link.icon}></i>
        </a>
      ))}
    </div>
  );
};

export default SocialIcons;
