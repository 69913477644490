import React, { Component } from "react";
import { Button } from "reactstrap";

export default class StandardButton extends Component {
  render() {
    const {
      size,
      color,
      label,
      onClick,
      loading,
      loadingLabel = "Please wait..",
      disable,
      style,
      className
    } = this.props;

    return (
      <div>
        <Button
          className={className}
          style={style}
          size={size}
          color={color}
          onClick={onClick}
          disabled={disable}
        >
          {loading ? loadingLabel : label}
        </Button>
      </div>
    );
  }
}
