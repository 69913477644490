import { SUBSCRIBE_NEWSLETTER } from '../actions/types'


const initialState = {
    subscribed: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SUBSCRIBE_NEWSLETTER:
            return {
                ...state,
                subscribed: true
            }
        default: return state;
    }
}