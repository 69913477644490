import {
    SUBSCRIBE_NEWSLETTER,
    RESET_CART_SYNC,
    GET_GUEST_USER,
    GUEST_DATA_SAVED,
    FORM_VALIDATION_ERROR,
} from './types'
import axios from 'axios'


export const subscribe = (data) => dispatch => {
    axios.post('/api/guestUsers/newsletter', data)
        .then(res => {
            dispatch({
                type: SUBSCRIBE_NEWSLETTER
            })
            dispatch({
                type: RESET_CART_SYNC
            })
            dispatch({
                type: GET_GUEST_USER,
                payload: res.data.user
            })

            dispatch({
                type: GUEST_DATA_SAVED,
            })
        })
        .catch(err => {


            dispatch({
                type: FORM_VALIDATION_ERROR,
                payload: err.response.data
            }
            )
        })
}
