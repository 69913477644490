import React, { Component } from "react";
import { connect } from "react-redux";
import { toggleCart } from "../actions/shoppingCart";
import { Drawer } from "@material-ui/core";
import { Row, Col, Button } from "reactstrap";
import { updateShoppingCart, removeFromCart } from "../actions/shoppingCart";
import { withRouter } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import { getCartTotal } from "../utils/getCartTotal";
import SizeLabel from "./SizeLabel";
import ProgressBar from "./ProgressBar";
import { initiateCheckout } from "../utils/analytics";

class SideBar extends Component {
  state = { width: 0, height: 0 };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = (e) => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  closeCart = (e) => {
    this.props.toggleCart();
  };
  routeToCart = (e) => {
    this.props.history.push("/cart");
    this.closeCart();
  };

  removeItem = (item) => {
    const product = item.product;
    const variant = item.variant;

    if (!this.props.auth.isAuthenticated && this.props.guest.userid === "") {
      let cart = [...this.props.shoppingCart.cart];

      let index = product.variants.findIndex((item) => item._id === variant);

      if (index !== -1) {
        cart = cart.filter(
          (eachItem) => eachItem.variant !== product.variants[index]._id
        );
      }

      this.props.updateShoppingCart(cart);
    }

    if (this.props.guest.userid !== "") {
      this.props.removeFromCart(product, this.props.guest.userid, variant);
    }
    if (this.props.auth.isAuthenticated) {
      this.props.removeFromCart(product, this.props.auth.user.id, variant);
    }
  };

  routeToSpecificPage = (product) => {
    this.props.history.push({
      pathname: `/shop/${product.name.replace(/[^A-Z0-9]+/gi, "-")}/${
        product._id
      }`,
      state: { product },
    });
    this.props.toggleCart();
  };

  render() {
    let cartTotal = getCartTotal(this.props.shoppingCart.cart) || 0;

    return (
      <Drawer
        anchor="right"
        open={this.props.shoppingCart.cartOpen === true}
        onClose={this.closeCart}
      >
        <div style={{ width: "300px", lineHeight: 1 }} role="presentation">
          <div
            className=" text-light font-weight-bold  "
            style={{ height: "40px", backgroundColor: "#a36349" }}
          >
            <h5
              className="text-left pl-2 d-inline"
              style={{ fontSize: "30px" }}
            >
              Shopping Cart
            </h5>
            <span
              className="float-right close-btn mr-3"
              onClick={this.closeCart}
            >
              &times;
            </span>
          </div>

          {this.props.shoppingCart.cart.length === 0 ? (
            <div className="px-3 py-3" style={{ fontSize: 20 }}>
              You have no items in your shopping cart.
            </div>
          ) : (
            <React.Fragment>
              <div
                className="side-drawer position-absolute"
                style={{
                  overflowY: "scroll",
                  overflowX: "hidden",
                  height: this.state.height - 240,
                }}
              >
                {this.props.shoppingCart.cart.map((product) => (
                  <div key={product._id}>
                    <LoadingOverlay
                      active={
                        this.props.shoppingCart.removeFromCartLoading
                          .productId === product.product._id
                      }
                      spinner
                    >
                      <Row className="px-2 py-3 border-bottom ">
                        <Col xs="3">
                          <img
                            src={product.product.imageList[0].imageUrl}
                            alt={product.product.name}
                            className="img-fluid"
                            style={{ lineHeight: 0 }}
                          />
                        </Col>
                        <Col xs="9">
                          <span
                            className=" item-delete-button text-dark float-right"
                            onClick={() => this.removeItem(product)}
                          >
                            &times;
                          </span>
                          <div
                            className="eachRow "
                            style={{ lineHeight: "30px" }}
                            onClick={() =>
                              this.routeToSpecificPage(product.product)
                            }
                          >
                            <p
                              className="font-weight-bold pr-4 "
                              style={{
                                wordWrap: "break-word",
                                fontSize: "16px",
                              }}
                            >
                              {product.product.name}
                            </p>
                          </div>
                          <div className="mt-3 ">
                            <SizeLabel product={product} />

                            <span
                              className="text-muted d-inline"
                              style={{ fontSize: 14 }}
                            >
                              {" "}
                              Qty: {product.quantity}
                            </span>
                            <span className=" text-muted  font-weight-bold float-right">
                              {" "}
                              $
                              {(
                                Math.round(
                                  product.product.price * product.quantity * 100
                                ) / 100
                              ).toFixed(2)}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </LoadingOverlay>
                  </div>
                ))}
              </div>

              <div className="inner-buttons-container border-top w-100 overflow-hidden">
                {
                  <Row className="mx-3">
                    <Col>
                      <ProgressBar total={cartTotal} />
                    </Col>
                  </Row>
                }
                <Row className="py-3 ">
                  <Col>
                    <span
                      style={{ fontSize: 30 }}
                      className="d-inline pl-2 font-weight-bold "
                    >
                      Total:{" "}
                    </span>
                    <span
                      style={{ fontSize: 30 }}
                      className="float-right font-weight-bold text-muted  pr-2"
                    >
                      ${cartTotal.toFixed(2)} AUD
                    </span>
                  </Col>
                </Row>

                <Row>
                  <Col className="p-0">
                    <Button
                      className="border-0 btn-block bg-light text-muted font-weight-bold px-3 py-3 "
                      style={{ borderRadius: 0 }}
                      onClick={this.routeToCart}
                    >
                      VIEW CART
                    </Button>
                  </Col>
                  <Col className="p-0">
                    <Button
                      className="font-weight-bold text-light btn-block px-3 py-3"
                      color="success"
                      style={{ borderRadius: 0 }}
                      onClick={() => {
                        this.props.history.push("/checkout");
                        this.props.toggleCart();
                        initiateCheckout(this.props.shoppingCart.cart);
                      }}
                    >
                      CHECKOUT
                    </Button>
                  </Col>
                </Row>
              </div>
            </React.Fragment>
          )}
        </div>
      </Drawer>
    );
  }
}

const mapStateToProps = (state) => ({
  shoppingCart: state.cart,
  products: state.products,
  auth: state.form,
  guest: state.guest,
});

export default connect(mapStateToProps, {
  toggleCart,
  updateShoppingCart,
  removeFromCart,
})(withRouter(SideBar));
