import React, { Component } from 'react'
import {
    ListGroup, ListGroupItem

} from 'reactstrap'


export default class FooterLinks extends Component {
    render() {
        return (
            <div>
                <ListGroup>
                    {this.props.links.map((link, index) => (
                        <ListGroupItem key={index} className='border-0  px-0' style={{ borderRadius: 30, backgroundColor: '#f8f9fa' }}>
                            <a className='text-dark' href={link.route} style={{ fontSize: 14 }}>
                                {link.title}
                            </a>
                        </ListGroupItem>
                    ))}
                </ListGroup>
            </div>
        )
    }
}
