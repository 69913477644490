import {
    GET_PRODUCTS,
    PRODUCT_LOADING,
    PRODUCT_LOADING_RESET,
    GET_PRODUCT_COUNT,
    GET_SINGLE_PRODUCT,
    RESET_PRODUCTS,
    SINGLE_PRODUCT_LOADING,
    RESET_SINGLE_PRODUCT_LOADING,
    GET_YOU_MAY_ALSO_LIKE_PRODUCTS,
    RESET_YOU_MAY_ALSO_LIKE_PRODUCTS,
    GET_BEST_SELLERS
} from './types'
import axios from 'axios'

export const getBestSellers = (data) => dispatch => {
    dispatch({
        type: PRODUCT_LOADING
    })

    axios.post(`/api/products/getBestSellers`, data)
        .then(res => {
            dispatch({
                type: GET_BEST_SELLERS,
                payload: res.data
            })
            dispatch({
                type: PRODUCT_LOADING_RESET
            })

        })
        .catch(err => {
            dispatch({
                type: PRODUCT_LOADING_RESET
            })
            console.log(err)
        })
}



export const getProducts = (page, orderby, searchQuery, category) => dispatch => {
    dispatch({
        type: PRODUCT_LOADING
    })

    axios.get(`/api/products/getproducts/${page}/${orderby}/${searchQuery}/${category}`)
        .then(res => {
            if (res.data.products) {
                dispatch({
                    type: GET_PRODUCTS,
                    payload: res.data.products
                })
            }
            if (res.data.count) {
                dispatch({
                    type: GET_PRODUCT_COUNT,
                    payload: res.data.count
                })
            }
            dispatch({
                type: PRODUCT_LOADING_RESET
            })

        })
        .catch(err => {
            dispatch({
                type: PRODUCT_LOADING_RESET
            })
            console.log(err)
        })
}


export const resetProducts = () => dispatch => {
    dispatch({
        type: RESET_PRODUCTS,
    })
}

export const getSingleProduct = (id) => dispatch => {
    dispatch({
        type: SINGLE_PRODUCT_LOADING
    })
    axios.get(`/api/products/getSpecificProduct/${id}`)
        .then(res => {
            dispatch({
                type: GET_SINGLE_PRODUCT,
                payload: res.data
            })
            dispatch({
                type: RESET_SINGLE_PRODUCT_LOADING
            })
        })
        .catch(err => {
            dispatch({
                type: RESET_SINGLE_PRODUCT_LOADING
            })
            console.log(err)
        }
        )

}


export const getYouMayAlsoLike = (product) => dispatch => {
    axios.get(`/api/products/getYouMayAlsoLike`, { params: product })
        .then(res => {
            dispatch({
                type: GET_YOU_MAY_ALSO_LIKE_PRODUCTS,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const resetYouMayAlsoLike = () => dispatch => {
    dispatch({
        type: RESET_YOU_MAY_ALSO_LIKE_PRODUCTS,
    })
}




