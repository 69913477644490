import React, { Component } from "react";
import About from "./Components/About";
import LandingPage from "./Components/LandingPage";
import Footer from "./Components/Footer";
import Contact from "./Components/Contact";
import Shop from "./Components/Shop";
import LogIn from "./Components/LogIn";
import PageLink from "./Components/PageLink";
import AccountVerified from "./Components/AccountVerified";
import NotFound from "./Components/NotFound";
import Dashboard from "./Components/Dashboard";
import Addresses from "./Components/Addresses";
import OrderDetails from "./Components/OrderDetails";
import PrivateRoute from "./Components/PrivateRoute";
import NewResetPassword from "./Components/NewResetPassword";
import Topbar from "./Components/Topbar";
import MyCart from "./Components/MyCart";
import Checkout from "./Components/Checkout";
import { Switch, Route, withRouter } from "react-router-dom";
import NavBar from "./Components/NavBar";
import AdminDash from "./Components/AdminPanel/AdminDash";
import PrivateAdminRoute from "./Components/PrivateAdminRoute";
import UserDashboard from "./Components/AdminPanel/UserDashboard";
import SelectedUser from "./Components/AdminPanel/SelectedUser";
import ProductsDashboard from "./Components/AdminPanel/ProductsDashboard";
import NewProduct from "./Components/AdminPanel/NewProduct";
import UpdateProduct from "./Components/AdminPanel/UpdateProduct";
import OrderReceived from "./Components/OrderReceived";
import SelectedOrder from "./Components/AdminPanel/SelectedOrder";
import OrdersDashboard from "./Components/AdminPanel/OrdersDashboard";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import TermsandConditions from "./Components/TermsandConditions";
import { connect } from "react-redux";
import { subscribe } from "./actions/newsletterAction";
import Coupons from "./Components/AdminPanel/Coupons";
import HowToApply from "./Components/HowToApply";
import {
  resetLocalStorageUser,
  logoutUser,
  resetFormReducer,
} from "../src/actions/formActions";
import Reviews from "./Components/AdminPanel/Reviews";
import NewCoupon from "./Components/AdminPanel/NewCoupon";
import Sustainability from "./Components/Sustainability";
import Categories from "./Components/AdminPanel/Categories";
import NewCategory from "./Components/AdminPanel/NewCategory";
import Faqs from "./Components/Faqs";
import ShippingPolicy from "./Components/ShippingPolicy";
import HowAreWeDoing from "./Components/HowAreWeDoing";
import Explore from "./Components/Explore";
import WholesaleInquiry from "./Components/WholesaleInquiry";
import OrderTracking from "./Components/OrderTracking";
import ReturnsExchanges from "./Components/ReturnsExchange";
import WelcomeModal from "./Components/WelcomeModal";
import CollapseMenu from "./Components/CollapseMenu";
import SideBar from "./Components/SideBar";

class Routes extends Component {
  state = {
    windowWidth: window.innerWidth,
    navHeight: 0,
  };

  componentDidMount() {
    this.updateWindowDimension();
    window.addEventListener("resize", this.updateWindowDimension);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimension);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (this.props.auth.checkUser.userName !== null) {
        if (!localStorage.jwtToken) {
          this.props.resetLocalStorageUser();
        }
      }
      this.updateWindowDimension();
    }
  }

  onChange = (e) => {
    this.setState({
      errors: {},
    });
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    let data = {};
    data.email = this.state.email.toLowerCase();
    data.firstName = this.state.firstName;
    data.cart = [...this.props.cart.cart];

    this.props.subscribe(data);
  };

  updateWindowDimension = (e) => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };

  render() {
    let showNav = true;

    let pathnamesArray = this.props.history.location.pathname.split("/");

    if (pathnamesArray.includes("adminpanel")) {
      showNav = false;
    }
    let navHeight = this.state.navHeight + "px";

    const { errors } = this.state;

    return (
      <div className=" site overflow-hidden">
        <div className="site-content">
          <React.Fragment>
            {window.location.pathname !== "/checkout" &&
              window.location.pathname !== "/login" &&
              showNav && (
                <div
                  className={
                    window.location.pathname !== "/login" && "fixed-top "
                  }
                >
                  <Topbar />
                  <NavBar />
                  <WelcomeModal />
                  <HowAreWeDoing />
                </div>
              )}
            <CollapseMenu />
            <SideBar />
            <Switch>
              <div
                style={{
                  paddingTop:
                    showNav &&
                    window.location.pathname !== "/login" &&
                    window.location.pathname !== "/checkout" &&
                    140,
                }}
              >
                <Route exact path="/" component={LandingPage} />
                <Route exact path="/about" component={About} />
                <Route exact path="/help-centre" component={Contact} />
                <Route exact path="/shop" component={Shop} />
                <Route exact path="/login" component={LogIn} />
                <Route exact path="/howtoapply" component={HowToApply} />
                <Route exact path="/faqs" component={Faqs} />
                <Route
                  exact
                  path="/shipping-policy"
                  component={ShippingPolicy}
                />
                <Route
                  exact
                  path="/returns-policy"
                  component={ReturnsExchanges}
                />
                <Route
                  exact
                  path="/sustainability"
                  component={Sustainability}
                />
                <Route
                  exact
                  path="/shop/:title/:productid"
                  component={PageLink}
                />
                <Route
                  exact
                  path="/verifyaccount/:code"
                  component={AccountVerified}
                />
                <Route exact path="/cart" component={MyCart} />
                <Route exact path="/checkout" component={Checkout} />
                <Route exact path="/privacypolicy" component={PrivacyPolicy} />
                <Route
                  exact
                  path="/termsofuse"
                  component={TermsandConditions}
                />
                <Route
                  exact
                  path="/newPassword/:userid/:code"
                  component={NewResetPassword}
                />
                <Route
                  exact
                  path="/order-received/:orderid"
                  component={OrderReceived}
                />
                <Route exact path="/explore" component={Explore} />
                <Route
                  exact
                  path="/becomestockist"
                  component={WholesaleInquiry}
                />
                <Route exact path="/order-status" component={OrderTracking} />

                <PrivateRoute
                  exact
                  path="/dashboard/order-details/:orderid"
                  component={OrderDetails}
                />

                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute
                  exact
                  path="/dashboard/addresses"
                  component={Addresses}
                />

                <PrivateAdminRoute
                  exact
                  path="/adminpanel"
                  component={AdminDash}
                />
                <PrivateAdminRoute
                  exact
                  path="/adminpanel/users"
                  component={UserDashboard}
                />
                <PrivateAdminRoute
                  exact
                  path="/adminpanel/coupons"
                  component={Coupons}
                />
                <PrivateAdminRoute
                  exact
                  path="/adminpanel/coupons/newCoupon"
                  component={NewCoupon}
                />
                <PrivateAdminRoute
                  exact
                  path="/adminpanel/reviews"
                  component={Reviews}
                />
                <PrivateAdminRoute
                  exact
                  path="/adminpanel/users/:name/:userid"
                  component={SelectedUser}
                />
                <PrivateAdminRoute
                  exact
                  path="/adminpanel/products"
                  component={ProductsDashboard}
                />
                <PrivateAdminRoute
                  exact
                  path="/adminpanel/products/newProduct"
                  component={NewProduct}
                />
                <PrivateAdminRoute
                  exact
                  path="/adminpanel/products/:title/:productid"
                  component={UpdateProduct}
                />
                <PrivateAdminRoute
                  exact
                  path="/adminpanel/orders"
                  component={OrdersDashboard}
                />
                <PrivateAdminRoute
                  exact
                  path="/adminpanel/orders/:orderid"
                  component={SelectedOrder}
                />
                <PrivateAdminRoute
                  exact
                  path="/adminpanel/categories"
                  component={Categories}
                />
                <PrivateAdminRoute
                  exact
                  path="/adminpanel/categories/newCategory"
                  component={NewCategory}
                />
              </div>
              <Route path="*" component={NotFound} />
            </Switch>
          </React.Fragment>
        </div>
        {window.location.pathname !== "/checkout" &&
          window.location.pathname !== "/login" &&
          showNav && (
            <React.Fragment>
              <Footer />
            </React.Fragment>
          )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cart: state.cart,
  errors: state.errors,
  newsletter: state.newsletter,
  auth: state.form,
});

export default connect(mapStateToProps, {
  subscribe,
  resetLocalStorageUser,
  logoutUser,
  resetFormReducer,
})(withRouter(Routes));
