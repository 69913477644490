import React, { Component } from "react";
import { Row, Container, Col, Button } from "reactstrap";
import { Helmet } from "react-helmet";
import Faqs from "./Faqs";
import SocialMedia from "./SocialMedia";
import BreadCrumbs from "./BreadCrumbs";

const tiles = [
  {
    title: "Order Status",
    route: "order-status",
    logo: "far fa-compass",
  },

  {
    title: "Returns & Exchanges",
    route: "returns-policy",
    logo: "fas fa-exchange-alt",
  },
  {
    title: "Shipping & Delivery",
    route: "/shipping-policy",
    logo: "fas fa-shipping-fast",
  },
  {
    title: "Become a Stockist",
    route: "/becomestockist",
    logo: "far fa-comments",
  },
  {
    title: "Terms of Use",
    route: "/termsofuse",
    logo: "far fa-file-alt",
  },
  {
    title: "Privacy Policy",
    route: "/privacypolicy",
    logo: "fas fa-compass",
  },
];

class Contact extends Component {
  state = {
    windowWidth: window.innerWidth,
  };

  componentDidMount() {
    this.updateWindowDimension();
    window.addEventListener("resize", this.updateWindowDimension);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimension);
  }

  updateWindowDimension = (e) => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };

  handleRoute = (route) => {
    this.props.history.push(`${route}`);
  };

  render() {
    return (
      <div className="pb-4">
        <div>
          <Helmet>
            <title>Help-Centre | Remote Trails</title>
            <meta name="description" content="Help - Centre | Remote Trails" />
          </Helmet>
        </div>
        <div className="container">
          <div>
            <BreadCrumbs title="CUSTOMER HELP CENTRE" />
          </div>
        </div>

        <Container>
          <Row>
            <Col md="8" sm="12 " className="px-0">
              <div
                className={`${
                  this.state.windowWidth > 768 && `border-right pr-5`
                } h-100`}
              >
                <Row>
                  {tiles.map((tile, index) => (
                    <Col
                      sm="6"
                      xs="12"
                      key={index}
                      className=" my-3"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.handleRoute(tile.route)}
                    >
                      <Row className="border mx-2 py-3 tile-anime">
                        <Col xs="4" className="my-auto">
                          <div
                            className="text-right "
                            style={{ height: "100%", lineHeight: "15px" }}
                          >
                            <p
                              className="pr-3 m-0 p-0"
                              style={{ fontSize: 25, verticalAlign: "middle" }}
                            >
                              <i className={tile.logo} />
                            </p>
                          </div>
                        </Col>
                        <Col className="my-auto">
                          <p
                            className="font-weight-bold m-0 p-0"
                            style={{
                              verticalAlign: "middle",
                              fontSize: 15,
                              lineHeight: "15px",
                            }}
                          >
                            {tile.title}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>

            <Col md="4" sm="12">
              <div
                className={`${
                  this.state.windowWidth > 768 ? `px-5` : "px-3"
                } h-100`}
              >
                <Row>
                  <div className=" my-2">
                    <h1 className="font-weight-bold" style={{ fontSize: 24 }}>
                      Still need help?
                    </h1>
                  </div>

                  <div className="">
                    <h1
                      className="font-weight-bold py-3"
                      style={{ fontSize: 20 }}
                    >
                      Contact Us
                    </h1>
                    <p>
                      Reach us via email at{" "}
                      <a
                        style={{ color: "#a16348" }}
                        href="mailto:support@remotetrails.com.au"
                      >
                        support@remotetrails.com.au
                      </a>{" "}
                      or click the button below.
                    </p>
                    <p>
                      All inquiries will be responded within 2 business day.
                    </p>
                    <Button
                      size="sm"
                      style={{ borderRadius: 30, backgroundColor: "#a16348" }}
                      className="px-5 mt-2 border-0"
                    >
                      Send us an email
                    </Button>
                  </div>

                  <div className="mt-4 ">
                    <h1
                      className="font-weight-bold py-3"
                      style={{ fontSize: 20 }}
                    >
                      Follow Us
                    </h1>
                    <p>Find us on social media.</p>

                    <SocialMedia />
                  </div>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Faqs />
          </Row>
        </Container>
      </div>
    );
  }
}
export default Contact;
