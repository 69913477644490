import React from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

function Warnings(props) {
  return (
    <div>
      {props.shoppingCart.warnings.map((eachWarning, index) => (
        <div key={index} className="border-bottom">
          <Row className="px-2 py-3">
            <Col xs="2">
              <img
                src={eachWarning.product.imageList[0].imageUrl}
                alt={eachWarning.product.name}
                style={{ minHeight: 60, maxHeight: 100 }}
              />
            </Col>
            <Col xs="10">
              <div className="pt-2">
                <span className="w-75 d-inline float-left  pl-3">
                  {" "}
                  {eachWarning.product.name}
                </span>
              </div>
            </Col>
          </Row>
          <Row className="px-4 py-1 ">
            <p className="font-weight-bold text-danger">
              {eachWarning.definition}
            </p>
          </Row>
        </div>
      ))}
    </div>
  );
}

const mapStateToProps = (state) => ({
  shoppingCart: state.cart,
});

export default connect(mapStateToProps, {})(withRouter(Warnings));
