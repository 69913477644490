import React, { Component } from "react";
import { Alert } from "reactstrap";
import { withRouter } from "react-router-dom";
class AlertBox extends Component {
  render() {
    const {
      push,
      linkText = "Click here",
      alertColor,
      linkColor = "rgb(235,24,78)",
      title,
      subText
    } = this.props;
    return (
      <div>
        <Alert color={alertColor}>
          {title}
          {push && (
            <span
              onClick={() => this.props.history.push(push)}
              style={{ color: linkColor, cursor: "pointer" }}
            >
              {linkText}
            </span>
          )}

          {subText}
        </Alert>
      </div>
    );
  }
}
export default withRouter(AlertBox);
