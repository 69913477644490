import React, { Component } from "react";
import classnames from "classnames";
import { Form, InputGroup, Input } from "reactstrap";
import FooterTitle from "./FooterTitle";
import FooterParagraph from "./FooterParagraph";
import Axios from "axios";
import { connect } from "react-redux";
import { completeRegistration, lead } from "../utils/analytics";
import { getCartTotal } from "../utils/getCartTotal";
class Subscribe extends Component {
  state = {
    email: "",
    errors: {},
    showSuccess: false,
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      errors: {},
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      errors: {},
      showSuccess: false,
    });

    const data = {
      email: this.state.email,
      cart: this.props.shoppingCart.cart,
    };

    Axios.post("/api/guestUsers/newsletter", data)
      .then((res) => {
        completeRegistration(
          "Newsletter",
          getCartTotal(this.props.shoppingCart.cart)
        );

        lead(this.props.shoppingCart.cart, "Newsletter");

        this.setState({
          showSuccess: true,
          email: "",
          errors: {},
        });
      })
      .catch((err) => {
        this.setState({ errors: err.response.data });
      });
  };
  render() {
    const { errors } = this.state;

    return (
      <div className="container">
        <div>
          <FooterTitle title="Subscribe" />
          <FooterParagraph paragraph="Sign up for exclusive offers, original stories, activism awareness, events and more." />

          <Form onSubmit={this.handleSubmit}>
            <InputGroup className="my-2">
              <Input
                placeholder="Email"
                onChange={this.onChange}
                className={classnames("", { "is-invalid": errors.email })}
                value={this.state.email}
                name="email"
              />
              <div className="invalid-feedback">{errors.email}</div>
            </InputGroup>

            <input
              style={{ borderRadius: 30, backgroundColor: "#a16348" }}
              disabled={
                this.state.email === "" ||
                this.state.firstName === "" ||
                this.state.lastName === ""
                  ? true
                  : false
              }
              type="submit"
              value={"Sign up"}
              className="btn  btn-block mt-4 border-0 text-light"
            />

            {errors.userExists && (
              <div className="text-danger mt-3">
                Email address has already subscribed.
              </div>
            )}

            {this.state.showSuccess && (
              <div className="text-success mt-3">
                Thanks for signing up to our newsletter. Check your email to see
                your 10% off promo code!
              </div>
            )}
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  shoppingCart: state.cart,
});

export default connect(mapStateToProps, {})(Subscribe);
