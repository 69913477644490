import React, { useState } from "react";
import { Row, Col, Modal } from "reactstrap";
import sizeGuide from "../images/sizeguide.png";

export default function SizeGuideModal() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <Modal isOpen={isOpen} toggle={() => setIsOpen(false)}>
        <Row>
          <Col>
            <div className="p-3">
              <div>
                <p className="font-weight-bold" style={{ fontSize: 14 }}>
                  Size Guide
                </p>
                <img
                  src={sizeGuide}
                  className="img-fluid"
                  alt="Remote Trails Tshirt Size Guide"
                />
              </div>

              <div className="my-4">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Size</th>
                      <th scope="col">Chest (cm)</th>
                      <th scope="col">Length (cm)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>S</td>
                      <td>50</td>
                      <td>70</td>
                    </tr>
                    <tr>
                      <td>M</td>
                      <td>53</td>
                      <td>72</td>
                    </tr>
                    <tr>
                      <td>L</td>
                      <td>56</td>
                      <td>74</td>
                    </tr>
                    <tr>
                      <td>XL</td>
                      <td>60</td>
                      <td>76</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
        </Row>
      </Modal>

      <div>
        <p
          className="p-0 m-0 font-weight-bold pr-2 position-relative"
          style={{ fontSize: 14 }}
        >
          <span>Size</span>
          <span
            className="text-muted"
            onClick={() => setIsOpen(true)}
            style={{
              position: "absolute",
              right: 0,
              fontSize: 12,
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            <i className="fa-solid fa-ruler-horizontal mr-2"></i>
            Size Guide
          </span>
        </p>
      </div>
    </div>
  );
}
