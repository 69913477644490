import React, { Component } from "react";
import Landing from "./Landing";
import BestSellers from "./BestSellers";
import About from "./About";

class LandingPage extends Component {
  render() {
    return (
      <div>
        <Landing />
        <BestSellers />
        <About />
        {/* <StoriesFromTheTrails /> */}
      </div>
    );
  }
}

export default LandingPage;
