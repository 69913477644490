import {
    SEND_NEWSLETTER,
    SEND_NEWSLETTER_LOADING_RESET,
    SEND_NEWSLETTER_LOADING,
    SEND_NEWSLETTER_ERRORS,
    RESET_SEND_NEWSLETTER,
    TESTMAIL_SENT,
    SEND_TESTMAIL_LOADING_RESET,
    SEND_TESTMAIL_LOADING,
    RESET_TESTMAIL_DATA
} from '../actions/types.js'


const initialState = {
    mailSent: false,
    loading: false,
    mailingList: [],
    errors: {},
    testMailSent:false,
    testMailLoading:false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case RESET_SEND_NEWSLETTER:
            return {
                ...state,
                mailSent: false,
                loading: false,
                mailingList: [],
                errors: {},
            }
            case RESET_TESTMAIL_DATA:
                return {
                    ...state,
                    testMailSent:false,
                    testMailLoading:false
                }
            case TESTMAIL_SENT:
                return {
                    ...state,
                   testMailSent:true
                }
                case SEND_TESTMAIL_LOADING:
                    return {
                        ...state,
                        testMailLoading:true
                    }
                    case SEND_TESTMAIL_LOADING_RESET:
                        return {
                            ...state,
                            testMailLoading:false
                        }
        case SEND_NEWSLETTER:
            return {
                ...state,
                mailSent: true,
                mailingList: action.payload.users
            }
        case SEND_NEWSLETTER_LOADING:
            return {
                ...state,
                loading: true,
                mailSent: false,
            }
        case SEND_NEWSLETTER_LOADING_RESET:
            return {
                ...state,
                loading: false
            }

        case SEND_NEWSLETTER_ERRORS:
            return {
                ...state,
                errors: action.payload
            }
        default: return state;
    }
}