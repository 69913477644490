import React from "react";
import { Row, Col } from "reactstrap";
import returnsPolicy from "./Policies/ReturnsPolicy.json";

export default function ReturnsExchange() {
  return (
    <div>
      <div className="container">
        <div>
          <div>
            <p className="mb-0 d-inline pr-2">
              <a
                href="/"
                style={{
                  textDecoration: "underline",
                  color: "black",
                  fontSize: 14,
                }}
              >
                Remote Trails
              </a>{" "}
              >
            </p>
            <p className="mb-0 d-inline pr-2">
              <a
                href="/help-centre"
                style={{
                  textDecoration: "underline",
                  color: "black",
                  fontSize: 14,
                }}
              >
                Help Centre
              </a>{" "}
              >
            </p>
            <p className="mb-0 d-inline">
              <a
                href="/shipping-policy"
                style={{
                  textDecoration: "underline",
                  color: "black",
                  fontSize: 14,
                }}
              >
                Returns & Exchanges
              </a>
            </p>
          </div>

          <h1 className="font-weight-bold" style={{ fontSize: "1.25rem" }}>
            RETURNS & EXCHANGES
          </h1>
        </div>
      </div>

      <div className="container my-2">
        <Row>
          <Col>
            <div>
              <p>FREE returns and exchange for 30 days!</p>
              {returnsPolicy[0].returnsPolicy.map((policy, index) => (
                <div key={index} className="my-1">
                  <li> {policy.paragraph}</li>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
