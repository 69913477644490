import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import Base from './Base'
import sustainability from '../images/sustainability.jpg'
// import RowLayout from './RowLayout';
import design from '../images/design.jpeg'
import production from '../images/production.jpeg'

const remoteTrailsSustainability = [
    {
        image: production,
        title: 'Sustainable Fabrics',
        paragraph: `It's a long journey from the field to the final product.`,
        buttonText: ''

    },
    {
        image: design,
        title: 'Eco Friendly Experience',
        paragraph: 'Research and testing underpins all our planning and action around sustainability.         ',
        buttonText: ''

    },
    {
        image: production,
        title: 'Supply Chain',
        paragraph: 'Good for us, our partners, their workers, and the environment.',
        buttonText: ''

    }]

const paragraphs = [`High quality, durable and comfortable products and sustainability can coexist, allowing an unforgettable experience in the great outdoors. 
Our planet offers enticing and exciting experiences around untouched wilderness and remarkable wildlife. While we are aware that everything we do has an impact on the environment around us, we are committed to reducing it at every turn. `,
    `As an outdoor company, protection and conservation of our planet is fundamental to our operation.  We therefore integrate sustainability into everything we do, making deliberate decisions about who we work with, how we work & what materials we use. `,
    `Sustainability is as much about choices and understanding as it is about action. At Remote Trails, we focus on finding common ground to advocate and educate our community about the most responsible and ethical way of enjoying the outdoors. `]



export default class Sustainability extends Component {
    render() {
        return (
            <div >
                <div className='container'>
                    <Base image={sustainability} overlay title={`OUR PATH TO SUSTAINABILITY`} />
                </div>
                <div className='container'>
                    <Row>
                        <Col>
                            <div className='w-75 mx-auto' >
                                <h1 className='mt-5'>WE PRODUCE CONTEMPORARY CLOTHING MADE
                                    WITH RESPECT FOR BOTH PEOPLE AND THE PLANET.</h1>
                                {paragraphs.map((paragraph, index) => (
                                    <p className='font-weight-light my-5' style={{ lineHeight: 2, fontSize: 18 }} key={index}>{paragraph}</p>
                                ))}
                            </div>
                        </Col>
                    </Row>
                    {/* <RowLayout boxes={remoteTrailsSustainability} /> */}


                </div>

            </div>
        )
    }
}
