import React, { Component } from "react";
import { Breadcrumb, BreadcrumbItem, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
class PageCrumbs extends Component {
  render() {
    const { crumbs } = this.props;
    return (
      <Row className="mt-3">
        <Col>
          <div>
            <Breadcrumb className="mx-2">
              {crumbs.map((crumb, index) => (
                <BreadcrumbItem
                  key={index}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    crumb.route && this.props.history.push(crumb.route)
                  }
                >
                  {crumb.title}
                </BreadcrumbItem>
              ))}
            </Breadcrumb>
          </div>
        </Col>
      </Row>
    );
  }
}
export default withRouter(PageCrumbs);
