import React, { Component } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import Paginate from './Pagination'
import { withRouter } from 'react-router-dom'
class DashboardOrderList extends Component {
    state = {
        windowWidth: 0,
        currentPage: 1,
        postsPerPage: 5,
    }

    componentDidMount() {
        this.setState({
            windowWidth: window.innerWidth,
        })
        window.addEventListener('resize', this.updateWindowDimension);

    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimension);

    }

    updateWindowDimension = (e) => {
        this.setState({
            windowWidth: window.innerWidth
        })
    }

    paginate = (pageNumber) => {
        this.setState({
            currentPage: pageNumber
        })
    };

    handleSignleOrder = (item) => {
        this.props.history.push(`/dashboard/order-details/${item.orderid}`)
    }



    render() {

        const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
        const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
        let currentOrders = this.props.orders.userOrders.slice(indexOfFirstPost, indexOfLastPost)

        return (
            <div >
                <table className="table">
                    <thead className='table-borderless'>
                        <tr>
                            <th scope="col">ORDER NO</th>
                            {this.state.windowWidth > 600 && (
                                <th scope="col">PAYMENT TYPE</th>
                            )}
                            <th scope="col">ORDER DATE</th>
                            {this.state.windowWidth > 600 && (
                                <React.Fragment>
                                    <th scope="col">TOTAL</th>
                                </React.Fragment>
                            )}
                            <th scope="col">ORDER STATUS</th>
                            <th scope="col">TRACKING NUMBER</th>



                        </tr>
                    </thead>
                    <tbody >


                        {currentOrders

                            .map(item => (
                                <tr key={item._id} className='user_order_item' onClick={() => this.handleSignleOrder(item)}>
                                    <td className='text-truncate' style={{ color: 'rgb(223,149,143)', verticalAlign: 'middle', maxWidth: this.state.windowWidth < 600 && '50px' }}>
                                        {item.orderid.toUpperCase()}
                                    </td>
                                    {this.state.windowWidth > 600 && (
                                        <td style={{ verticalAlign: 'middle' }} >{item.paymentType === 'stripe' ? 'Credit Card ' : (item.paymentType === 'paypal' && 'Paypal')}</td>
                                    )}
                                    <td style={{ verticalAlign: 'middle' }} >{moment(item.created_at).format('LLL')}</td>

                                    {this.state.windowWidth > 600 && (
                                        <td style={{ verticalAlign: 'middle', width: '100px' }} className='font-weight-bold text-muted'>
                                            AUD {(item.total / 100).toFixed(2)}
                                        </td>
                                    )}
                                    <td className={item.orderStatus === 'Processing' ? 'text-warning' : 'text-success'} style={{ verticalAlign: 'middle' }} >{item.orderStatus}</td>
                                    <td className={item.shippingDate && 'text-success'} style={{ verticalAlign: 'middle' }} >{item.trackingNumber ? (
                                        <a style={{ color: item.trackingNumber && 'rgb(223,149,143)' }} href={`https://auspost.com.au/mypost/track/#/details/${item.trackingNumber}`} rel="noopener noreferrer" target='_blank'>{item.trackingNumber.toUpperCase()} </a>
                                    ) : 'N/A'}</td>

                                </tr>
                            )
                            )}

                    </tbody>
                </table>
                <div className='text-center'>
                    {this.props.orders.userOrders.length !== 0 &&
                        this.props.orders.userOrders.length > 5 && (
                            <Paginate postsPerPage={this.state.postsPerPage}
                                totalPosts={this.props.orders.userOrders.length} paginate={this.paginate} />
                        )}
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    errors: state.errors,
    auth: state.form,
    cart: state.cart,
    fullPageLoading: state.fullPageLoading,
    orders: state.orders
})


export default connect(mapStateToProps, {})(withRouter(DashboardOrderList))