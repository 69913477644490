import React from "react";
import BadgeMT from "@material-ui/core/Badge";
import { Row, Col } from "reactstrap";
import SizeLabel from "./SizeLabel";

export default function EachProductList({ product }) {
  return (
    <div key={product._id} className="border-bottom">
      <Row className="px-2 py-3 ">
        <Col xs="3">
          <BadgeMT
            badgeContent={product.quantity}
            color="error"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <img
              src={product.product.imageList[0].imageUrl}
              className="img-fluid"
              alt={product.product.name}
              style={{ minHeight: 60, maxHeight: 100 }}
            />
          </BadgeMT>
        </Col>
        <Col xs="9">
          <div className="pt-2">
            <span className="w-75 d-inline float-left  pl-3">
              {" "}
              {product.product.name} <br />
              <SizeLabel product={product} />
            </span>

            <span className=" text-muted  font-weight-bold w-25 float-right pl-2">
              {" "}
              $
              {(
                Math.round(product.product.price * product.quantity * 100) / 100
              ).toFixed(2)}
            </span>
          </div>
        </Col>
      </Row>
    </div>
  );
}
