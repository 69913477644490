import {
    ADMIN_ANALYTICS_LOADING,
    ADMIN_ANALYTICS_LOADING_RESET,
    SESSION_BY_DEVICE_TOTAL_USERS,
    SESSION_BY_DEVICE_TOTAL_DESKTOP,
    SESSION_BY_DEVICE_TOTAL_MOBILE,
    SESSION_BY_DEVICE_TOTAL_TABLET

} from './types'
import axios from 'axios'
import { logoutUser } from './formActions'



export const analyticDetails = (date) => dispatch => {
    dispatch({
        type: ADMIN_ANALYTICS_LOADING
    })
    axios.get(`/api/admin/googleAnalyticsDetails/${date}`)
        .then(res => {
            dispatch({
                type: SESSION_BY_DEVICE_TOTAL_USERS,
                payload: res.data.totalUsers
            })
            dispatch({
                type: SESSION_BY_DEVICE_TOTAL_DESKTOP,
                payload: res.data.desktopUsers
            })
            dispatch({
                type: SESSION_BY_DEVICE_TOTAL_MOBILE,
                payload: res.data.mobileUsers
            })
            dispatch({
                type: SESSION_BY_DEVICE_TOTAL_TABLET,
                payload: res.data.tabletUsers
            })

            dispatch({
                type: ADMIN_ANALYTICS_LOADING_RESET
            })
        })
        .catch(err => {
            dispatch({
                type: ADMIN_ANALYTICS_LOADING_RESET
            })
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }

            console.log(err)
        })

}
