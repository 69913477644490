import React, { Component } from 'react'

export default class FooterParagraph extends Component {
    render() {
        return (
            <div>
                <p style={{ fontSize: 14 }}>{this.props.paragraph}</p>
            </div>
        )
    }
}
