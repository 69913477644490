import React from "react";
import TouchableOpacity from "./TouchableOpacity";
import { setCollapseMenu } from "../actions/collapseMenu";
import { connect } from "react-redux";

const CloseIcon = (props) => {
  return (
    <TouchableOpacity onClick={() => props.setCollapseMenu(false)}>
      <div className=" py-2 text-right ">
        <span style={{ fontSize: 25 }}>&times;</span>
      </div>
    </TouchableOpacity>
  );
};

const mapStateToProps = (state) => ({
  collapseMenu: state.collapseMenu,
});

export default connect(mapStateToProps, { setCollapseMenu })(CloseIcon);
