import React, { Component } from "react";
import Badge from "@material-ui/core/Badge";
import { connect } from "react-redux";
import { toggleCart } from "../actions/shoppingCart";

class Cart extends Component {
  openCart = (e) => {
    this.props.toggleCart();
  };

  render() {
    return (
      <div className="mr-2 d-inline" onClick={this.openCart}>
        <i
          className="fas fa-shopping-cart nav-cart"
          style={{ fontSize: "17px", cursor: "pointer" }}
        >
          <Badge
            badgeContent={
              this.props.shoppingCart.cart.length !== 0
                ? this.props.shoppingCart.cart
                    .map((product) => product.quantity)
                    .reduce((total, currentValue) => total + currentValue)
                : 0
            }
            color="error"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          />
        </i>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  shoppingCart: state.cart,
  products: state.products,
});

export default connect(mapStateToProps, { toggleCart })(Cart);
