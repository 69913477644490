import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, Form, Input, Badge, Button } from "reactstrap";
import {
  getUserList,
  updatedUsers,
  updatedUserCount,
} from "../../actions/adminUserDash";
import { Helmet } from "react-helmet";
import queryString from "query-string";
import Pusher from "pusher-js";

class UserDashboard extends Component {
  state = {
    searchQuery: this.props.location.search,
    filter: "",
    queryInput: "",
    page: 1,
  };
  loadMore = (e) => {
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => {
        const parsed = queryString.parse(this.props.location.search);

        let page = this.state.page;
        let filter;
        let query;

        if (parsed.filter) {
          filter = parsed.filter;
        }
        if (parsed.query) {
          query = parsed.query;
        }

        this.props.getUserList(page, filter, query);
      }
    );
  };

  scrollToMyRef = () => window.scrollTo(0, 0);

  componentWillUnmount() {
    this.channel.unbind();
  }

  componentDidMount() {
    this.pusher = new Pusher("1ede5d88839c3e05076c", {
      cluster: "ap4",
      encrypted: true,
    });

    this.channel = this.pusher.subscribe("userDashboard");

    this.channel.bind(
      "newUser",
      function (data) {
        let users = [...this.props.users.users];

        let index = users.findIndex(
          (eachUser) => eachUser._id === data.newUser._id
        );

        if (index !== -1) {
          users[index] = data.newUser;
        } else {
          users.unshift(data.newUser);
          this.props.updatedUserCount(users.length + 1);
        }

        this.props.updatedUsers(users);
      }.bind(this)
    );

    const parsed = queryString.parse(this.props.location.search);
    let page = this.state.page;
    let filter;
    let query;

    if (parsed.filter) {
      filter = parsed.filter;
      this.setState({
        filter,
      });
    }

    if (parsed.query) {
      query = parsed.query;
      this.setState({
        queryInput: "",
      });
    }

    this.props.getUserList(page, filter, query);
  }

  handleInputChange = (e) => {
    this.setState({
      queryInput: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const parsed = queryString.parse(this.props.location.search);
    parsed.query = this.state.queryInput;
    let stringified = queryString.stringify(parsed);

    this.setState({
      queryInput: "",
      searchQuery: "?" + stringified,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const parsed = queryString.parse(this.props.location.search);

    if (this.state.searchQuery !== prevState.searchQuery) {
      this.props.history.push(`/adminpanel/users${this.state.searchQuery}`);
    }

    if (this.props.location.search !== prevProps.location.search) {
      let page = this.state.page;
      let filter;
      let query;

      if (parsed.filter) {
        filter = parsed.filter;
      }
      if (parsed.query) {
        query = parsed.query;
      }

      this.props.getUserList(page, filter, query);
    }
  }

  handleReset = (e) => {
    this.setState({
      searchQuery: "",
    });
  };

  ucFirstAllWords = (str) => {
    var pieces = str.split(" ");
    for (var i = 0; i < pieces.length; i++) {
      var j = pieces[i].charAt(0).toUpperCase();
      pieces[i] = j + pieces[i].substr(1);
    }
    return pieces.join(" ");
  };

  handleUserClick = (user) => {
    this.props.history.push({
      pathname: `/adminpanel/users/${user.firstName}${user.lastName}/${user._id}`,
      state: { user },
    });
  };

  render() {
    const parsed = queryString.parse(this.props.location.search);

    let userCount = this.props.users.userCount;

    let noResultWarning;

    if (parsed.query && this.props.users.userCount === 0) {
      noResultWarning = true;
    }

    return (
      <div className="mb-3">
        <div>
          <Helmet>
            <title>Users - Remote Trails</title>
            <meta name="description" content="Users - Remote Trails" />
          </Helmet>
        </div>

        <div>
          <Row className="my-3">
            <Col>
              <Form onSubmit={this.handleSubmit}>
                <Input
                  placeholder="Search User"
                  value={this.state.queryInput}
                  onChange={this.handleInputChange}
                />
              </Form>
            </Col>
          </Row>
        </div>
        <div className="mx-2">
          {parsed.query && (
            <h5
              className="d-inline pr-3"
              style={{ cursor: "pointer" }}
              onClick={this.handleReset}
            >
              {" "}
              <Badge color="danger">
                {parsed.query}
                <span className="ml-2" style={{ fontSize: "1.2rem" }}>
                  &times;
                </span>
              </Badge>
            </h5>
          )}
        </div>

        {noResultWarning ? (
          <div className="text-center my-5">
            <h5>No results found.</h5>
          </div>
        ) : (
          <React.Fragment>
            <div>
              {this.props.users.users.length !== 0 && (
                <h1 className="float-right " style={{ fontSize: "0.9rem" }}>
                  {this.props.users.userCount} results
                </h1>
              )}
            </div>

            <div className="bg-light mt-5" style={{ borderRadius: 10 }}>
              <Row className="w-100">
                <Col>
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="table-borderless">
                        <tr>
                          <th scope="col">NAME</th>
                          <th scope="col">EMAIL</th>
                          <th scope="col">MEMBER SINCE</th>
                          <th scope="col">TOTAL ORDERS</th>
                          <th scope="col">STATUS</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.users.users.length > 0 &&
                          this.props.users.users.map((item) => (
                            <tr
                              onClick={() => this.handleUserClick(item)}
                              key={item._id}
                              className="admin-each-product"
                              style={{
                                cursor: "pointer",
                                color: item.isAdmin
                                  ? "green"
                                  : !item.registeredUser && "red",
                              }}
                            >
                              <td
                                style={{ verticalAlign: "middle" }}
                                className="font-weight-bold"
                              >
                                {item.firstName &&
                                  this.ucFirstAllWords(item.firstName)}{" "}
                                {item.lastName &&
                                  this.ucFirstAllWords(item.lastName)}
                              </td>
                              <td
                                style={{ verticalAlign: "middle" }}
                                className="font-weight-bold"
                              >
                                {item.email}
                              </td>
                              <td
                                style={{ verticalAlign: "middle" }}
                                className="font-weight-bold"
                              >
                                {moment(item.dateCreated).format("MMM Do YY")}
                              </td>
                              <td
                                style={{ verticalAlign: "middle" }}
                                className="font-weight-bold"
                              >
                                {" "}
                                {item.orders.length}
                              </td>
                              <td
                                style={{ verticalAlign: "middle" }}
                                className={
                                  item.isActive
                                    ? `font-weight-bold`
                                    : item.sendOffers
                                    ? `font-weight-bold text-warning`
                                    : `font-weight-bold text-danger`
                                }
                              >
                                {" "}
                                {item.isActive ? "Active" : "Not Active"}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="text-center my-3">
                  {this.props.users.userCount >
                    this.props.users.users.length && (
                    <Button
                      onClick={this.loadMore}
                      outline
                      color="dark"
                      disabled={this.props.users.loading}
                    >
                      {this.props.users.loading ? "Loading" : "Load More"}
                    </Button>
                  )}
                </Col>
              </Row>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
});

export default connect(mapStateToProps, {
  getUserList,
  updatedUsers,
  updatedUserCount,
})(UserDashboard);
