import {
    GET_PRODUCTS,
    PRODUCT_LOADING,
    PRODUCT_LOADING_RESET,
    GET_PRODUCT_COUNT,
    GET_SINGLE_PRODUCT,
    RESET_PRODUCTS,
    SINGLE_PRODUCT_LOADING,
    RESET_SINGLE_PRODUCT_LOADING,
    GET_YOU_MAY_ALSO_LIKE_PRODUCTS,
    RESET_YOU_MAY_ALSO_LIKE_PRODUCTS,
    GET_BEST_SELLERS
} from '../actions/types'


const initialState = {
    products: [],
    totalPageNumbers: 0,
    productLoading: false,
    product: {},
    singleProductLoading: false,
    youMayAlsoLike: [],
    bestSellers: []


}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_BEST_SELLERS:
            return {
                ...state,
                bestSellers: action.payload
            }
        case GET_YOU_MAY_ALSO_LIKE_PRODUCTS:
            return {
                ...state,
                youMayAlsoLike: action.payload
            }
        case RESET_YOU_MAY_ALSO_LIKE_PRODUCTS:
            return {
                ...state,
                youMayAlsoLike: []
            }

        case SINGLE_PRODUCT_LOADING:
            return {
                ...state,
                singleProductLoading: true
            }
        case RESET_SINGLE_PRODUCT_LOADING:
            return {
                ...state,
                singleProductLoading: false
            }

        case GET_PRODUCTS:
            return {
                ...state,
                products: action.payload
            }
        case GET_PRODUCT_COUNT:
            return {
                ...state,
                totalPageNumbers: action.payload
            }
        case PRODUCT_LOADING:
            return {
                ...state,
                productLoading: true
            }
        case PRODUCT_LOADING_RESET:
            return {
                ...state,
                productLoading: false
            }
        case RESET_PRODUCTS:
            return {
                ...state,
                products: []
            }
        case GET_SINGLE_PRODUCT:
            return {
                ...state,
                product: action.payload
            }

        default: return state;
    }
}