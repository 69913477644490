import React, { Component } from 'react'
import TotalUsers from './TotalUsers'
import TotalRevenue from './TotalRevenue'
import TotalOrders from './TotalOrders'
import {
    Row, Col
} from 'reactstrap';
import Pusher from 'pusher-js';
import { connect } from 'react-redux'
import { changeStreamRevenueData } from '../../actions/adminRevenueAction'
import { changeStreamUserData } from '../../actions/adminUserActions'
import { changeStreamSaleStats } from '../../actions/saleStats'
import YesterdayRevenue from './YesterdayRevenue';
import TotalOrdersYesterday from './TotalOrdersYesterday';



class AdminTopBar extends Component {

    componentWillUnmount() {
        this.channel.unbind()
    }

    componentDidMount() {
        this.pusher = new Pusher('1ede5d88839c3e05076c', {
            cluster: 'ap4',
            encrypted: true,
        });

        this.channel = this.pusher.subscribe('mongodbChanges');
        this.channel.bind('orderTableUpdate', function (data) {
            this.props.changeStreamRevenueData(data)
            this.props.changeStreamSaleStats(data.stats)


        }.bind(this));


        this.channel.bind('userTableUpdate', function (data) {
            this.props.changeStreamUserData(data)
        }.bind(this));

    }
    render() {
        return (
            <div>
                <Row className=' text-center ' >
                    <Col lg='4' md='12'>
                        <TotalRevenue />
                    </Col>
                    <Col lg='4' md='12'>
                        <TotalOrders />
                    </Col>
                    <Col lg='4' md='12'>
                        <TotalUsers />
                    </Col>
                </Row>
                <Row className=' text-center ' >
                    <Col >
                        <YesterdayRevenue />
                    </Col>
                    <Col >
                        <TotalOrdersYesterday />
                    </Col>

                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    adminRevenueCalc: state.adminRevenueCalc
})

export default connect(mapStateToProps, { changeStreamRevenueData, changeStreamUserData, changeStreamSaleStats })(AdminTopBar)