import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import {
  deleteSelectedAddress,
  newAddress,
  editAddress,
} from "../actions/formActions";
import classnames from "classnames";

class Addresses extends Component {
  state = {
    modal: false,
    selected: null,
    showNewAddressForm: false,
    address: "",
    city: "",
    postcode: "",
    region: "",
    country: "Australia",
    editRegion: "",
    editAddressId: "",
    editAddress: "",
    editCity: "",
    editPostcode: "",
    editCountry: "",
    editShowSetAsDefault: false,
    setAsDefault: false,
    errors: {},
    showEditForm: false,
    editSetAsDefault: false,
  };

  handleEditForm = (address) => {
    if (this.state.showEditForm) {
      if (address.streetAdress !== this.state.editAddress) {
        if (address.addressType === "default") {
          this.setState({
            showEditForm: true,
            editAddressId: address._id,
            editAddress: address.streetAdress,
            editCity: address.city,
            editRegion: address.region,
            editPostcode: address.zipCode,
            editShowSetAsDefault: false,
          });
        } else {
          this.setState({
            showEditForm: true,
            editAddressId: address._id,
            editAddress: address.streetAdress,
            editCity: address.city,
            editRegion: address.region,
            editPostcode: address.zipCode,
            editShowSetAsDefault: true,
          });
        }
      } else {
        this.setState({
          showEditForm: false,
          editAddress: "",
          editCity: "",
          editPostcode: "",
          editRegion: "",
          editAddressId: "",
          editShowSetAsDefault: false,
        });
      }
    } else {
      if (address.addressType === "default") {
        this.setState({
          showEditForm: true,
          editAddressId: address._id,
          editAddress: address.streetAdress,
          editCity: address.city,
          editPostcode: address.zipCode,
          editRegion: address.region,
        });
      } else {
        this.setState({
          showEditForm: true,
          editAddressId: address._id,
          editAddress: address.streetAdress,
          editCity: address.city,
          editPostcode: address.zipCode,
          editRegion: address.region,
          editShowSetAsDefault: true,
        });
      }
    }
  };

  handleCheckBox = (e) => {
    this.setState({
      setAsDefault: !this.state.setAsDefault,
    });
  };
  editHandleCheckBox = (e) => {
    this.setState({
      editSetAsDefault: !this.state.editSetAsDefault,
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.auth.user.adress !== prevProps.auth.user.adress) {
      this.setState({
        showNewAddressForm: false,
        address: "",
        city: "",
        postcode: "",
        region: "",
        errors: {},
        editAddressId: "",
        editAddress: "",
        editCity: "",
        editPostcode: "",
        editRegion: "",
        editShowSetAsDefault: false,
        showEditForm: false,
        editSetAsDefault: false,
      });
    }
    if (this.props.errors !== prevProps.errors) {
      this.setState({
        errors: this.props.errors,
      });
    }
  }
  setSelectedAddress = (address) => {
    this.setState(
      {
        selected: address,
      },
      () => {
        this.toggle();
      }
    );
  };
  toggle = (e) => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  deleteAddress = (e) => {
    this.setState({
      modal: false,
    });
    let data = {};
    data.user = this.props.auth.user;
    data.address = this.state.selected;
    this.props.deleteSelectedAddress(data);
    this.setState({
      selected: null,
    });
  };
  handleNewAddress = (e) => {
    this.setState({
      showNewAddressForm: !this.state.showNewAddressForm,
    });
  };
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  addNewAddress = (e) => {
    let data = {};
    data.user = this.props.auth.user;
    data.streetAdress = this.state.address;
    data.city = this.state.city;
    data.zipCode = this.state.postcode;
    data.region = this.state.region;
    data.country = this.state.country;
    data.addNewAddress = true;
    if (this.state.setAsDefault) {
      data.setAsDefault = true;
    }
    this.props.newAddress(data);
  };

  editUserAddress = (e) => {
    let data = {};
    data.user = this.props.auth.user;
    data.editAddressId = this.state.editAddressId;
    data.editStreetAdress = this.state.editAddress;
    data.editCity = this.state.editCity;
    data.editPostcode = this.state.editPostcode;
    data.editRegion = this.state.editRegion;
    data.editSetAsDefault = this.state.editSetAsDefault;
    data.editAddress = true;
    this.props.editAddress(data);
    this.setState({
      showEditForm: false,
    });
  };
  render() {
    return (
      <div className="dashboard-view">
        <div>
          <Helmet>
            <title>Addresses - Remote Trails</title>
            <meta name="description" content="Addresses - Remote Trails" />
          </Helmet>
        </div>
        <Row className="mx-5 my-3 border-bottom py-3">
          <Col>
            <h4 style={{ fontWeight: "bolder" }}>My Account</h4>
          </Col>
        </Row>
        <Row className="mx-5 my-3  py-3">
          <Col>
            <Button
              onClick={this.handleNewAddress}
              color="success"
              className="btn font-weight-bold"
            >
              Add New Address
            </Button>
          </Col>
        </Row>
        {this.state.showNewAddressForm && (
          <Row className="mx-5">
            <Col>
              <Form className="pt-3">
                <FormGroup>
                  <Input
                    className={classnames("", {
                      "is-invalid": this.state.errors.streetAdress,
                    })}
                    placeholder="Address*"
                    type="text"
                    onChange={this.onChange}
                    value={this.state.address}
                    name="address"
                  />
                  <div className="invalid-feedback">
                    {this.state.errors.streetAdress}
                  </div>
                </FormGroup>

                <FormGroup>
                  <Input
                    className={classnames("", {
                      "is-invalid": this.state.errors.city,
                    })}
                    placeholder="City*"
                    type="text"
                    onChange={this.onChange}
                    value={this.state.city}
                    name="city"
                  />
                  <div className="invalid-feedback">
                    {this.state.errors.city}
                  </div>
                </FormGroup>

                <Row form>
                  {this.state.country !== "United Kingdom" && (
                    <Col md={6}>
                      <FormGroup>
                        <Input
                          className={classnames("", {
                            "is-invalid": this.state.errors.region,
                          })}
                          placeholder={
                            this.state.country === "New Zealand"
                              ? "Region*"
                              : "State*"
                          }
                          type="text"
                          onChange={this.onChange}
                          value={this.state.region}
                          name="region"
                        />
                        <div className="invalid-feedback">
                          {this.state.errors.region}
                        </div>
                      </FormGroup>
                    </Col>
                  )}

                  <Col md={this.state.country === "United Kingdom" ? 12 : 6}>
                    <FormGroup>
                      <Input
                        className={classnames("", {
                          "is-invalid": this.state.errors.zipCode,
                        })}
                        placeholder="Postal Code*"
                        type="text"
                        onChange={this.onChange}
                        value={this.state.postcode}
                        name="postcode"
                      />
                      <div className="invalid-feedback">
                        {this.state.errors.zipCode}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>

                <Row form>
                  <Col>
                    <FormGroup>
                      <Input
                        className={classnames("", {
                          "is-invalid": this.state.errors.country,
                        })}
                        placeholder="Country"
                        type="select"
                        onChange={this.onChange}
                        value={this.state.country}
                        name="country"
                      >
                        <option>Australia</option>
                        <option>New Zealand</option>

                        <option>Canada</option>
                        <option>United Kingdom</option>
                        <option>United States</option>
                      </Input>

                      <div className="invalid-feedback">
                        {this.state.errors.country}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>

                {this.props.auth.user.adress.length !== 0 && (
                  <FormGroup className="ml-4">
                    <Label check>
                      <Input
                        type="checkbox"
                        id="handleCheckBox"
                        onClick={this.handleCheckBox}
                      />
                      Set as Default
                    </Label>
                  </FormGroup>
                )}
              </Form>
              <Button onClick={this.addNewAddress} outline color="dark">
                ADD NEW ADDRESS
              </Button>
            </Col>
          </Row>
        )}
        <Row className="mx-5 my-3  py-3">
          <Col>
            <h5 className="border-bottom" style={{ fontWeight: "bold" }}>
              Customer Address Book
            </h5>

            <div>
              <p>
                {this.props.auth.user.firstName} {this.props.auth.user.lastName}
              </p>

              <Row>
                {this.props.auth.user.adress.length !== 0 &&
                  this.props.auth.user.adress
                    .sort(function (left, right) {
                      return left.hasOwnProperty("addressType")
                        ? -1
                        : right.hasOwnProperty("addressType")
                        ? 1
                        : 0;
                    })
                    .map((eachAdress) => (
                      <Col
                        className=" my-3"
                        key={eachAdress._id}
                        md="3"
                        sm="4"
                        xs="6"
                      >
                        <p style={{ minHeight: "80px" }}>
                          <span>
                            {" "}
                            {eachAdress.streetAdress}{" "}
                            {eachAdress.addressType && `(default)`}
                          </span>{" "}
                          <br />
                          <span>
                            {" "}
                            {eachAdress.city} {eachAdress.state}{" "}
                            {eachAdress.zipCode} {eachAdress.country}{" "}
                          </span>
                        </p>
                        <Button
                          onClick={() => this.setSelectedAddress(eachAdress)}
                          color="danger"
                          outline
                          className="btn btn-sm font-weight-bold d-inline"
                        >
                          Delete
                        </Button>
                      </Col>
                    ))}
              </Row>

              {this.props.auth.user.adress.length === 0 && (
                <div>
                  <p className="font-weight-bold">
                    You don't have any saved addresses.
                  </p>
                </div>
              )}

              <Modal isOpen={this.state.modal} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle}>
                  Are you sure you wish to delete the following address?
                </ModalHeader>
                <ModalBody className="font-weight-bold">
                  {this.state.selected !== null && (
                    <p>
                      <span> {this.state.selected.streetAdress} </span> <br />
                      <span>
                        {" "}
                        {this.state.selected.city} {this.state.selected.region}{" "}
                        {this.state.selected.zipCode}{" "}
                      </span>
                    </p>
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" onClick={this.deleteAddress}>
                    Delete
                  </Button>{" "}
                  <Button color="secondary" onClick={this.toggle}>
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  errors: state.errors,
  auth: state.form,
  cart: state.cart,
});

export default connect(mapStateToProps, {
  deleteSelectedAddress,
  newAddress,
  editAddress,
})(Addresses);
