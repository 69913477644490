
import {
    SET_CURRENT_USER,
    VERIFY_USER,
    RESET_LOCALSTORAGE_USER,
    RESET_PASSWORD,
    RESET_SHOW_RESET_PASSWORD,
    PASSWORD_UPDATED,
    RESEND_VERIFICATION,
    SHOW_SPINNER,
    RESET_SPINNER,
    REGISTRATION_SUCCESSFUL,
    RESET_SIGNUP,
    RESET_FORM_REDUCER
} from '../actions/types'
import isEmpty from '../is-empty'


const initialState = {
    isAuthenticated: false,
    user: {},
    verified: null,
    verifyloading: false,
    checkUser: {
        userName: null,
        logInTime: null
    },
    showResetPasswordSuccess: false,
    passwordUpdated: false,
    resendLink: false,
    spinner: false,
    showRegistrationSuccess: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case RESET_SIGNUP:
            return {
                ...state,
                showRegistrationSuccess: false
            }
        case REGISTRATION_SUCCESSFUL:
            return {
                ...state,
                showRegistrationSuccess: true
            }
        case RESEND_VERIFICATION:
            return {
                ...state,
                resendLink: true
            }
        case PASSWORD_UPDATED:
            return {
                ...state,
                passwordUpdated: true
            }
        case RESET_SHOW_RESET_PASSWORD:
            return {
                ...state,
                showResetPasswordSuccess: false
            }
        case RESET_PASSWORD:
            return {
                ...state,
                showResetPasswordSuccess: true
            }
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload,
                checkUser: {
                    userName: action.payload.firstName,
                    logInTime: Date.now()
                },
                spinner: false
            }

        case VERIFY_USER:
            return {
                ...state,
                verified: action.payload

            }
        case RESET_LOCALSTORAGE_USER:
            return {
                ...state,
                checkUser: {
                    userName: null,
                    logInTime: null
                },
                isAuthenticated: false,
                user: {},
                spinner: false
            }

        case SHOW_SPINNER:
            return {
                ...state,
                spinner: true

            }
        case RESET_SPINNER:
            return {
                ...state,
                spinner: false
            }
        case RESET_FORM_REDUCER:
            return {
                ...state,
                isAuthenticated: false,
                user: {},
                verified: null,
                verifyloading: false,
                checkUser: {
                    userName: null,
                    logInTime: null
                },
                showResetPasswordSuccess: false,
                passwordUpdated: false,
                resendLink: false,
                spinner: false,
                showRegistrationSuccess: false
            }

        default: return state;
    }
}