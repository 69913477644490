import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Feature from "./Feature";

const features = [
  {
    title: "Free Shipping.",
    paragraph:
      "We offer free shipping on all domestic orders over $100 and international orders above $150.",
    icon: "fas fa-shipping-fast",
  },
  {
    title: "Australian Owned.",
    paragraph:
      "Remote Trails is proudly Australian owned and operated from Melbourne, Victoria.",
    icon: "far fa-compass",
  },
  {
    title: "Sustainable Fabrics.",
    paragraph:
      "At Remote Trails, we work closely with our suppliers to source only 100% organic cotton and recycled materials.",
    icon: "fas fa-leaf",
  },
  {
    title: "We give back for every sale.",
    paragraph:
      "We’ve pledged 10% of sales to the preservation and restoration of the natural environment.",
    icon: "fas fa-percent",
  },
];

class Features extends Component {
  render() {
    return (
      <div className="my-5">
        <Feature features={features} />
      </div>
    );
  }
}

export default withRouter(Features);
