import React, { Component } from "react";
import FooterLinks from "./FooterLinks";
import FooterTitle from "./FooterTitle";

const links = [
  {
    title: "Help Centre",
    route: "/help-centre",
  },
  {
    title: "Order Status",
    route: "/order-status",
  },
  // {
  //   title: "Returns & Exchanges",
  //   route: "",
  // },
  {
    title: "Frequently Asked Questions",
    route: "/faqs",
  },
  {
    title: "Become a Stockist",
    route: "/becomestockist",
  },
];

export default class NeedHelp extends Component {
  render() {
    return (
      <div>
        <div className="container">
          <div>
            <FooterTitle title="Need Help" />
            <FooterLinks links={links} />
          </div>
        </div>
      </div>
    );
  }
}
