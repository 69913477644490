import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'

export default class Feature extends Component {
    render() {
        return (
            <div className='container'>
                <Row  >
                    {this.props.features.map((feature, index) => (
                        <Col md='3' sm='6' xs='12' key={index}>
                            <div className='py-4 px-3 my-3' >
                                <i className={`${feature.icon} fa-4x`}></i>
                                <h1 style={{ fontSize: 16 }} className='my-4 font-weight-bold'>{feature.title}</h1>
                                <p style={{ fontSize: 14 }} className='my-4'>{feature.paragraph}</p>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        )
    }
}
