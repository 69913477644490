import React from "react";
import { InputGroup, Label, Input } from "reactstrap";
import { connect } from "react-redux";
import { registerUser, resetForm } from "../actions/formActions";
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

class RegisterForm extends React.Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    password2: "",
    errors: {},
    registeredUser: true,
    showSuccessMessage: false,
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    let newUser = {
      registeredUser: this.state.registeredUser,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email.toLowerCase(),
      password: this.state.password,
      password2: this.state.password2,
      cart: this.props.cart.cart,
    };

    this.props.registerUser(newUser, this.props.history);
  };

  componentWillUnmount() {
    this.props.resetForm();
  }

  componentDidUpdate(prevProps) {
    if (this.props.errors !== prevProps.errors) {
      this.setState({
        errors: this.props.errors,
      });
    }

    if (
      this.props.auth.showRegistrationSuccess !==
        prevProps.auth.showRegistrationSuccess &&
      this.props.auth.showRegistrationSuccess
    ) {
      this.setState({
        showSuccessMessage: true,
      });

      setTimeout(this.routeToLogin, 3000);
    }
  }

  routeToLogin = () => {
    this.props.setFormType();
  };

  render() {
    const { errors } = this.state;

    return (
      <div>
        <div>
          <Helmet>
            <title>Sign Up - Remote Trails</title>
            <meta name="description" content="Sign Up - Remote Trails" />
          </Helmet>
        </div>
        {this.props.history.location.pathname !== "/order-status" && (
          <h1 className="text-left " style={{ color: "#3d4246", fontSize: 36 }}>
            Create New Customer Account
          </h1>
        )}

        <form onSubmit={this.onSubmit}>
          <InputGroup className="my-2">
            <Input
              placeholder="First Name"
              onChange={this.onChange}
              className={classnames("", { "is-invalid": errors.firstName })}
              value={this.state.firstName}
              name="firstName"
            />
            <div className="invalid-feedback" style={{ fontSize: 12 }}>
              {errors.firstName}
            </div>
          </InputGroup>
          <InputGroup className="my-2">
            <Input
              placeholder="Last Name"
              onChange={this.onChange}
              className={classnames("", { "is-invalid": errors.lastName })}
              value={this.state.lastName}
              name="lastName"
            />
            <div className="invalid-feedback" style={{ fontSize: 12 }}>
              {errors.lastName}
            </div>
          </InputGroup>
          <InputGroup className="my-2">
            <Input
              placeholder="Email"
              onChange={this.onChange}
              className={classnames("", { "is-invalid": errors.email })}
              value={this.state.email}
              name="email"
            />
            <div className="invalid-feedback" style={{ fontSize: 12 }}>
              {errors.email}
            </div>
          </InputGroup>

          <InputGroup className="my-2">
            <Input
              autoComplete="on"
              type="password"
              placeholder="Password"
              onChange={this.onChange}
              className={classnames("", { "is-invalid": errors.password })}
              value={this.state.password}
              name="password"
            />
            <div className="invalid-feedback" style={{ fontSize: 12 }}>
              {errors.password}
            </div>
          </InputGroup>

          <InputGroup className="my-2">
            <Input
              autoComplete="on"
              type="password"
              placeholder="Confirm password"
              onChange={this.onChange}
              className={classnames("", { "is-invalid": errors.password2 })}
              value={this.state.password2}
              name="password2"
            />
            <div className="invalid-feedback" style={{ fontSize: 12 }}>
              {errors.password2}
            </div>
          </InputGroup>

          <InputGroup className="ml-4 ">
            <Input
              type="checkbox"
              className="form-check-input"
              id="invalidCheck"
              required
            />
            <Label
              check
              className="form-check-label text-muted"
              style={{ fontSize: 14 }}
              for="invalidCheck"
            >
              I want to receive news and offers from Remote Trails. By signing
              up, I agree to the{" "}
              <a href="/privacypolicy" style={{ color: "rgb(223,149,143)" }}>
                privacy policy
              </a>{" "}
              and{" "}
              <a href="/termsofuse" style={{ color: "rgb(223,149,143)" }}>
                terms and conditions
              </a>
              .
            </Label>
          </InputGroup>

          {this.state.showSuccessMessage && (
            <div>
              <p className="text-success" style={{ fontSize: 14 }}>
                Your account has been successfully created. You're being
                redirected to login page.
              </p>
            </div>
          )}

          <Input
            style={{
              borderRadius: "20px",
              fontWeight: 700,
              background: "black",
              color: "white",
              cursor: "pointer",
            }}
            disabled={
              this.state.name === "" ||
              this.state.email === "" ||
              this.state.password === "" ||
              this.state.password2 === ""
                ? true
                : false
            }
            type="submit"
            value={
              this.props.auth.spinner ? "Please Wait.." : "Create an Account"
            }
            className="w-100 py-2 border-0 mt-4"
          />

          <p className="text-center my-4">
            <span
              onClick={this.props.setFormType}
              style={{
                fontSize: 14,
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Back
            </span>
          </p>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  errors: state.errors,
  auth: state.form,
  cart: state.cart,
});

export default connect(mapStateToProps, { registerUser, resetForm })(
  withRouter(RegisterForm)
);
