import React, { useState } from "react";
import { Collapse } from "reactstrap";

const features = [
  {
    beanie: [
      "Wash 30 deg C",
      "Wash with similar colours",
      "Do not tumble dry",
      "Do not bleach",
    ],
    tshirt: [
      `Wash at 30 deg C`,
      "Wash with similar colours",
      "Do not bleach",
      "Do not tumble dry",
      "Cool iron on reverse",
      "Do not dry clean",
    ],
    cap: [
      "Spot clean if able",
      "Cold hand wash inside out",
      "Dry in shade",
      "Do not iron",
      "Do not bleach",
      "Do not tumble dry",
    ],
  },
];

export default function CareGuide() {
  const [open, setOpen] = useState(true);

  const path = window.location.pathname;

  let feature;

  if (path.includes("Cap")) {
    feature = "cap";
  } else if (path.includes("Beanie")) {
    feature = "beanie";
  } else {
    feature = "tshirt";
  }

  return (
    <div className="mt-3 ">
      <div>
        <p
          className="font-weight-bold border-bottom"
          style={{ fontSize: 14, cursor: "pointer" }}
          onClick={() => setOpen(!open)}
        >
          Care Guide
          <span className="float-right">
            <i
              className={
                open ? "fa-solid fa-caret-up" : "fa-solid fa-caret-down"
              }
            />
          </span>
        </p>
      </div>

      <Collapse isOpen={open}>
        <p style={{ fontSize: 14 }} className=" pb-3">
          <ul className="m-0 px-3">
            {features[0][feature].map((item) => (
              <li>{item}</li>
            ))}
            {/* <li>Wash at 30&#8451;</li>
            <li>Wash with similar colours</li>
            <li>Do not bleach</li>
            <li>Do not tumble dry</li>
            <li>Cool iron on reverse</li>
            <li>Do not dry clean</li> */}
          </ul>
        </p>
      </Collapse>
    </div>
  );
}
