import React, { Component } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import logo from "../../images/remote-trails-logo.png";
export default class SideBar extends Component {
  render() {
    return (
      <div className="d-none d-lg-block ">
        <Navbar
          className=" fixed-top"
          style={{
            height: "100vh",
            display: "block",
            backgroundColor: "white !important",
            width: 250,
          }}
          light
          expand="md"
        >
          <NavbarBrand href="/">
            <img
              className=" w-100"
              src={logo}
              alt="Remoet Trails Apparel & Gear"
            />
          </NavbarBrand>
          <NavbarToggler />
          <Collapse navbar>
            <Nav className="mt-3 flex-column" navbar style={{ top: 0 }}>
              <NavItem>
                <NavLink href="/adminpanel" className="px-4">
                  <i
                    className="fas fa-tv  text-primary pr-2"
                    style={{ fontSize: 14 }}
                  />
                  Dashboard
                </NavLink>
                <NavLink href="/adminpanel/orders" className="px-4">
                  <i
                    className="fas fa-shopping-cart  text-secondary pr-2"
                    style={{ fontSize: 14 }}
                  />
                  Orders
                </NavLink>
                <NavLink href="/adminpanel/users" className="px-4">
                  <i
                    className="fas fa-user-friends  text-success pr-2"
                    style={{ fontSize: 14 }}
                  />
                  Users
                </NavLink>
                <NavLink href="/adminpanel/products" className="px-4">
                  <i
                    className="fas fa-tag  text-danger pr-2"
                    style={{ fontSize: 14 }}
                  />
                  Products
                </NavLink>
                {/* <NavLink href="/adminpanel/faqs" className='px-4'>
                                    <i className="fas fa-question  text-info pr-2" style={{ fontSize: 14 }} />
                                    FAQ's</NavLink> */}
                <NavLink href="/adminpanel/coupons" className="px-4">
                  <i
                    className="fas fa-percent  text-muted pr-2"
                    style={{ fontSize: 14 }}
                  />
                  Coupons
                </NavLink>

                <NavLink href="/adminpanel/reviews" className="px-4">
                  <i
                    className="far fa-comment  text-dark pr-2"
                    style={{ fontSize: 14 }}
                  />
                  Reviews
                </NavLink>
                <NavLink href="/adminpanel/categories" className="px-4">
                  <i
                    className="fas fa-th-list  text-dark pr-2"
                    style={{ fontSize: 14 }}
                  />
                  Categories
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}
