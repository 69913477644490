import React, { Component } from "react";
import classnames from "classnames";
import { Row, Col, Button, Alert } from "reactstrap";
import { Helmet } from "react-helmet";
import {
  createNewCategory,
  resetCategoryReducer,
} from "../../actions/adminCategoryActions";
import { connect } from "react-redux";
import SuperForm from "./SuperForm";
import AlertBox from "./AlertBox";
import StandardButton from "./StandardButton";

class NewCategory extends Component {
  state = {
    mainLabel: "*All fields are required. The form is case sensitive.",
    inputGroups: [
      {
        title: "Title",
        name: "name",
        placeholder: "e.g. Christmas",
        value: "",
        type: "text",
      },
    ],
    isVisible: "",
    radioGroups: {
      title: "Visiblity",
      buttons: [
        {
          id: "visible",
          label: "Visible",
          value: "visible",
        },
        {
          id: "hidden",
          label: "Hidden",
          value: "hidden",
        },
      ],
    },
    errors: {},
    showSuccessAlert: false,
    loading: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.categories.errors !== prevProps.categories.errors) {
      this.setState({
        errors: this.props.categories.errors,
        loading: false,
      });
    }
    if (
      this.props.categories.newCategoryCreated !==
        prevProps.categories.newCategoryCreated &&
      this.props.categories.newCategoryCreated
    ) {
      this.setState({
        showSuccessAlert: true,
        loading: false,
      });
    }
  }

  handleRadioButton = (e) => {
    this.setState({
      isVisible: e.target.id,
    });
  };

  onChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      inputGroups: this.state.inputGroups.map((x) =>
        x.name === name ? { ...x, value: value } : x
      ),
      showSuccessAlert: this.state.showSuccessAlert && false,
    });

    if (this.props.categories.newCategoryCreated) {
      this.props.resetCategoryReducer();
    }
  };

  handleNewCategory = (e) => {
    this.setState({
      loading: true,
    });
    let data = {};

    data.name = this.state.name;

    for (let i = 0; i < this.state.inputGroups.length; i++) {
      data[this.state.inputGroups[i].name] = this.state.inputGroups[i].value;
    }

    if (this.state.isVisible === "hidden") {
      data.isVisible = false;
    } else {
      data.isVisible = true;
    }
    this.props.createNewCategory(data);
  };

  render() {
    const { mainLabel, inputGroups, radioGroups, errors, isVisible, loading } =
      this.state;

    let disable;

    if (inputGroups[0].value === "") {
      disable = true;
    } else {
      disable = false;
    }

    return (
      <div>
        <div>
          <Helmet>
            <title>New Category - Remote Trails</title>
            <meta name="description" content="New Category - Remote Trails" />
          </Helmet>
        </div>
        <Row>
          <Col>
            <SuperForm
              mainLabel={mainLabel}
              inputGroups={inputGroups}
              radioGroups={radioGroups}
              onChange={this.onChange}
              errors={errors}
              isVisible={isVisible}
              handleRadioButton={this.handleRadioButton}
            />
          </Col>
        </Row>

        {this.state.showSuccessAlert && (
          <Row>
            <Col>
              <AlertBox
                color="success"
                title="Category succesfully created."
                push="/adminpanel/categories"
                subText="to go back to the Categories Dashboard."
              />
            </Col>
          </Row>
        )}

        <Row>
          <Col>
            <StandardButton
              size="sm"
              color="success"
              onClick={this.handleNewCategory}
              label="Create"
              loading={loading}
              disable={disable || loading}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: state.adminCategories,
});

export default connect(mapStateToProps, {
  createNewCategory,
  resetCategoryReducer,
})(NewCategory);
