import {
    GUEST_DATA_SAVED, BUTTON_LOADING
    , CHECKOUT_FORM_ERROR,
    INPUT_UPDATE,
    RADIOBOX_UPDATE,
    SET_SHIPPING_FEE,
    SET_SHIPPING_METHOD,
    SET_DATE,
    SET_PAYMENT_TYPE,
    SET_CARD_NUMBER,
    SET_CARD_EXPIRY,
    SET_CARD_CVC,
    CLICK_PAY,
    RESET_CHECKOUT_FORM,
    USER_CHECKOUT_VERIFIED,
    SET_PICKUP_TIME,
    SHIPPING_DATA_VALIDATED,
    TRANSPARENT_FULL_PAGE_LOADING,
    RESET_FULL_PAGE_LOADING_CHECKOUT,
    RESET_CONTINUE_BUTTON,
} from '../actions/types.js'


const initialState = {
    buttonLoading: false,
    errors: {},
    email: '',
    firstname: '',
    lastname: '',
    address: '',
    city: '',
    postcode: '',
    sendOffers: true,
    guestUser: true,
    description: '',
    region: '',
    country: 'Australia',
    shippingFee: null,
    shippingMethod: null,
    date: null,
    paymentType: null,
    cardNumber: null,
    cardExpiry: null,
    cardCvc: null,
    payButton: false,
    nameOnCard: '',
    fullPageLoading: false,
    checkOutVerified: false,
    goGreen: false,
    phoneNumber: '',
}

export default function (state = initialState, action) {
    switch (action.type) {
        case RESET_FULL_PAGE_LOADING_CHECKOUT:
            return {
                ...state,
                fullPageLoading: false
            }
        case TRANSPARENT_FULL_PAGE_LOADING:
            return {
                ...state,
                fullPageLoading: true
            }
        case SHIPPING_DATA_VALIDATED:
            return {
                ...state,
                buttonLoading: false,
                errors: {}
            }
        case SET_PICKUP_TIME:
            return {
                ...state,
                pickupTime: action.payload
            }
        case USER_CHECKOUT_VERIFIED:
            return {
                ...state,
                buttonLoading: false,
                checkOutVerified: true,
                errors: {}
            }
        case RESET_CHECKOUT_FORM:
            return {
                ...initialState
            }
        case CLICK_PAY:
            return {
                ...state,
                payButton: true,
                buttonLoading: true
            }
        case SET_CARD_NUMBER:
            return {
                ...state,
                cardNumber: action.payload
            }
        case SET_CARD_EXPIRY:
            return {
                ...state,
                cardExpiry: action.payload
            }
        case SET_CARD_CVC:
            return {
                ...state,
                cardCvc: action.payload
            }
        case SET_PAYMENT_TYPE:
            return {
                ...state,
                paymentType: action.payload
            }
        case SET_DATE:
            return {
                ...state,
                date: action.payload
            }
        case SET_SHIPPING_METHOD:
            return {
                ...state,
                shippingMethod: action.payload
            }
        case SET_SHIPPING_FEE:
            return {
                ...state,
                shippingFee: action.payload
            }
        case INPUT_UPDATE:
            return {
                ...state,
                [action.payload.field]: action.payload.value,
            }
        case RADIOBOX_UPDATE:
            return {
                ...state,
                sendOffers: action.payload,
            }


        case GUEST_DATA_SAVED:
            return {
                ...state,
                buttonLoading: false,
                errors: {}
            }
        case BUTTON_LOADING:
            return {
                ...state,
                buttonLoading: true,
                payButton: false
            }
        case RESET_CONTINUE_BUTTON:
            return {
                ...state,
                buttonLoading: false,
                payButton: false
            }
        case CHECKOUT_FORM_ERROR:
            return {
                ...state,
                errors: action.payload,
                buttonLoading: false,
                payButton: false,
                fullPageLoading: false
            }
        default: return state;
    }
}