import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import shippingPolicy from "./Policies/ShippingPolicy.json";
import { Helmet } from "react-helmet";

export default class ShippingPolicy extends Component {
  render() {
    return (
      <div>
        <div>
          <Helmet>
            <title>
              Shipping & Delivery | Remote Trails Outdoor Apparel + Gear |
              Proudly Australian owned and operated from Melbourne | We give
              back for every sale | Sustainable Outdoor Apparel + Gear
            </title>
            <meta
              name="description"
              content="  Shipping & Delivery | Remote Trails | Proudly Australian owned and operated from Melbourne | We give back for every sale | Sustainable Outdoor Apparel + Gear | Free Shipping over $100 on all domestic orders"
            />
          </Helmet>
        </div>
        <div className="container">
          <div>
            <div>
              <p className="mb-0 d-inline pr-2">
                <a
                  href="/"
                  style={{
                    textDecoration: "underline",
                    color: "black",
                    fontSize: 14,
                  }}
                >
                  Remote Trails
                </a>{" "}
                >
              </p>
              <p className="mb-0 d-inline pr-2">
                <a
                  href="/help-centre"
                  style={{
                    textDecoration: "underline",
                    color: "black",
                    fontSize: 14,
                  }}
                >
                  Help Centre
                </a>{" "}
                >
              </p>
              <p className="mb-0 d-inline">
                <a
                  href="/shipping-policy"
                  style={{
                    textDecoration: "underline",
                    color: "black",
                    fontSize: 14,
                  }}
                >
                  Shipping & Delivery
                </a>
              </p>
            </div>

            <h1 className="font-weight-bold" style={{ fontSize: "1.25rem" }}>
              SHIPPING & DELIVERY
            </h1>
          </div>
        </div>

        <div className="container my-2">
          <Row>
            <Col>
              <div>
                {shippingPolicy[0].shippingPolicy.map((policy, index) => (
                  <div key={index} className="my-1">
                    <li> {policy.paragraph}</li>
                  </div>
                ))}
              </div>
              <div className="my-4">
                <p className="font-weight-bold">Australia </p>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Shipping Method</th>
                      <th scope="col">Cost(AU)</th>
                      <th scope="col">Estimated Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Click & Collect</td>
                      <td>N/A</td>
                      <td>N/A</td>
                    </tr>
                    <tr>
                      <td>Aus Post - Standard</td>
                      <td>
                        FREE SHIPPING on all orders over $100 <br /> $10 for
                        orders under $100
                      </td>
                      <td>
                        4-7 Business days for Australia wide including most
                        regional areas.
                      </td>
                    </tr>
                    <tr>
                      <td>Aus Post - Express</td>
                      <td>Available for $15 at checkout</td>
                      <td> 1-3 Business Days </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="my-4">
                <p className="font-weight-bold">Rest of the World </p>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Shipping Method</th>
                      <th scope="col">Cost(AU)</th>
                      <th scope="col">Estimated Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Click & Collect</td>
                      <td>N/A</td>
                      <td>N/A</td>
                    </tr>
                    <tr>
                      <td>DHL Express</td>
                      <td>
                        FREE SHIPPING on all orders over $150 <br /> $35 for
                        orders under $150
                      </td>
                      <td>Up to 10 business days</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
