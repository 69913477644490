import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { Helmet } from "react-helmet";
import { resetOrder } from "../actions/orderActions";
import { connect } from "react-redux";
import { resetCoupon } from "../actions/discountActions";

class OrderReceived extends Component {
  componentWillUnmount() {
    this.props.resetOrder();
    this.props.resetCoupon();
  }
  render() {
    if (this.props.location.state === undefined) {
      return <Redirect to="/" />;
    }

    let SubTotal;

    if (this.props.location.state.order.products) {
      SubTotal =
        Math.round(
          this.props.location.state.order.products
            .map((eachItem) => eachItem.productQuantity * eachItem.productPrice)
            .reduce((total, currentValue) => total + currentValue) * 100
        ) / 100;
    }

    let total = this.props.location.state.order.total / 100;

    let discount;

    if (this.props.location.state.order.coupon) {
      if (SubTotal >= this.props.location.state.order.coupon.minimumSpend) {
        if (this.props.location.state.order.coupon.isPercent) {
          discount =
            (SubTotal / 100) * this.props.location.state.order.coupon.amount;
        } else {
          discount = this.props.location.state.order.coupon.amount;
        }
      }
    }

    console.log(this.props.location.state.order);

    return (
      <div className="mx-3 my-5 container mx-auto">
        <div>
          <Helmet>
            <title>Order Confirmed - Remote Trails</title>
            <meta
              name="description"
              content="Order Confirmed - Remote Trails"
            />
          </Helmet>
        </div>
        <div>
          <div className="text-center">
            <i className=" far fa-check-circle fa-5x text-success"></i>
          </div>
          <h4 className="text-center  my-3  ">
            Thank you {this.props.location.state.order.firstName.toUpperCase()}!
          </h4>
        </div>

        <Row>
          <Col md="9" sm="12" className="mx-auto">
            <div>
              <p className="text-center" style={{ fontSize: "20px" }}>
                Your order is{" "}
                {this.props.location.state.order.orderStatus === "Confirmed"
                  ? "confirmed"
                  : "being processed"}
                , and your order number is
                <span style={{ color: "#a36349" }}>
                  {" "}
                  {this.props.location.state.order.orderid.toUpperCase()}.{" "}
                </span>
                You will soon receieve an email with your order details. We'll
                let you know when they're on their way.
              </p>
            </div>
          </Col>
        </Row>
        <div className="">
          <Row className=" pt-3">
            <Col md="9" sm="12" className="mx-auto">
              <table className="table border ">
                <thead className="table-borderless">
                  <tr>
                    <th scope="col">PRODUCT</th>
                    <th></th>
                    <th scope="col">QTY</th>
                    <th scope="col">PRICE</th>
                  </tr>
                </thead>
                <tbody className="border">
                  {this.props.location.state.order.products.map((item) => (
                    <tr key={item.productId}>
                      <td style={{ width: "100px" }}>
                        <img
                          src={item.productImage}
                          alt={item.productImage}
                          className="img-fluid"
                        />
                      </td>
                      <td
                        style={{ verticalAlign: "middle" }}
                        className="font-weight-bold"
                      >
                        {" "}
                        {item.productName} - {item.productSize}
                      </td>
                      <td
                        style={{ verticalAlign: "middle" }}
                        className="font-weight-bold"
                      >
                        {item.productQuantity}
                      </td>
                      <td
                        style={{ verticalAlign: "middle", width: "100px" }}
                        className="font-weight-bold text-muted"
                      >
                        $
                        {(
                          Math.round(
                            item.productQuantity * item.productPrice * 100
                          ) / 100
                        ).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tbody className="border-0">
                  <tr>
                    <td className="border-0"></td>
                    <td className="border-0"></td>
                    <td className="border-0">SubTotal</td>
                    <td className="border-0">AU ${SubTotal.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td className="border-0"></td>
                    <td className="border-0"></td>
                    <td className="border-0">Shipping</td>
                    <td className="border-0">
                      {this.props.location.state.order.shippingFee === 0
                        ? "Free"
                        : "AU $" +
                          this.props.location.state.order.shippingFee.toFixed(
                            2
                          )}
                    </td>
                  </tr>

                  {this.props.location.state.order.coupon &&
                    discount !== undefined && (
                      <React.Fragment>
                        <tr>
                          <td className="border-0"></td>
                          <td className="border-0"></td>
                          <td className="border-0">Discount</td>
                          <td className="border-0">
                            {this.props.location.state.order.coupon.isPercent
                              ? "%"
                              : "AU $"}
                            {this.props.location.state.order.coupon.amount}
                          </td>
                        </tr>
                        <tr>
                          <td className="border-0"></td>
                          <td className="border-0"></td>
                          <td className="border-0">Discount Applied</td>
                          <td className="border-0 text-danger">
                            {" "}
                            - AU ${discount.toFixed(2)}
                          </td>
                        </tr>
                      </React.Fragment>
                    )}

                  <tr>
                    <td className="border-0"></td>
                    <td className="border-0"></td>
                    <td className="border-0">Total</td>
                    <td className="border-0">AU ${total.toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </div>
        <div>
          <p
            className="text-center"
            style={{ fontSize: "20px", marginLeft: "10%", marginRight: "10%" }}
          >
            Email us at
            <span>
              {" "}
              <a
                style={{ color: "#a36349" }}
                href="mailto:support@remotetrails.com.au"
              >
                support@remotetrails.com.au
              </a>{" "}
            </span>
            with any questions.
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  orders: state.orders,
});
export default connect(mapStateToProps, { resetOrder, resetCoupon })(
  OrderReceived
);
