import React, { Component } from "react";
import { Row, Col } from "reactstrap";

export default class ProductTileHeader extends Component {
  render() {
    const { header } = this.props;
    return (
      <Row className="mb-3 mt-3">
        <Col className="text-center">
          <h4 style={{color:'rgb(161, 99, 72)'}}>{header}</h4>
        </Col>
      </Row>
    );
  }
}
