import {
    TOTAL_USER_COUNT,
    TOTAL_WEEKLY_USER_COUNT,
    ADMIN_USER_DETAILS_LOADING,
    RESET_ADMIN_USER_DETAILS_LOADING,
    CHANGE_STREAM_TOTAL_USER_COUNT
} from './types'
import axios from 'axios'

import { logoutUser } from './formActions'


export const totalUserDetails = () => dispatch => {
    dispatch({
        type: ADMIN_USER_DETAILS_LOADING
    })
    axios.get(`/api/admin/getTotalUserDetails`)
        .then(res => {
            dispatch({
                type: TOTAL_USER_COUNT,
                payload: res.data.totalCount
            })
            dispatch({
                type: TOTAL_WEEKLY_USER_COUNT,
                payload: res.data.weeklyCount
            })
            dispatch({
                type: RESET_ADMIN_USER_DETAILS_LOADING
            })
        })
        .catch(err => {
            dispatch({
                type: RESET_ADMIN_USER_DETAILS_LOADING
            })
            if (err.response.status === 401 || err.response.status === 403) {
                dispatch(logoutUser())
            }
            console.log(err)
        })

}

export const changeStreamUserData = data => dispatch => {
    dispatch({
        type: CHANGE_STREAM_TOTAL_USER_COUNT,
        payload: data.totalCount
    })
}
