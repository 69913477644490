import React from "react";
import { Progress } from "reactstrap";
export default function ProgressBar({ total }) {
  return (
    <div className="mt-3">
      <Progress max={100} color="success" value={total < 100 ? total : 100}>
        {total < 100 ? total : 100}%
      </Progress>
      <div className="text-center  pt-4" style={{ fontSize: 12 }}>
        {total.toFixed(2) >= 100
          ? "Eligible for FREE SHIPPING! "
          : "Get FREE SHIPPING if you order over $100.00!"}
      </div>
    </div>
  );
}
