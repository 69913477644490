import React from "react";
import { getVariantName } from "../utils/getVariantName";

export default function SizeLabel({ product }) {
  return (
    product.product.variants.length > 1 && (
      <>
        <span className="text-muted d-inline" style={{ fontSize: 14 }}>
          {" "}
          Size: {getVariantName(product)}
        </span>
        <br />
      </>
    )
  );
}
