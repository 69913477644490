import React from "react";
import { Spinner as ReactSpinner } from "reactstrap";

const Spinner = () => {
  return (
    <div className="mx-auto">
      <ReactSpinner style={{ color: "#a36349" }} />
    </div>
  );
};

export default Spinner;
