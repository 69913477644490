import React, { Component } from "react";
import whoweare from "../images/whoweare.jpg";
import { Row, Col } from "reactstrap";
import community from "../images/community.jpg";
import sustainability from "../images/sustainability.jpg";
import { Helmet } from "react-helmet";

export default class Explore extends Component {
  state = { theposition: null };

  componentDidMount() {
    window.addEventListener("scroll", this.listenToScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll);
  }

  listenToScroll = () => {
    let offsetTop = this.instance.getBoundingClientRect().top - 110;
    this.setState({
      theposition: offsetTop,
    });
  };

  render() {
    return (
      <div>
        <div>
          <Helmet>
            <title>Explore - Remote Trails - Who We Are</title>
            <meta
              name="description"
              content="Melbourne based Australian Outdoor and Lifestyle label. Our mission is to inspire and educate a community dedicated to conserving Australia’s Public Land resources and values for future generations while creating apparel and gear inspired by Australia’s remote wilderness."
            />
          </Helmet>
        </div>
        <div className="container">
          <div>
            <img
              src={whoweare}
              className="img-fluid whoWeAreImage"
              onLoad={this.onImgLoad}
            />
          </div>
        </div>
        <div className="container my-2" ref={(el) => (this.instance = el)}>
          <Row>
            <Col md="10" sm="12" xs="12" className="mx-auto">
              <div className="my-5" id="ourStory">
                <h1
                  className="font-weight-bold my-3"
                  style={{ fontSize: "1.25rem" }}
                >
                  WELCOME TO REMOTE TRAILS
                </h1>
                <p>
                  Nothing quite says Australia like sweeping arid plains, wide
                  open spaces that seem to stretch on forever, rugged landscape
                  and curvy coastal roads. You could drive for miles and never
                  see another soul. It is truly so remote that you will be able
                  to experience that feeling of being one with nature.{" "}
                </p>
                <p>
                  Every road, every track, every trail into the remote land is a
                  journey of peace and the experience is one that feeds the
                  soul.{" "}
                </p>
                <p>
                  The land that’s mostly wild, untouched and unforgiving in the
                  remote reaches of our beautiful country is the inspiration
                  behind Remote Trails.{" "}
                </p>
                <p>
                  All orders are dispatched from our warehouse in Melbourne. Our
                  products are hand printed locally by our printing partners. We
                  work closely with our suppliers to source only 100% organic
                  cotton and sustainable materials.
                </p>
              </div>

              <div className="my-5">
                <img
                  src={community}
                  className="img-fluid whoWeAreImage"
                  alt="mission"
                />
                <div id="mission">
                  <h1
                    className="font-weight-bold my-3"
                    style={{ fontSize: "1.25rem" }}
                  >
                    MISSION
                  </h1>
                  <p>
                    Our mission is to inspire and educate a community dedicated
                    to conserving Australia’s Public Land resources and values
                    for future generations while creating apparel and gear
                    inspired by Australia’s remote wilderness.{" "}
                  </p>
                </div>
              </div>

              <div className="my-5">
                <img
                  src={sustainability}
                  className="img-fluid whoWeAreImage"
                  alt="sustainability"
                />
                <div id="sustainability">
                  <h1
                    className="font-weight-bold my-3"
                    style={{ fontSize: "1.25rem" }}
                  >
                    SUSTAINABILITY
                  </h1>

                  <p>
                    High quality, durable and comfortable products and
                    sustainability can coexist, allowing an unforgettable
                    experience in the great outdoors. Our planet offers enticing
                    and exciting experiences around untouched wilderness and
                    remarkable wildlife. While we are aware that everything we
                    do has an impact on the environment around us, we are
                    committed to reducing it at every turn.{" "}
                  </p>
                  <p>
                    As an outdoor company, protection and conservation of our
                    planet is fundamental to our operation. We therefore
                    integrate sustainability into everything we do, making
                    deliberate decisions about who we work with, how we work &
                    what materials we use.
                  </p>
                  <p>
                    Sustainability is as much about choices and understanding as
                    it is about action. At Remote Trails, we focus on finding
                    common ground to advocate and educate our community about
                    the most responsible and ethical way of enjoying the
                    outdoors.
                  </p>
                </div>
              </div>

              <div className="my-5" id="givingBack">
                <h1
                  className="font-weight-bold my-3"
                  style={{ fontSize: "1.25rem" }}
                >
                  GIVING BACK
                </h1>
                <p>
                  At Remote Trails, we are committed to giving back and making a
                  difference. With increasing threat to our planet earth due to
                  climate change, giving back to protect our public lands is
                  more important than ever. We believe that it's our
                  responsibility to care for the natural environment for future
                  generations. That's why for every purchase, 10% is donated to
                  non profit organizations that protect and conserve our
                  national wonders here in our own backyard.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
