import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getReviews,
  deleteSelectedReview,
  updateReview,
  resetReviews,
} from "../../actions/adminReviews";
import { Row, Col, Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import moment from "moment";

class Reviews extends Component {
  state = {
    reviews: [],
    deleteReviewModal: false,
    deleteId: "",
    showDeleteLoading: false,
    page: 1,
  };

  loadMore = (e) => {
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => {
        this.props.getReviews(this.state.page);
      }
    );
  };

  componentWillUnmount() {
    this.props.resetReviews();
  }

  componentDidMount() {
    let page = 1;
    this.props.getReviews(page);
  }

  componentDidUpdate(prevProps) {
    if (this.props.adminReviews.reviews !== prevProps.adminReviews.reviews) {
      this.setState({
        reviews: this.props.adminReviews.reviews,
      });
    }

    if (
      this.props.adminReviews.reviews !== prevProps.adminReviews.reviews &&
      this.state.deleteId !== ""
    ) {
      if (
        this.props.adminReviews.reviews.filter(
          (review) => review._id === this.state.deleteId
        ).length === 0
      ) {
        this.setState({
          deleteId: "",
          deleteReviewModal: false,
          showDeleteLoading: false,
        });
      }
    }
  }

  handleDeactive = (review) => {
    let data = {};
    data.isActive = false;
    this.props.updateReview(review._id, data);
  };

  handlePublish = (review) => {
    let data = {};
    data.isActive = true;
    this.props.updateReview(review._id, data);
  };

  deleteReview = () => {
    this.setState({
      showDeleteLoading: true,
    });
    this.props.deleteSelectedReview(this.state.deleteId);
  };

  handleDeleteClick = (review) => {
    this.setState({
      deleteId: review._id,
      deleteReviewModal: true,
    });
  };

  handleDeleteModal = (e) => {
    this.setState({
      deleteReviewModal: !this.state.deleteReviewModal,
    });
  };

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  capitalizeLastName = (string) => {
    return string.charAt(0).toUpperCase() + ".";
  };

  handleProductClick = (product) => {
    this.props.history.push({
      pathname: `/adminpanel/products/${product.name.replace(
        /[^A-Z0-9]+/gi,
        "-"
      )}/${product._id}`,
      // state: { product }
    });
  };

  handleUserClick = (review) => {
    this.props.history.push({
      pathname: `/adminpanel/users/${review.userId.firstName}${review.userId.lastName}/${review.userId._id}`,
      // state: { user: review.userId }
    });
  };

  render() {
    console.log(this.state.reviews);
    return (
      <div>
        <div>
          <Modal
            isOpen={this.state.deleteReviewModal}
            toggle={this.handleDeleteModal}
          >
            <ModalBody>Are you sure you want to delete this review?</ModalBody>
            <ModalFooter>
              <Button
                color="success"
                onClick={this.deleteReview}
                disabled={this.state.showDeleteLoading}
              >
                {this.state.showDeleteLoading ? "Please Wait" : "Yes"}
              </Button>
              <Button
                color="danger"
                onClick={this.handleDeleteModal}
                disabled={this.state.showDeleteLoading}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
        <Row className="w-100">
          <Col>
            {this.state.reviews.length === 0 ? (
              <div className="text-center  my-5">
                <h5>No results found.</h5>
              </div>
            ) : (
              <div className="table-responsive">
                <table className="table">
                  <thead className="table-borderless">
                    <tr>
                      <th>RATING</th>
                      <th style={{ width: "40%" }}>REVIEW</th>
                      <th>DATE</th>
                      <th>IMAGE</th>
                      <th>REVIEW STATUS</th>
                      <th> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.reviews.map((review) => (
                      <React.Fragment key={review._id}>
                        <tr
                          className="admin-each-product"
                          style={{ cursor: "pointer" }}
                        >
                          <td
                            style={{ verticalAlign: "middle" }}
                            className="font-weight-bold"
                          >
                            {review.rating && (
                              <span
                                style={{
                                  fontSize: "18px",
                                  display: "inline-block",
                                }}
                              >
                                {[...Array(Math.ceil(review.rating))].map(
                                  (star, index) => (
                                    <i
                                      key={index}
                                      className="fas fa-star"
                                      style={{ color: "gold" }}
                                    ></i>
                                  )
                                )}
                                {[...Array(Math.ceil(5 - review.rating))].map(
                                  (star, index) => (
                                    <i
                                      key={index}
                                      className="far fa-star"
                                      style={{ color: "gold" }}
                                    ></i>
                                  )
                                )}
                              </span>
                            )}
                          </td>
                          <td
                            style={{ verticalAlign: "middle" }}
                            className="font-weight-bold"
                          >
                            {this.capitalizeFirstLetter(review.review)} <br />
                            <span className="text-muted">
                              <span
                                className="admin-review-product-name"
                                onClick={() => this.handleUserClick(review)}
                              >
                                {this.capitalizeFirstLetter(review.firstName)}{" "}
                                {this.capitalizeLastName(review.lastName)}
                              </span>{" "}
                              on{" "}
                              <span
                                className="admin-review-product-name"
                                onClick={() =>
                                  this.handleProductClick(review.product)
                                }
                              >
                                {review.product.name}
                              </span>
                            </span>
                          </td>
                          <td
                            style={{ verticalAlign: "middle" }}
                            className="font-weight-bold"
                          >
                            {moment(review.lastUpdated).format("Do MMM YY")}
                          </td>
                          <td
                            style={{ verticalAlign: "middle" }}
                            className="font-weight-bold"
                          >
                            {review.reviewImage &&
                              review.reviewImage !== "" && (
                                <img
                                  src={review.reviewImage}
                                  className="img-fluid"
                                  style={{ maxHeight: 75 }}
                                  alt={this.capitalizeFirstLetter(
                                    review.review
                                  )}
                                />
                              )}
                          </td>
                          <td
                            style={{ verticalAlign: "middle" }}
                            className={
                              !review.isActive
                                ? "text-warning font-weight-bold"
                                : review.isActive &&
                                  "text-success font-weight-bold"
                            }
                          >
                            {" "}
                            {review.isActive ? "Published" : "Not Active"}
                          </td>
                          <td
                            style={{ verticalAlign: "middle" }}
                            className="font-weight-bold text-center"
                          >
                            {!review.isActive ? (
                              <Button
                                className="my-1"
                                color="success"
                                size="sm"
                                onClick={() => this.handlePublish(review)}
                              >
                                {" "}
                                Publish
                              </Button>
                            ) : (
                              <Button
                                className="my-1"
                                color="info"
                                size="sm"
                                onClick={() => this.handleDeactive(review)}
                              >
                                {" "}
                                Deactivate{" "}
                              </Button>
                            )}

                            <Button
                              size="sm"
                              className="ml-2 my-1"
                              onClick={() => this.handleDeleteClick(review)}
                              color="danger"
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </Col>
        </Row>

        <Row>
          <Col className="text-center my-3">
            {this.props.adminReviews.reviewCount >
              this.props.adminReviews.reviews.length && (
              <Button onClick={this.loadMore} outline color="dark">
                Load More
              </Button>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  adminReviews: state.adminReviews,
});

export default connect(mapStateToProps, {
  getReviews,
  deleteSelectedReview,
  updateReview,
  resetReviews,
})(Reviews);
