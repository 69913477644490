import React, { Component } from "react";
import { connect } from "react-redux";
import { setImages } from "../actions/reviewActions";
import { Button } from "reactstrap";
import { Form, Input, InputGroup } from "reactstrap";
import classnames from "classnames";

class ReviewStep5 extends Component {
  render() {
    return (
      <div className="text-center px-5  py-3">
        <p>
          <i
            style={{ color: "green" }}
            className="fas fa-check-circle fa-3x"
          ></i>{" "}
        </p>
        <h3 className="font-weight-bold my-3 ">THANKS FOR YOUR FEEDBACK</h3>
        <p>
          Your feedback has been submitted and will be made public after review.
          If you have any questions, you can email our friendly team at{" "}
          <a
            style={{ color: "rgb(223,149,143)" }}
            href="mailto:support@remotetrails.com.au"
          >
            support@remotetrails.com.au{" "}
          </a>{" "}
        </p>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  reviews: state.reviews,
});

export default connect(mapStateToProps, {})(ReviewStep5);
