import React from 'react'
import LayOut from './LayOut'
import MainBar from './MainBar'
import {
    Row
} from 'reactstrap';
import AdminTopBar from './AdminTopBar';



const AdminLayout = ({ children }) => {
    return (
        <div >
            <Row >
                <div className='left-panel'>
                    <LayOut />
                    <MainBar />

                </div>
                <div className='content' style={{ minHeight: '100vh' }}>
                    {children.props.location.pathname.split('/').length < 4 && (
                        <AdminTopBar />

                    )}
                    {children}
                </div>
            </Row>

        </div>
    )
}

export default AdminLayout;
