import axios from "axios";
import {
  FORM_VALIDATION_ERROR,
  SET_CURRENT_USER,
  VERIFY_USER,
  GET_DB_CART,
  RESET_CART,
  RESET_LOCALSTORAGE_USER,
  DISABLE_CART_SYNC,
  GET_GUEST_USER,
  RESET_PASSWORD,
  FULL_PAGE_LOADING,
  RESET_FULL_PAGE_LOADING,
  RESET_SHOW_RESET_PASSWORD,
  PASSWORD_UPDATED,
  RESEND_VERIFICATION,
  SHOW_SPINNER,
  RESET_SPINNER,
  REGISTRATION_SUCCESSFUL,
  RESET_SIGNUP,
  RESET_FORM_REDUCER,
} from "./types";
import jwt_decode from "jwt-decode";
import setAuthToken from "../setAuthToken";

export const resetFormReducer = () => (dispatch) => {
  dispatch({
    type: RESET_FORM_REDUCER,
  });
};

export const registerUser = (userData, history) => (dispatch) => {
  dispatch({
    type: DISABLE_CART_SYNC,
  });
  dispatch({
    type: SHOW_SPINNER,
  });

  axios
    .post("/api/users/register", userData)
    .then((res) => {
      dispatch({
        type: GET_GUEST_USER,
        payload: res.data.user,
      });

      if (res.data.success) {
        dispatch({
          type: REGISTRATION_SUCCESSFUL,
        });
      }
      dispatch({
        type: RESET_SPINNER,
      });
    })
    .catch((err) => {
      dispatch({
        type: RESET_SPINNER,
      });
      dispatch({
        type: FORM_VALIDATION_ERROR,
        payload: err.response.data,
      });
    });
};

export const verifyUser = (code) => (dispatch) => {
  axios
    .post(`/api/users/verifyaccount/${code}`)
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: VERIFY_USER,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: FORM_VALIDATION_ERROR,
        payload: err.response.data,
      });
    });
};

export const loginUser = (userData) => (dispatch) => {
  dispatch({
    type: SHOW_SPINNER,
  });
  axios
    .post("/api/users/login", userData)
    .then((res) => {
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);

      setAuthToken(token);

      const decoded = jwt_decode(token);

      dispatch({
        type: SET_CURRENT_USER,
        payload: decoded.userDetails,
      });
      dispatch({
        type: GET_DB_CART,
        payload: decoded.cart.cart,
      });
      dispatch({
        type: RESET_SPINNER,
      });
    })
    .catch((err) => {
      dispatch({
        type: FORM_VALIDATION_ERROR,
        payload: err.response.data,
      });
      dispatch({
        type: RESET_SPINNER,
      });
    });
};

export const resetForm = () => (dispatch) => {
  dispatch({
    type: RESET_SIGNUP,
  });
};

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem("jwtToken");
  setAuthToken(false);
  dispatch({
    type: SET_CURRENT_USER,
    payload: {},
  });
  dispatch({
    type: RESET_CART,
  });
  dispatch({
    type: RESET_SPINNER,
  });
};

export const resetLocalStorageUser = () => (dispatch) => {
  dispatch({
    type: RESET_LOCALSTORAGE_USER,
  });
  dispatch({
    type: RESET_CART,
  });
  // dispatch(logoutUser())
};

export const deleteSelectedAddress = (data) => (dispatch) => {
  dispatch({
    type: FULL_PAGE_LOADING,
  });
  axios
    .post("/api/users/deleteAddress", data)
    .then((res) => {
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);

      setAuthToken(token);

      const decoded = jwt_decode(token);

      dispatch({
        type: SET_CURRENT_USER,
        payload: decoded.userDetails,
      });
      dispatch({
        type: RESET_FULL_PAGE_LOADING,
      });
    })
    .catch((err) => {
      dispatch({
        type: FORM_VALIDATION_ERROR,
        payload: err.response.data,
      });
      dispatch({
        type: RESET_FULL_PAGE_LOADING,
      });
    });
};

export const newAddress = (data) => (dispatch) => {
  dispatch({
    type: FULL_PAGE_LOADING,
  });

  axios
    .post("/api/users/newAddress", data)
    .then((res) => {
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);

      setAuthToken(token);

      const decoded = jwt_decode(token);

      dispatch({
        type: SET_CURRENT_USER,
        payload: decoded.userDetails,
      });
      dispatch({
        type: RESET_FULL_PAGE_LOADING,
      });
    })
    .catch((err) => {
      dispatch({
        type: FORM_VALIDATION_ERROR,
        payload: err.response.data,
      });
      dispatch({
        type: RESET_FULL_PAGE_LOADING,
      });
    });
};

export const editAddress = (data) => (dispatch) => {
  dispatch({
    type: FULL_PAGE_LOADING,
  });

  axios
    .post("/api/users/editAddress", data)
    .then((res) => {
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);

      setAuthToken(token);

      const decoded = jwt_decode(token);

      dispatch({
        type: SET_CURRENT_USER,
        payload: decoded.userDetails,
      });
      dispatch({
        type: RESET_FULL_PAGE_LOADING,
      });
    })
    .catch((err) => {
      dispatch({
        type: FORM_VALIDATION_ERROR,
        payload: err.response.data,
      });
      dispatch({
        type: RESET_FULL_PAGE_LOADING,
      });
    });
};

export const sendVerification = (data) => (dispatch) => {
  axios
    .post("/api/users/verify-user", data)
    .then((res) => {
      dispatch({
        type: RESEND_VERIFICATION,
      });
    })
    .catch((err) => {
      dispatch({
        type: FORM_VALIDATION_ERROR,
        payload: err.response.data,
      });
    });
};

export const resetPassword = (data) => (dispatch) => {
  axios
    .post("/api/users/reset-password", data)
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: RESET_PASSWORD,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: FORM_VALIDATION_ERROR,
        payload: err.response.data,
      });
    });
};
export const resetShowMessage = () => (dispatch) => {
  dispatch({
    type: RESET_SHOW_RESET_PASSWORD,
  });
};

export const updatePassword = (userData, linkData) => (dispatch) => {
  axios
    .post(
      `/api/users/newPassword/${linkData.userid}/${linkData.code}`,
      userData
    )
    .then((res) => {
      dispatch({
        type: PASSWORD_UPDATED,
      });
    })
    .catch((err) => {
      dispatch({
        type: FORM_VALIDATION_ERROR,
        payload: err.response.data,
      });
    });
};
