import React, { useState } from "react";
import { Collapse } from "reactstrap";

const features = [
  {
    beanie: [
      "95% Organic Ring Spun Combed Cotton; 5% Elastane",
      "Pre washed",
      "Unisex fit",
      "Biodegradable packaging",
    ],
    tshirt: [
      "100% Certified Organic Cotton",
      "Vegan Approved",
      "Biodegradable/recycable packaging",
      "This product was manufactured solely using renewable green energy from wind and solar power.",
    ],
    cap: [
      "Unisex",
      "One size fits all",
      "Adjustable back strap",
      "6 panel unstructured vintage fit",
      "Organic cotton corduroy",
    ],
  },
];

export default function ProductFeatures() {
  const [open, setOpen] = useState(true);

  const path = window.location.pathname;

  let feature;

  if (path.includes("Cap")) {
    feature = "cap";
  } else if (path.includes("Beanie")) {
    feature = "beanie";
  } else {
    feature = "tshirt";
  }

  return (
    <div className="mt-3 ">
      <div>
        <p
          className="font-weight-bold border-bottom"
          style={{ fontSize: 14, cursor: "pointer" }}
          onClick={() => setOpen(!open)}
        >
          Features
          <span className="float-right">
            <i
              className={
                open ? "fa-solid fa-caret-up" : "fa-solid fa-caret-down"
              }
            />
          </span>
        </p>
      </div>

      <Collapse isOpen={open}>
        <p style={{ fontSize: 14 }} className=" pb-3">
          <ul className="m-0 px-3">
            {features[0][feature].map((item) => (
              <li>{item}</li>
            ))}
            {/* <li>
              This product was manufactured solely using renewable green energy
              from wind and solar power.
            </li>
            <li>100% Certified Organic Cotton</li>
            <li>Vegan Approved</li>
            <li>Biodegradable/recycable packaging</li> */}
          </ul>
        </p>
      </Collapse>
    </div>
  );
}
