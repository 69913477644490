import React, { useCallback, Component } from 'react'
import { useDropzone } from 'react-dropzone';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Button } from 'reactstrap'
const SortableItem = (SortableElement(props => {
    const handleClick = (value) => {
        props.deleteImage(value)
    }

    return <div className='py-2 px-2 sortable-item' >
        <div  >
            <Button className='border-0 text-muted p-0 float-right' style={{ backgroundColor: 'snow', }} onClick={() => handleClick(props.value)}>&times;</Button>
        </div>
        <img style={{ maxHeight: 100 }} src={URL.createObjectURL(props.value)} />
    </div>
}
))

const SortableList = SortableContainer(props => {
    return (
        <div style={{ backgroundColor: 'snow' }}>
            {props.items.map((item, index) => (
                <SortableItem deleteImage={props.removeImage} value={item} key={index} index={index} axis='x' />
            ))}
        </div>
    );
});


class ImageList extends Component {
    state = {
        images: []
    }

    componentDidMount() {
        this.setState({
            images: this.props.images
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.images !== prevProps.images) {
            this.setState({
                images: this.props.images
            })
        }
    }

    render() {
        return (
            <div >
                <div>
                    <div>
                        <SortableList removeImage={this.props.updateRemoveImage} items={this.state.images} onSortEnd={this.props.onSortChange} lockAxis="x"
                            axis="x" />
                    </div>
                </div>
            </div>
        );
    }

}
export default ImageList