import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { connect } from 'react-redux'
import { } from '../../actions/adminUserActions'
import Spinner from '../Spinner'


class TotalOrdersYesterday extends Component {
    render() {


        return (
            <div className=' text-left bg-light px-3 py-3 my-2' style={{ borderRadius: 10 }}>
                <Row>
                    <Col xs='8' >
                        <h6 className='font-weight-bold text-secondary'>TOTAL ORDERS YESTERDAY</h6>
                        {this.props.adminRevenueCalc.loading ? (
                            <div className='text-center my-auto'>
                                <Spinner />

                            </div>
                        ) : (
                                <React.Fragment>
                                    <h5 className='font-weight-bold text-dark'>{this.props.adminRevenueCalc.totalOrdersYesterday}</h5>

                                </React.Fragment>
                            )}

                    </Col>
                    <Col xs='4' className='my-3  text-center'>
                        <div className='rounded-circle text-white shadow px-3 py-3 bg-info' style={{ width: '3em', height: '3em', alignItems: 'center', justifyContent: 'center', display: 'inline-flex', textAlign: 'center', }}>
                            <i className="fas fa-shopping-cart" style={{ fontSize: '1.25em', }}></i>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.form,
    adminRevenueCalc: state.adminRevenueCalc

})


export default connect(mapStateToProps, {})(TotalOrdersYesterday)