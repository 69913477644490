import React, { Component } from 'react'
import { getCouponStats } from '../../actions/LatestOrders'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
    Row, Col
} from 'reactstrap'


class CouponData extends Component {

    componentDidMount() {
        this.props.getCouponStats()
    }

    render() {
        return (
            <div>
                {this.props.latestOrders.shippingData.length !== 0 && (
                    <div className=' text-left bg-light px-3 py-3  h-100 ' style={{ borderRadius: 10, overflow: 'scroll' }}>
                        <Row>
                            <Col>
                                <h6 className='font-weight-bold text-secondary'>Coupon Data</h6>
                                <div className='table-responsive'>
                                    <table className="table">
                                        <thead className='table-borderless'>
                                            <tr>
                                                <th scope="col">TOTAL ORDERS</th>
                                                <th scope="col">%10 DISCOUNT USED</th>

                                            </tr>
                                        </thead>

                                        <tbody >
                                            <tr className='admin-each-product' style={{ cursor: 'pointer' }}>
                                                <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>  {this.props.latestOrders.couponData.orderCount}</td>
                                                <td style={{ verticalAlign: 'middle' }} className='font-weight-bold'>{this.props.latestOrders.couponData.tenPercentOff}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>

                            </Col>
                        </Row>

                    </div>
                )}

            </div>

        )
    }
}
const mapStateToProps = state => ({
    latestOrders: state.latestOrders
})


export default connect(mapStateToProps, { getCouponStats })(withRouter(CouponData))
